/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link } from "react-router-dom";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import footerLogo from "../assets/images/logo/image.png";
import { mail } from "../constant/images";

const Footer = () => {
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  const handleSubscribeFooter = async () => {
    const email = document.getElementById('footer-email').value;

    if (!validateEmail(email)) {
      setError('Invalid email address');
      return;
    }

    setError('');

    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);

      const formData = new FormData();
      formData.append('Email', email);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'NewLetter'); // Replace 'YourSheetName' with your actual sheet name
      toast.info('Submitting...');
      const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
      if (response.status === 200) {
        toast.success('Subscription successful');
        document.getElementById('footer-email').value = '';
      }
    } catch (error) {
      toast.error('Failed to subscribe');
    }
  };

  const updatedDate = new Date().getFullYear();

  return (
    <>
      <footer className="bg-black bg-[url('../images/all-img/footer-bg-1.png')] bg-cover bg-center bg-no-repeat">
        <div className="section-padding container">
          <div className="grid grid-cols-1 gap-7 md:grid-cols-2 lg:grid-cols-3">
            <div className="single-footer">
              <div className="lg:max-w-[270px]">
                <a href="" className="mb-10 block">
                  <img src={footerLogo} style={{ backgroundColor: "white" }} alt="" />
                </a>
                <ul className="flex space-x-4 pt-8">
                  <li>
                    <a
                      target="_blank"
                      href="https://www.facebook.com/people/ALCE/100075831133620/"
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:facebook"></iconify-icon>
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://www.instagram.com/alce_institute/ "
                      className="flex h-12 w-12 flex-col items-center justify-center rounded bg-white bg-opacity-[0.08] text-2xl text-white
                  transition hover:bg-primary hover:text-white"
                    >
                      <iconify-icon icon="bxl:instagram"></iconify-icon>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
            <div className="single-footer">
              <div className="flex space-x-[80px]">
                <div className="flex-1 lg:flex-none">
                  <h4 className="mb-8 text-2xl font-bold text-white">Links</h4>
                  <ul className="list-item space-y-5">
                    <li>
                      <Link to={"/"}>
                        Home
                      </Link>
                    </li>
                    <li>
                      <Link to={"/about-us"}>
                        About
                      </Link>
                    </li>
                    <li>
                      <Link to={"/courses"}>
                        Courses
                      </Link>
                    </li>
                    <li>
                      <Link to={"/contact-us"}>
                        Contacts
                      </Link>
                    </li>
                    <li>
                      <Link to={"/privacy-policy"}>
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="single-footer">
              <h4 className="mb-8 text-2xl font-bold text-white">Newsletter</h4>
              <div className="mb-8">
                Stay updated with our latest courses, events, news, and promotions!
              </div>
              <div className="mb-4 flex items-center rounded-md bg-white py-[10px] pr-[10px] pl-6 shadow-e1">
                <div className="flex-none">
                  <span className=" ">
                    <img src={mail} alt="" />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    id="footer-email"
                    type="text"
                    placeholder="Enter your mail"
                    className="border-none focus:ring-0"
                  />
                </div>
              </div>
              {error && <p className="text-red-500">{error}</p>}
              <button className="btn btn-primary block w-full text-center" onClick={handleSubscribeFooter}>
                Subscribe Now
              </button>
            </div>
          </div>
        </div>
        <div className="container border-t border-white border-opacity-[0.1] py-8 text-center text-base">
          &copy; Copyright {updatedDate} | ALCE | All Rights Reserved
        </div>
      </footer>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: '300px', // Adjust the width as needed
          bottom: '30px', // Adjust the distance from the bottom
          right: '30px', // Adjust the distance from the right
          zIndex: 9999 // Ensure it appears above other elements
        }}
      />
    </>
  );
};

export default Footer;
