import { Tab } from "@headlessui/react";
import AOS from 'aos';
import 'aos/dist/aos.css'; // Import aos styles
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const FilteredCourse = ({ classNameForTabOne, classNameForTabTwo, data }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Clean up when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []);

  const openModal = (item) => {
    setCurrentItem(item);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setName("");
    setMobile("");
    setEmail("");
    setErrors({});
  };

  const validateForm = () => {
    let errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!mobile.trim()) {
      errors.mobile = "Mobile Number is required";
    } else if (!/^[0-9]{10}$/.test(mobile)) {
      errors.mobile = "Mobile Number is invalid";
    }
    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);
      const formData = new FormData();
      formData.append("Name", name);
      formData.append("Email", email);
      formData.append("MobileNumber", mobile);
      formData.append("CourseTitle", currentItem.title);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'Enquiry'); // Replace 'YourSheetName' with your actual sheet name
      localStorage.setItem("Name", name)
      localStorage.setItem("Email", email)
      localStorage.setItem("MobileNumber", mobile)
      const response = await axios.post("https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec", formData);
      if (response.status === 200) {
        toast.success("Enquiry submitted successfully");
        closeModal();
      }
    } catch (error) {
      toast.error("Failed to submit enquiry");
    } finally {
      setLoading(false);
    }
  };

  return (
    <>
      <Tab.Group>
        <div className="flex flex-wrap gap-5 justify-center items-center">
          <div className="flex-1 flex flex-wrap gap-5 space-x-6 items-center">
            <Tab.List as="ul" id="tabs-nav" className="flex space-x-4 cata-Tbas">
              {/* Add your icons here */}
            </Tab.List>
          </div>
        </div>
        <Tab.Panels as="div" id="tabs-content">
          <Tab.Panel as="div" id="tab1" className="tab-content">
            <div className={classNameForTabOne}>
              {data.map((item, index) => (
                <div
                  className="animated-element bg-white shadow-box2 rounded-[8px] transition duration-100 overflow-hidden border border-gray-300 shadow-md relative"
                  key={item.id * index}
                  data-aos="fade-right" data-aos-delay="200"
                >
                  {/* <Link to={item.route}>
                      <div className="course-thumb h-[248px] relative" style={{height:"200px"}}>
                        <img
                          src={item.img}
                          alt={item.route}
                          className="h-full w-full mx-auto object-cover transition duration-300 transform hover:scale-105"
                        />
                        <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white absolute left-6 top-6">
                          {item.post}
                        </span>
                      </div>
                    </Link> */}
                  <div className="course-content bg-white relative z-10">
                    <h4 className="text-xl mb-3 font-bold ml-3" style={{ marginLeft: "20px",marginTop: "20px"  }}>{item.title}</h4>
                    <div className="font-bold text-2xl mb-3" style={{ marginLeft: "20px" }}>
                    ₹ <span style={{color:"red",textDecoration:"line-through"}}>{item.mprice}</span> <span className="text-secondary">{item.oprice}</span>
                    </div>
                    <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white" style={{ marginLeft: "20px" }}>
                      {item.post}
                    </span>
                    <div className="flex justify-between items-center" style={{ margin: "20px" }}>
                      <button
                        className="btn-primary btn mr-2"
                        onClick={() => openModal(item)}
                        style={{ fontSize: "15px" }}
                      >
                        Enquiry Now
                      </button>
                      <Link
                        className="btn bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                        to={item.route}
                        style={{ fontSize: "15px" }}
                        key={item.id * index}
                      >
                        View Details
                      </Link>
                    </div>
                  </div>
                  {/* <div className="absolute top-0 left-0 right-0 bottom-0 bg-gradient-to-b from-transparent to-black opacity-10 z-0"></div> */}
                </div>
              ))}

            </div>
          </Tab.Panel>
        </Tab.Panels>

        {isModalOpen && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" style={{ marginTop: "100px" }}>
            <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
              <form onSubmit={handleFormSubmit}>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Name
                  </label>
                  <input
                    type="text"
                    className="form-control"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                  />
                  {errors.name && (
                    <p className="text-red-500 text-sm">{errors.name}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Mobile Number
                  </label>
                  <input
                    type="tel"
                    className="form-control"
                    value={mobile}
                    onChange={(e) => setMobile(e.target.value)}
                  />
                  {errors.mobile && (
                    <p className="text-red-500 text-sm">{errors.mobile}</p>
                  )}
                </div>
                <div className="mb-4">
                  <label className="block text-sm font-medium text-gray-700">
                    Email
                  </label>
                  <input
                    type="email"
                    className="form-control"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                  />
                  {errors.email && (
                    <p className="text-red-500 text-sm">{errors.email}</p>
                  )}
                </div>
                <div className="mb-4">
                  {/* <label className="block text-sm font-medium text-gray-700" >
                    Course Title
                  </label> */}
                  <input
                    type="hidden"
                    className="form-control"
                    value={currentItem?.title || ""}
                    readOnly
                  />
                </div>
                <button
                  type="submit"
                  className="btn-primary btn w-full"
                  disabled={loading}
                >
                  {loading ? "Sending..." : "Submit"}
                </button>
              </form>
              <button className="mt-4 text-red-500" onClick={closeModal}>
                Cancel
              </button>
            </div>
          </div>
        )}
      </Tab.Group>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: "300px", // Adjust the width as needed
          bottom: "30px", // Adjust the distance from the bottom
          right: "30px", // Adjust the distance from the right
          zIndex: 9999, // Ensure it appears above other elements
        }}
      />
    </>
  );
};

export default FilteredCourse;
