import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ShowInterestModal({ onClose }) {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({});

  useEffect(() => {
    document.body.style.overflow = 'hidden';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, []);

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (!name) {
      tempErrors['name'] = 'Name is required';
      isValid = false;
    }

    if (mobile) {
      const mobilePattern = /^[0-9]{10}$/;
      if (!mobilePattern.test(mobile)) {
        tempErrors['mobile'] = 'Mobile number must be 10 digits';
        isValid = false;
      }
    } else {
      tempErrors['mobile'] = 'Mobile number is required';
      isValid = false;
    }

    if (email) {
      const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      if (!emailPattern.test(email)) {
        tempErrors['email'] = 'Invalid email format';
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
        const istDateTime = new Date().toLocaleString('en-IN', istOptions);
        const formData = new FormData();
        formData.append('Name', name);
        formData.append('Email', email);
        formData.append('MobileNumber', mobile);
        formData.append('sheetName', 'ShowInterest'); // Replace 'YOUR_SHEET_NAME' with your actual sheet name
        formData.append('DateTime', istDateTime);
        localStorage.setItem("Name",name)
        localStorage.setItem("Email",email)
        localStorage.setItem("MobileNumber",mobile)
        toast.success('Your Interest Submitted Successfully');
        const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
        if (response.status == 200) {
            toast.success('Your Interest Submitted Successfully');
        }
      } catch (error) {
        toast.error('Failed to submit form');
      }
    }
  };
  
  

  return (
    <div style={styles.modal}>
      <div style={styles.modalContent}>
        <span onClick={onClose} style={styles.close}>&times;</span>
        {/* <h2 style={styles.modalHeader}>Modal Heading</h2> */}
        <form onSubmit={handleSubmit}>
          <div style={styles.formGroup}>
            <label style={styles.label}>Name:</label>
            <input
              type="text"
              value={name}
              onChange={(e) => setName(e.target.value)}
              style={styles.input}
            />
            {errors.name && <p style={styles.error}>{errors.name}</p>}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Email (Optional):</label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              style={styles.input}
            />
            {errors.email && <p style={styles.error}>{errors.email}</p>}
          </div>
          <div style={styles.formGroup}>
            <label style={styles.label}>Mobile Number:</label>
            <input
              type="text"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
              style={styles.input}
            />
            {errors.mobile && <p style={styles.error}>{errors.mobile}</p>}
          </div>
          <button type="submit" className="btn btn-primary py-[15px] px-8">
            Submit
          </button>
        </form>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
            width: '300px', // Adjust the width as needed
            bottom: '30px', // Adjust the distance from the bottom
            right: '30px', // Adjust the distance from the right
            zIndex: 9999 // Ensure it appears above other elements
        }}
        />


    </div>
  );
}

const styles = {
  modal: {
    display: 'block',
    position: 'fixed',
    zIndex: 1,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    backgroundColor: '#fff',
    margin: '10% auto',
    padding: '20px',
    border: '1px solid #888',
    width: '400px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
  },
  close: {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '-10px',
    marginRight: '-10px'
  },
  modalHeader: {
    marginBottom: '15px',
    color: '#333'
  },
  formGroup: {
    marginBottom: '15px',
    textAlign: 'left'
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333'
  },
  input: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '14px'
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginTop: '5px'
  },
  submitButton: {
    backgroundColor: '#28a745',
    border: 'none',
    color: 'white',
    padding: '10px 20px',
    textAlign: 'center',
    textDecoration: 'none',
    display: 'inline-block',
    fontSize: '16px',
    margin: '10px 2px',
    cursor: 'pointer',
    borderRadius: '5px',
    width: '100%'
  }
};

export default ShowInterestModal;
