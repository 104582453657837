import React, { useEffect } from "react";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Feature = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <div className="feature-area bg-[url('../images/all-img/section-bg-1.png')] bg-cover bg-no-repeat bg-center section-padding">
      <div className="container">
        <div className="text-center">
          {/* <div className="mini-title">Core Features</div> */}
          <div className="column-title " data-aos="fade-up" data-aos-delay="200">
            Why <span className="shape-bg">Choose</span> ALCE
          </div>
        </div>
        <div className="grid lg:grid-cols-3 md:grid-cols-2 grid-cols-1 gap-[30px] pt-10" >
          <div data-aos="fade-right" data-aos-delay="400" className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="codicon:globe"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Learn More Anywhere
            </h4>
            <div className="transition duration-150 group-hover:text-white">
            Learn more anywhere with our flexible online and offline courses, tailored to fit your lifestyle and goals.
            </div>
          </div>

          <div data-aos="fade-right" data-aos-delay="600" className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="healthicons:i-training-class-outline"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Expert Instructor
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Learn from expert instructors who bring real-world experience and deep knowledge to every course and lesson.
            </div>
          </div>

          <div data-aos="fade-right" data-aos-delay="800" className="bg-white shadow-box rounded-[8px]  p-10 group hover:bg-primary  transition duration-150 hover:-translate-y-1">
            <div
              className="h-[72px] w-[72px] rounded-full flex flex-col items-center justify-center text-secondary bg-green-paste mb-8
                text-5xl group-hover:bg-black group-hover:bg-opacity-[0.1] group-hover:text-white transition duration-150"
            >
              <iconify-icon icon="fluent:handshake-32-regular"></iconify-icon>
            </div>
            <h4 className=" lg:text-2xl text-[22px] leading-[30px] mb-4 transition duration-150 group-hover:text-white">
              Assured Growth
            </h4>
            <div className="transition duration-150 group-hover:text-white">
              Experience assured growth with our tailored educational programs designed to enhance your skills and career prospects.
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Feature;
