/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import ok from "../assets/images/svg/ok.svg";
import aboutTwo from "../assets/images/banner/image copy.png";
import { Link } from "react-router-dom";
import AOS from 'aos';
import 'aos/dist/aos.css';
const Platform = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <div className="bg-[url('../images/all-img/section-bg-2.png')] bg-cover bg-no-repeat bg-center section-padding">
      <div className="container">
        <div className="lg:grid grid-cols-12 gap-[30px]">
          <div className="xl:col-span-5 lg:col-span-6 col-span-12">
            {/* <div className="mini-title">Best Learning Place</div> */}
            <h4 className="column-title " data-aos="fade-up" data-aos-delay="200">
              One Place & Many
              <span className="shape-bg"> Courses</span> For You
            </h4>
            {/* <div>
              There are many variations of passages of Lorem Ipsum available,
              but the majority have suffered.
            </div> */}
            <ul className=" space-y-2 pt-8">
              <li className="flex flex-wrap" data-aos="fade-left" data-aos-delay="600">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    Both Offline & Online
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap" data-aos="fade-left" data-aos-delay="800">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    Affordable Price
                    {/* 96% Completitation Rate */}
                  </h4>
                </div>
              </li>
              <li className="flex flex-wrap" data-aos="fade-left" data-aos-delay="1000">
                <div className="flex-none mr-3">
                  <div className="">
                    <img src={ok} alt="" />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-lg mb-1 font-semibold">
                    Friendly Environment & Expert Teacher
                  </h4>
                </div>
              </li>
            </ul>
            <div className="pt-8" data-aos="fade-left" data-aos-delay="1200">
              <Link to="courses" className=" btn btn-primary">
                Explore Our Courses
              </Link>
            </div>
          </div>
          <div className="xl:col-span-7 lg:col-span-6 col-span-12 lg:pt-0 md:pt-10 pt-6" data-aos="fade-right" data-aos-delay="400">
            <img src={aboutTwo} alt="" className=" mx-auto block w-full" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Platform;
