import axios from "axios";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import AOS from 'aos';
import 'aos/dist/aos.css';

const ContactForm = () => {

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 

  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [mobile, setMobile] = useState("");
  const [message, setMessage] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});
  const navigate = useNavigate();

  const validateForm = () => {
    let errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!mobile.trim()) {
      errors.mobile = "Mobile Number is required";
    } else if (!/^[0-9]{10}$/.test(mobile)) {
      errors.mobile = "Mobile Number is invalid";
    }
    if (!message.trim()) {
      errors.message = "Message is required";
    }

    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);

      const formData = new FormData();
      formData.append('Name', name);
      formData.append('Email', email);
      formData.append('MobileNumber', mobile);
      formData.append('Message', message);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'ContactForm'); // Replace 'YourSheetName' with your actual sheet name
      const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
      if (response.status === 200) {
        toast.success('Subscription successful');
        setMobile('')
        setEmail('')
        setMessage('')
        setName('')

        setErrors({})
        
      }
    } catch (error) {
      toast.error('Failed to subscribe');
    }
    setLoading(false)
  };

  return (
    <>
      <form className="md:grid-cols-2 grid grid-cols-1 gap-[30px] mt-6" onSubmit={handleFormSubmit}>
        <div data-aos="fade-right" data-aos-delay="200">
          <input
            type="text"
            className="from-control"
            placeholder="Name*"
            value={name}
            onChange={(e) => setName(e.target.value)}
          />
          {errors.name && <p className="text-red-500">{errors.name}</p>}
        </div>
        <div data-aos="fade-right" data-aos-delay="400">
          <input
            type="email"
            className="from-control"
            placeholder="Email*"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          {errors.email && <p className="text-red-500">{errors.email}</p>}
        </div>
        <div data-aos="fade-right" data-aos-delay="600">
          <input
            type="tel"
            className="from-control"
            placeholder="Mobile Number*"
            value={mobile}
            onChange={(e) => setMobile(e.target.value)}
          />
          {errors.mobile && <p className="text-red-500">{errors.mobile}</p>}
        </div>
        <div className="md:col-span-2 col-span-1" data-aos="fade-right" data-aos-delay="800">
          <textarea
            className="from-control"
            placeholder="Your Message*"
            rows="5"
            value={message}
            onChange={(e) => setMessage(e.target.value)}
          ></textarea>
          {errors.message && <p className="text-red-500">{errors.message}</p>}
        </div>
        <button className="btn btn-primary mt-[10px]" type="submit" disabled={loading} data-aos="fade-right" data-aos-delay="1200">
          {loading ? "Sending..." : "Send Message"}
        </button>
      </form>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: '300px', // Adjust the width as needed
          bottom: '30px', // Adjust the distance from the bottom
          right: '30px', // Adjust the distance from the right
          zIndex: 9999 // Ensure it appears above other elements
        }}
      />
    </>
  );
};

export default ContactForm;
