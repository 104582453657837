import SoftwareTesting from "../Components/Pages/SoftwareTesting";
import {
  android,
  angular,
  appium,
  aws,
  azure,
  blog1,
  blog2,
  blog3,
  c1,
  c2,
  c3,
  c4,
  ccpp,
  counter1,
  counter2,
  counter3,
  counter4,
  devops,
  dotnet,
  flutter,
  fullstack,
  gcp,
  hibernate,
  ios,
  java,
  mean,
  mern,
  nextjs,
  nodeJS,
  oracle,
  php,
  python,
  reactjs,
  reactnative,
  selenium,
  springboot,
  sql,
  t1,
  t2,
  t3,
  t4,
  t5,
  t6,
  t7,
  t8,
  team1,
  team2,
  team3,
  team4,
  team5,
  team6,
  team7,
  team8,
  vue,
  webdesign
} from "./images";

export const achivementCounter = [
  {
    icon: counter1,
    num: 82 + "k",
    sector: "Enrolled Students",
  },
  {
    icon: counter2,
    num: 460,
    sector: "Academic Programs",
  },
  {
    icon: counter3,
    num: 20,
    sector: "Certified Studens",
  },
  {
    icon: counter4,
    num: 200,
    sector: "Award Winnig",
  },
];

export const blogArticleData = [
  {
    title: "Fashion and Luxury Fashion in a Changing",
    img: c1,
  },
  {
    title: "Creative Writing Through Storytelling",
    img: c2,
  },
  {
    title: "Product Manager Learn The Skills & Job",
    img: c3,
  },
  {
    title: "The Power of Podcast for Storytelling",
    img: c4,
  },
];

export const courseData = [
  {
    id: [0, "marketing", "design"],
    post: "Software Development",
    title: "C & CPP Training",
    mprice: " _,999",
    oprice: " _,999",
    img: ccpp,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Software Development",
    title: ".Net Training",
    price: " _4,999",
    img: dotnet,
    lesson: "21 Topics",
    route: "python-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Software Development",
    title: "Python Training",
    price: " _,499",
    img: python,
    lesson: "21 Topics",
    route: "python-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Java",
    title: "Core Java Training",
    mprice: " _,999",
    oprice: " _,999",
    img: java,
    lesson: "21 Topics",
    route: "core-java-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Java",
    title: "Hibernate Training",
    mprice: " _,999",
    oprice: " _,999",
    img: hibernate,
    lesson: "21 Topics",
    route: "hibernate-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Java",
    title: "Spring & Spring Boot Training",
    price: " _4,999",
    img: springboot,
    lesson: "21 Topics",
    route: "spring-training-in-madurai"
  },

  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Web Designing",
    price: " _4,999",
    img: webdesign,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "PHP",
    price: " _4,999",
    img: php,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Angular",
    price: " _4,999",
    img: angular,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "ReactJS",
    price: " _4,999",
    img: reactjs,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "NextJS",
    title: "Web Designing",
    price: " _4,999",
    img: nextjs,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Vue JS",
    price: " _4,999",
    img: vue,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "NodeJS",
    price: " _4,999",
    img: nodeJS,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Mean Stack",
    price: " _4,999",
    img: mean,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Mern Stack",
    price: " _4,999",
    img: mern,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Full Stack Development",
    price: " _4,999",
    img: fullstack,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },

  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "Flutter",
    price: " _4,999",
    img: flutter,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "React Native",
    price: " _4,999",
    img: reactnative,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "Android & Kotlin",
    price: " _4,999",
    img: android,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "iOS & Swift",
    price: " _4,999",
    img: ios,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },

  {
    id: [0, "marketing", "design"],
    post: "Software Testing",
    title: "Selenium",
    price: " _4,999",
    img: selenium,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Software Testing",
    title: "Appium Testing",
    price: " _4,999",
    img: appium,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },

  {
    id: [0, "marketing", "design"],
    post: "Database",
    title: "Oracle",
    price: " _4,999",
    img: oracle,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Database",
    title: "SQL",
    price: " _4,999",
    img: sql,
    level: "Beginner",
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },

  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "AWS",
    price: " _4,999",
    img: aws,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "Google Cloud",
    price: " _4,999",
    img: gcp,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "Microsoft Azure",
    price: " _4,999",
    img: azure,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "DevOps",
    price: " _4,999",
    img: devops,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },

];

export const courseDatat = [
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Angular",
    price: " _4,999",
    img: angular,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "ReactJS",
    price: " _4,999",
    img: reactjs,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "NextJS",
    title: "Web Designing",
    price: " _4,999",
    img: nextjs,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Vue JS",
    price: " _4,999",
    img: vue,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "NodeJS",
    price: " _4,999",
    img: nodeJS,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Mean Stack",
    price: " _4,999",
    img: mean,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Mern Stack",
    price: " _4,999",
    img: mern,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Web Development",
    title: "Full Stack Development",
    price: " _4,999",
    img: fullstack,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "Flutter",
    price: " _4,999",
    img: flutter,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "React Native",
    price: " _4,999",
    img: reactnative,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "Android & Kotlin",
    price: " _4,999",
    img: android,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Mobile App Development",
    title: "iOS & Swift",
    price: " _4,999",
    img: ios,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Software Testing",
    title: "Selenium",
    price: " _4,999",
    img: selenium,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Software Testing",
    title: "Appium Testing",
    price: " _4,999",
    img: appium,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Database",
    title: "SQL",
    price: " _4,999",
    img: sql,
    level: "Beginner",
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "AWS",
    price: " _4,999",
    img: aws,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "Google Cloud",
    price: " _4,999",
    img: gcp,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "Microsoft Azure",
    price: " _4,999",
    img: azure,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
  {
    id: [0, "marketing", "design"],
    post: "Cloud & DevOps",
    title: "DevOps",
    price: " _4,999",
    img: devops,
    lesson: "21 Topics",
    route: "c-and-cpp-training-in-madurai"
  },
];
export const topicsData = [
  { logo: t1, title: "Data Science", quantity: "68 Courses" },
  { logo: t2, title: "UI/UX Design", quantity: "UI/UX Design" },
  { logo: t3, title: "Modern Physics", quantity: "68 Courses" },
  { logo: t4, title: "Music Production", quantity: "45 Courses" },
  { logo: t5, title: "Data Science", quantity: "68 Courses" },
  { logo: t6, title: "Finances", quantity: "68 Courses" },
  { logo: t7, title: "Global Science", quantity: "75 Courses" },
  { logo: t8, title: "Game Design", quantity: "12 Courses" },
];

export const teamData = [
  { img: team1, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team2, name: "Daniel Steven", title: "Developer" },
  { img: team3, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team4, name: "Selina Gomez", title: "UI/UX Designer" },
];
export const instructorData = [
  { img: team5, name: "Erics Widget", title: "UI/UX Designer" },
  { img: team6, name: "Daniel Steven", title: "Developer" },
  { img: team7, name: "Nelson Decosta", title: "Digital Marketer" },
  { img: team8, name: "Selina Gomez", title: "UI/UX Designer" },
];

export const blogsData = [
  {
    img: blog1,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Mobile Painting and Sculpting",
  },
  {
    img: blog2,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Professional Ceramic Moulding for Beginners",
  },
  {
    img: blog3,
    type: "education",
    position: "admin",
    date: "jan 29, 2022",
    title: "Education Is About Create Leaders For Tomorrow",
  },
];

export const courseDetailsInfo = [
  {
    id: "ccpp",
    img: ccpp,
    title: "C CPP Training | Best C CPP Training institute",
    route: "c-and-cpp-training-in-madurai",
    description: "Looking for the top training institute for C and CPP training? Look no further than ALCE. Our comprehensive C and CPP training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for C and CPP training? Look no further than ALCE. Our comprehensive C and CPP training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Software Development",
    title: "C & CPP Training",
    mprice: "6,000",
    oprice: "4,#99",
    overview: `Looking for the top training institute for C and CPP training? Look no further than ALCE. Our comprehensive C and CPP training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. 
    <br></br>Our mentors at ALCE will help you become proficient in key concepts such as data structures, algorithms, loops, conditional statements, references, lambdas, operators, and debugging codes. Apply these problem-solving techniques in real-world scenarios with confidence after completing our training. 
    <br></br>Join ALCE for the best C and CPP training.`,
    curriculam: "",
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to C and CPP programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of C and CPP programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our C and CPP tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time C and CPP developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in C and CPP programming.`,
    lectures: "21",
    duration: "30 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn C / CPP Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the C CPP Course at ALCE ?",
        value: `
          The C CPP at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the C CPP Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the C CPP programming language
        `
      },
      {
        key: "How Can I enroll for the C CPP Course at ALCE ?",
        value: `
          You can enroll for the C CPP Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the C CPP Course at ALCE and will I be given enough practical Training for the C CPP Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The C CPP Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the C CPP Trainers well-equipped at ALCE ?",
        value: `
          The C CPP Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the C CPP Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "C",
        value: [
          "Introduction to C language",
          "Data Types and Variables",
          "Input /Output Management",
          "Control-flow Statements",
          "Modular Programming with Functions",
          "Arrays & Pointers",
          "Structures and Dynamic Memory Allocation",
          "The Preprocessor and Multiple-file Compilation",
          "File Input/Output",
          "Strings",
          "The Case-Control Structure",
          "Miscellaneous Feature"
        ]
      },
      {
        key: "CPP",
        value: [
          "CPP Overview",
          "Functions and Variables",
          "Classes in CPP",
          "Operator Overloading",
          "Initialization and Assignment",
          "Storage Management",
          "Inheritance Polymorphism Input and Output in CPP Programs",
          "Exceptions Template Advanced Featured",
          "Miscellaneous Class Issues"
        ]
      }

    ]

  },
  {
    id: "python",
    img: python,
    title: "Python Training | Best Python Training institute",
    route: "python-training-in-madurai",
    description: "Looking for the top training institute for Python training? Look no further than ALCE. Our comprehensive Python training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Python training? Look no further than ALCE. Our comprehensive Python training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Software Development",
    title: "Python Training",
    mprice: "9,000",
    oprice: "7,#99",
    overview: `The Python Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Python programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Python programmers. 
    <br></br>Throughout this Python training, you will also gain expertise in the commonly used Python web framework, Django, and apply it to real-time projects and programs like a professional Python developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Python programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Python programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Python tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Python developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Python programming.`,
    lectures: "102",
    duration: "60 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Python Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Python Course at ALCE ?",
        value: `
          The Python  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Python Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Python programming language
        `
      },
      {
        key: "How Can I enroll for the Python Course at ALCE ?",
        value: `
          You can enroll for the Python Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Python Course at ALCE and will I be given enough practical Training for the Python Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Python Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Python Trainers well-equipped at ALCE ?",
        value: `
          The Python Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Python Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Introduction to Python",
        value: [
          "What is Python and history of Python",
          "Unique features of Python",
          "Python-2 and Python-3 differences",
          "Installing Python",
          "Setup Python Development Environment"
        ]
      },
      {
        key: "Important Programming Basics in Python",
        value: [
          "Python Keywords and Indentation",
          "Comments",
          "Python Basic Data Types",
          "Python Variables",
          "Operators in Python",
          "Strings in Python",
          "Getting User Input",
          "First Python Program"
        ]
      },
      {
        key: "Loops & Control Statements",
        value: [
          "Simple if",
          "if-else",
          "nested if",
          "if-elif-else",
          "for loop",
          "while loop",
          "Break and Continue Statements"
        ]
      },
      {
        key: "Functions, Modules & Packages in Python",
        value: [
          "Python user defined functions",
          "Defining and calling functions",
          "Function parameters",
          "Function scope and global vs local variables",
          "Lambda functions",
          "Anonymous functions",
          "Creating and using modules",
          "Importing modules and namespaces",
          "The __name__ and __main__ keywords",
          "Creating and using packages",
          "Using the standard library and external libraries"
        ]
      },
      {
        key: "Data Structures in Python",
        value: [
          "Lists in Python",
          "Lists as Stacks",
          "Lists as Queues",
          "Tuples in Python",
          "Understanding Del statement",
          "Understanding Iterators",
          "Generators, Comprehensions and Lambda",
          "Expressions",
          "Understanding and using Ranges",
          "Python Dictionaries",
          "More on Dictionaries",
          "Sets",
          "Python Sets Examples"
        ]
      },
      {
        key: "Data Structures in Python",
        value: [
          "Lists in Python",
          "Lists as Stacks",
          "Lists as Queues",
          "Tuples in Python",
          "Understanding Del statement",
          "Understanding Iterators",
          "Generators, Comprehensions and Lambda",
          "Expressions",
          "Understanding and using Ranges",
          "Python Dictionaries",
          "More on Dictionaries",
          "Sets",
          "Python Sets Examples"
        ]
      },
      {
        key: "Exception Handling in Python",
        value: [
          "Raising Exceptions",
          "Handling Exceptions",
          "Creating custom Exceptions",
          "Using try",
          "Using except",
          "Using finally"
        ]
      },
      {
        key: "Multithreading in Python",
        value: [
          "Creating Threads",
          "Thread synchronization",
          "Thread pools",
          "Multiprocessing Module"
        ]
      },
      {
        key: "File Handling (I/O) in Python",
        value: [
          "Reading and writing text files",
          "Writing Text Files",
          "Appending to Files and Challenge",
          "Writing Binary Files Manually",
          "Using Pickle to Write Binary Files"
        ]
      },
      {
        key: "Collections in Python",
        value: [
          "Understanding the basics of Collections"
        ]
      },
      {
        key: "Object Oriented Programming in Python",
        value: [
          "Understanding OOPS in Python",
          "Defining and using Classes",
          "Defining and using Objects",
          "Mastering Encapsulation in Python",
          "Mastering Inheritance in Python",
          "Mastering Polymorphism in Python",
          "Accessing attributes",
          "Built-In Class Attributes",
          "Destroying Objects"
        ]
      },
      {
        key: "Python Regular Expressions",
        value: [
          "What are regular expressions? ",
          "The match Function",
          "The search Function",
          "Matching vs searching",
          "Search and Replace",
          "Extended Regular Expressions",
          "Wildcard"
        ]
      },
      {
        key: "Database Connectivity in Python",
        value: [
          "Understanding relational databases Understanding the role of SQL",
          "Creating and connecting to databases using MySQL or Oracle",
          "Understanding the concept of tables",
          "Understanding fields and primary keys",
          "Creating tables",
          "Inserting data into tables",
          "Querying data using SQL",
          "Delete records from tables",
          "Error handling"
        ]
      },
      {
        key: "Network Programming",
        value: [
          "Introduction to Sockets",
          "Understanding Clients",
          "Understanding Server",
          "Handling http requests"
        ]
      }

    ]

  }, 
  {
    id: "dotnet",
    img: dotnet,
    title: "Dot Net Training | Best Spring Training institute",
    route: "dot-net-training-in-madurai",
    description: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Software Development",
    title: "Dot Net Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The Spring Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Spring programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Spring programmers. 
    <br></br>Throughout this Spring training, you will also gain expertise in the commonly used Spring web framework, Django, and apply it to real-time projects and programs like a professional Spring developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "18",
    duration: "30 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Spring Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Spring Course at ALCE ?",
        value: `
          The Spring  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Spring Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Spring programming language
        `
      },
      {
        key: "How Can I enroll for the Spring Course at ALCE ?",
        value: `
          You can enroll for the Spring Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Spring Course at ALCE and will I be given enough practical Training for the Spring Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Spring Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Spring Trainers well-equipped at ALCE ?",
        value: `
          The Spring Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Spring Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Spring Syllabus",
        value: [
          "What Is Spring Framework and Spring Introduction",
          "Spring Modules, What Are Spring Modules",
          "Spring MVC",
          "Spring Boot",
          "Spring Security",
          "Dependency Injection In Spring Framework",
          "Example On Spring Dependency In The Form Of Objects",
          "Setter Injection, Dependency In The Form Of Collections",
          "Constructor Injection In Spring [ Full Concept ]",
          "Difference between Setter Injection and Constructor Injection",
          "Spring Bean Autowiring",
          "Spring JDBC",
          "Spring AOP(Aspect Oriented Programming)",
          "Example On Spring AOP Before Advice, After Advice",
          "Spring AOP Throws Advice Example With Complete Explanation",
          "Spring AOP Around Advice Example With Complete Explanation",
          "Spring AOP JoinPoint, What Is JoinPoint In Spring Framework",
          "Spring AOP Pointcut Example"
        ]
      }

    ]

  },
  {
    id: "java",
    img: java,
    title: "Core Java Training | Best Core Java Training institute",
    route: "core-java-training-in-madurai",
    description: "Looking for the top training institute for Core Java training? Look no further than ALCE. Our comprehensive Core Java training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Core Java training? Look no further than ALCE. Our comprehensive Core Java training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Java",
    title: "Core Java Training",
    mprice: "9,000",
    oprice: "7,#00",
    overview: `The Core Java Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Core Java programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Core Java programmers. 
    <br></br>Throughout this Core Java training, you will also gain expertise in the commonly used Core Java web framework, Django, and apply it to real-time projects and programs like a professional Core Java developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Core Java programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Core Java programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Core Java tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Core Java developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Core Java programming.`,
    lectures: "61",
    duration: "40 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Core Java Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Core Java Course at ALCE ?",
        value: `
          The Core Java  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Core Java Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Core Java programming language
        `
      },
      {
        key: "How Can I enroll for the Core Java Course at ALCE ?",
        value: `
          You can enroll for the Core Java Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Core Java Course at ALCE and will I be given enough practical Training for the Core Java Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Core Java Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Core Java Trainers well-equipped at ALCE ?",
        value: `
          The Core Java Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Core Java Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Introduction to Java",
        value: [
          "Object-oriented programming Fundamentals",
          "Structure of a java program"
        ]
      },
      {
        key: "Data Types",
        value: [
          "Primitive data types",
          "Reference data types",
          "Keywords, Identifiers, Expressions"
        ]
      },
      {
        key: "Operators",
        value: [
          "Arithmetic operators",
          "Logical operators",
          "Bitwise operators",
          "Assignment operators",
          "Relational operators"
        ]
      },
      {
        key: "Variables",
        value: [
          "Declaration, Definition, Types"
        ]
      },
      {
        key: "Methods",
        value: [
          "Syntax, Types"
        ]
      },
      {
        key: "Object",
        value: [
          "Object Creation, Reference, Reference Variables"
        ]
      },
      {
        key: "Constructors",
        value: [
          "Pass by value and Pass by reference",
          "Access Specifiers",
          "Access Levels",
          "Decision Making and Control Structures"
        ]
      },
      {
        key: "Strings",
        value: [
          "Decision Making and Control Structures"
        ]
      },
      {
        key: "Java Beans - Arrays",
        value: [
          "Declaring and defining arrays",
          "Object Arrays",
          "Has-A relationship",
          "Inheritance using the implements keyword",
          "Primitive Arrays",
          "Is-A relationship",
          "Inheritance using extends keyword"
        ]
      },
      {
        key: "Abstraction",
        value: [
          "Abstraction using abstract classes",
          "AAbstraction using interfaces"
        ]
      },
      {
        key: "Encapsulation - Interfaces",
        value: [
          "Interfaces vs Classes",
          "Nested Interfaces",
          "The interface is a type"
        ]
      },
      {
        key: "Polymorphism",
        value: [
          "Overloading",
          "Overloading between classes",
          "Constructor overloading",
          "Overriding"
        ]
      },
      {
        key: "Exception Handling",
        value: [
          "Exception",
          "Throw and throws keywords",
          "Categories of Exception, Exception hierarchy",
          "Try catch and finally keywords"
        ]
      },
      {
        key: "Collection Framework",
        value: [
          "Core interfaces",
          "Iterator",
          "Core Classes",
          "Comparable & Comparator"
        ]
      },
      {
        key: "Generics",
        value: [
          "Autoboxing",
          "Unboxing"
        ]
      },
      {
        key: "Casting",
        value: [
          "Primitive casting",
          "Reference casting",
          "Upcasting and downcasting"
        ]
      },
      {
        key: "Casting",
        value: [
          "Primitive casting",
          "Reference casting",
          "Upcasting and downcasting"
        ]
      },
      {
        key: "File Handling",
        value: [
          "File Handling in java",
          "Files, Streams, Types of streams"
        ]
      },
      {
        key: "Serialization",
        value: [

        ]
      },
      {
        key: "Threads",
        value: [
          "Thread life cycle",
          "Creating Threads",
          "Thread group",
          "Thread States",
          "Thread priorities",
          "Synchronization"
        ]
      },
      {
        key: "Inner Classes",
        value: [
          "Nested Classes",
          "Anonymous Classes"
        ]
      }

    ]

  },
  {
    id: "hibernate",
    img: hibernate,
    title: "Hibernate Training | Best Hibernate Training institute",
    route: "hibernate-training-in-madurai",
    description: "Looking for the top training institute for Hibernate training? Look no further than ALCE. Our comprehensive Hibernate training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Hibernate training? Look no further than ALCE. Our comprehensive Hibernate training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Java",
    title: "Hibernate Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The Hibernate Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Hibernate programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Hibernate programmers. 
    <br></br>Throughout this Hibernate training, you will also gain expertise in the commonly used Hibernate web framework, Django, and apply it to real-time projects and programs like a professional Hibernate developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Hibernate programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Hibernate programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Hibernate tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Hibernate developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Hibernate programming.`,
    lectures: "25",
    duration: "30 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Hibernate Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Hibernate Course at ALCE ?",
        value: `
          The Hibernate  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Hibernate Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Hibernate programming language
        `
      },
      {
        key: "How Can I enroll for the Hibernate Course at ALCE ?",
        value: `
          You can enroll for the Hibernate Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Hibernate Course at ALCE and will I be given enough practical Training for the Hibernate Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Hibernate Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Hibernate Trainers well-equipped at ALCE ?",
        value: `
          The Hibernate Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Hibernate Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Hibernate Syllabus",
        value: [
          "What is Hibernate – Hibernate Introduction",
          "Mapping And Configuration Files In Hibernate",
          "Main Advantage And Disadvantages Of Hibernates",
          "Example On Hibernate Select Query",
          "Example On Hibernate Delete Query",
          "Example On Hibernate Update Query",
          "Hibernate Versioning Example, Hibernate Versioning Of Objects",
          "Importance Of Wrapper And Primitive Types In Hibernate",
          "Hibernate Lifecycle Of POJO Class Objects",
          "Hibernate Converting Object From Detached to Persistent state",
          "Inheritance Mapping In Hibernate – Introduction",
          "Hibernate Inheritance: Table Per Class Hierarchy",
          "Hibernate Inheritance: Table Per subClass Hierarchy",
          "Hibernate Inheritance: Table Per Concrete Class Hierarchy",
          "Example On Composite Primary Keys In Hibernate",
          "Composite Primary Key In Hibernate With Select Query",
          "Generators In Hibernate",
          "Hibernate Query Language",
          "Criteria Query, Hibernate Criteria Query Introduction",
          "Hibernate Native SQL Query Example",
          "Hibernate Named Query",
          "Hibernate Mapping",
          "Joins In Hibernate",
          "Hibernate Caching Mechanism, Hibernate Cache",
          "Hibernate Annotations"
        ]
      }
    ]

  },
  {
    id: "spring",
    img: springboot,
    title: "Spring Training | Best Spring Training institute",
    route: "spring-training-in-madurai",
    description: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Java",
    title: "Spring & Spring Boot Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The Spring Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Spring programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Spring programmers. 
    <br></br>Throughout this Spring training, you will also gain expertise in the commonly used Spring web framework, Django, and apply it to real-time projects and programs like a professional Spring developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "18",
    duration: "30 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Spring Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Spring Course at ALCE ?",
        value: `
          The Spring  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Spring Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Spring programming language
        `
      },
      {
        key: "How Can I enroll for the Spring Course at ALCE ?",
        value: `
          You can enroll for the Spring Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Spring Course at ALCE and will I be given enough practical Training for the Spring Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Spring Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Spring Trainers well-equipped at ALCE ?",
        value: `
          The Spring Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Spring Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Spring Syllabus",
        value: [
          "What Is Spring Framework and Spring Introduction",
          "Spring Modules, What Are Spring Modules",
          "Spring MVC",
          "Spring Boot",
          "Spring Security",
          "Dependency Injection In Spring Framework",
          "Example On Spring Dependency In The Form Of Objects",
          "Setter Injection, Dependency In The Form Of Collections",
          "Constructor Injection In Spring [ Full Concept ]",
          "Difference between Setter Injection and Constructor Injection",
          "Spring Bean Autowiring",
          "Spring JDBC",
          "Spring AOP(Aspect Oriented Programming)",
          "Example On Spring AOP Before Advice, After Advice",
          "Spring AOP Throws Advice Example With Complete Explanation",
          "Spring AOP Around Advice Example With Complete Explanation",
          "Spring AOP JoinPoint, What Is JoinPoint In Spring Framework",
          "Spring AOP Pointcut Example"
        ]
      }

    ]

  },
  {
    id: "webdesign",
    img: webdesign,
    title: "Dot Net Training | Best Spring Training institute",
    route: "web-designing-training-in-madurai",
    description: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Spring training? Look no further than ALCE. Our comprehensive Spring training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "Web Designing Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The Spring Training at ALCE is an extensive program designed to help you specialize in key programming concepts such as basic Spring programs, loops and control statements, functions, modules, data structures, OOP, collections, HTML, CSS, and capstone projects under the mentorship of real-time Spring programmers. 
    <br></br>Throughout this Spring training, you will also gain expertise in the commonly used Spring web framework, Django, and apply it to real-time projects and programs like a professional Spring developer!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "18",
    duration: "30 Hrs",
    level: "Beginner",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Spring Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Spring Course at ALCE ?",
        value: `
          The Spring  at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Spring Programming language
          <br></br>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Spring programming language
        `
      },
      {
        key: "How Can I enroll for the Spring Course at ALCE ?",
        value: `
          You can enroll for the Spring Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Spring Course at ALCE and will I be given enough practical Training for the Spring Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Spring Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Spring Trainers well-equipped at ALCE ?",
        value: `
          The Spring Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Spring Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Spring Syllabus",
        value: [
          "What Is Spring Framework and Spring Introduction",
          "Spring Modules, What Are Spring Modules",
          "Spring MVC",
          "Spring Boot",
          "Spring Security",
          "Dependency Injection In Spring Framework",
          "Example On Spring Dependency In The Form Of Objects",
          "Setter Injection, Dependency In The Form Of Collections",
          "Constructor Injection In Spring [ Full Concept ]",
          "Difference between Setter Injection and Constructor Injection",
          "Spring Bean Autowiring",
          "Spring JDBC",
          "Spring AOP(Aspect Oriented Programming)",
          "Example On Spring AOP Before Advice, After Advice",
          "Spring AOP Throws Advice Example With Complete Explanation",
          "Spring AOP Around Advice Example With Complete Explanation",
          "Spring AOP JoinPoint, What Is JoinPoint In Spring Framework",
          "Spring AOP Pointcut Example"
        ]
      }

    ]

  },
  {
    id: "php",
    img: php,
    title: "PHP Training | ALCE",
    route: "php-training-in-madurai",
    description: "Looking for the top training institute for PHP training? Look no further than ALCE. Our comprehensive PHP training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for PHP training? Look no further than ALCE. Our comprehensive PHP training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "PHP Training",
    mprice: "9,000",
    oprice: "7,#99",
    overview: `The PHP Training at ALCE is delivered by industry professionals with extensive hands-on sessions and a real-time project. <br/><br/>Master key concepts such as designing websites, database management, form handling, implementing authentication, developing server-side scripts, creating web services, and building professional-grade interactive websites and web applications using PHP and MySQL.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "77",
    duration: "45 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn PHP at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the PHP Course at ALCE ?",
        value: `
          The PHP at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the PHP Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the PHP programming language
        `
      },
      {
        key: "How Can I enroll for the PHP Course at ALCE ?",
        value: `
          You can enroll for the PHP Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the PHP Course at ALCE and will I be given enough practical Training for the PHP Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The PHP Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the PHP Trainers well-equipped at ALCE ?",
        value: `
          The PHP Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the PHP Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Creating, Managing and Mastering MySQL - MySQL Database",
        value: [
          "Installing and Configuring MySQL",
          "Understanding MySQL",
          "Creating and Managing Database",
          "MySQL data types",
          "Creating Tables and Managing Tables",
          "Data Manipulation in Tables – Insert, Update, Delete",
          "Querying Data from Tables – Select, Joins",
          "Advanced Data Manipulation – Groups, Functions, Constraints, Index",
          "Handling Transactions in MySQL",
        ]
      },
      {
        key: "Developing Web Applications using PHP - Introduction to PHP",
        value: [
          "What is PHP",
          "The Power of PHP",
          "Learning the history of PHP",
          "Javascript / Jquery Vs PHP",
          "What is Server Side Scripting",
          "Client Side Scripting Vs Server Side Scripting",
          "Installation of XAMPP",
          "Importance of Apache Server",
          "Configuring PHP and setting up the Environment",
          "Working with Editors"
        ]
      },
      {
        key: "Basics of PHP",
        value: [
          "Coding PHP in HTML Document",
          "Embedding PHP Codes",
          "Executing PHP Embedded HTML Documents",
          "PHP Coding Structure",
          "Variables in PHP",
          "Data types in PHP – Number, Strings, Date, NULL",
          "Arrays and Variations of Arrays",
          "Control Structures of PHP"
        ]
      },
      {
        key: "Decision Making with IF Statements",
        value: [
          "Variations of IF Statements",
          "Switch Statements",
          "Using Operators with Decision Making Structures",
          "Looping Structures",
          "While Loops, For Loops, Foreach Loops",
          "Using CONTINUE and BREAK in Loops",
          "Managing Arrays with Control Structures",
          "Functions in PHP"
        ]
      },
      {
        key: "Understanding Functions in PHP",
        value: [
          "Using Built-in Functions – Date, String and Mathematical Functions",
          "Handling Return Value",
          "Nesting Functions",
          "User Defined Functions in PHP"
        ]
      },
      {
        key: "Defining our Own Functions",
        value: [
          "Understanding Arguments and Return Values",
          "Scope of Functions",
          "Handling Multiple return values",
          "Defining default Arguments",
          "File Handling in PHP"
        ]
      },
      {
        key: "Basics of File Handling and need to handle Files from PHP",
        value: [
          "Creating a File using PHP",
          "Reading, Writing, Appending Files",
          "Copy and Rename Files",
          "Delete Files",
          "Working with Directories",
          "Parsing Directory Structures",
          "Uploading Files",
          "Handling FORMS in PHP"
        ]
      },
      {
        key: "Understanding PHP Forms",
        value: [
          "Validating User Inputs",
          "Displaying Messages for Validation Errors",
          "Using Functions for Validations",
          "Creating Custom Functions for Data Validation",
          "Handling Form Submissions",
          "Handling Exceptions",
          "Accessing MySQL with PHP"
        ]
      },
      {
        key: "Introduction to Database APIs",
        value: [
          "Establishing Connection with Databases",
          "Retrieving data from Database",
          "Manipulating Retrieved Data",
          "Updating Records in Database using PHP",
          "Deleting Records in Database using PHP",
          "Understanding SQL Injection",
          "Sessions and Cookies"
        ]
      },
      {
        key: "Understanding Cookies",
        value: [
          "Purpose and use of Cookies",
          "Setting and Reading Cookie Values",
          "Resetting Cookie Values",
          "Understanding Sessions and importance of Sessions",
          "Starting a Session and Working with Session",
          "Understanding Session Variables",
          "Closing Sessions",
          "Using Emails for Form Submission in PHP"
        ]
      },
      {
        key: "Email Configuration in PHP",
        value: [
          "Need for Sending Mails from Web Applications",
          "Using Sendmail",
          "Using PHPMailer"
        ]
      }
    ]
  },
  {
    id: "oracle",
    img: oracle,
    title: "Oracle Training | ALCE",
    route: "oracle-training-in-madurai",
    description: "Looking for the top training institute for Oracle training? Look no further than ALCE. Our comprehensive Oracle training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Oracle training? Look no further than ALCE. Our comprehensive Oracle training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Database",
    title: "Oracle Training",
    mprice: " 18,000",
    oprice: " 1#,999",
    overview: `The Oracle Training at ALCE is delivered by industry professionals with extensive hands-on sessions and a real-time project. <br/><br/>Master key concepts such as designing websites, database management, form handling, implementing authentication, developing server-side scripts, creating web services, and building professional-grade interactive websites and web applications using Oracle and MySQL.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "154",
    duration: "45 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Oracle at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Oracle Course at ALCE ?",
        value: `
          The Oracle at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Oracle Course at ALCE ?",
        value: `
          You can enroll for the Oracle Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Oracle Course at ALCE and will I be given enough practical Training for the Oracle Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Oracle Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Oracle Trainers well-equipped at ALCE ?",
        value: `
          The Oracle Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Oracle Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Oracle SQL",
        value: [
          "What is a Database?",
          "Oracle Database",
          "Data Model",
          "Normalization"
        ]
      },
      {
        key: "Kinds of SQL Languages",
        value: [
          "(DDL) Data Definition Language: ALTER, DROP, TRUNCATE, & CREATE",
          "(DML) Data Manipulation Language: UPDATE, INSERT, SELECT, & DELETE",
          "(DCL) Data Control Language: GRANT, REVOKE",
          "(TCL) Transaction Control Language: COMMIT, SAVEPOINT, & ROLLBACK"
        ]
      },
      {
        key: "SELECT Statements & Conditions types",
        value: [
          "Listing Capabilities of the SQL Select Statements",
          "Select Specific Columns",
          "Select All Columns",
          "Use the Column Heading Defaults",
          "DESC Command",
          "Condition Types: BETWEEN, IN, NOT IN, AND, OR, LIKE, IS, IS NULL, EXISTS, NOT NULL"
        ]
      },
      {
        key: "Restrict, Group, Sort, Filter Data",
        value: [
          "How to Restrict Data using the WHERE Clause",
          "Operators",
          "Precedence of Operators",
          "Sorting of Data using the GROUP BY Clause",
          "Group the Data using the GROUP BY clause",
          "Filtering the groups of date by using Having Clause"
        ]
      },
      {
        key: "Data Types",
        value: [
          "Character",
          "Number",
          "Row and Long",
          "Time and Date",
          "Large Object Data Types",
          "Pseudo Columns"
        ]
      },
      {
        key: "SQL Constraints",
        value: [
          "Unique Constraint",
          "Primary Key Constraint ",
          "Foreign Key Constraint",
          "Check Constraint",
          "Not Null Constraint",
          "Default Constraint",
          "Alter Constraints, Remove, Guidelines to Add"
        ]
      },
      {
        key: "SQL Joins",
        value: [
          "Outer join",
          "Inner join",
          "Right Outer Join",
          "Left Outer Join",
          "Semi Join, Anti Join, and Self-join",
          "Union All, Union, Minus, and Intersect"
        ]
      },
      {
        key: "SQL Functions",
        value: [
          "Single Row Functions",
          "NULL - Related Functions: LNNVL, NANVL, NVL, COALESCE, NVL2",
          "Numeric Functions: CIEL, FLOOR, ABS, MOD, REMAINDER, POWER, SIGN, TRUNC, ROUND",
          "Character Functions: CONCAT, CHR, INITCAP, LPAD, LOWER, REPLACE, LTRIM, RPAD, RTRIM, SUBSTR, NLSSORT, INSTR, LENGTH, ASCII. ",
          "Time/Date Functions: CURRENT_DATE, LOCALTIMESTAMP, ADD_MONTHS, CURRENT_TIMESTAMP, MONTHS_BETWEEN, SESSIONTIMEZONE, ROUND, SYSDATE, SYSTIMESTAMP, TO_CHAR (DATETIME), TO_TIMESTAMP_TZ, TRUNC(DATE), TO_TIMESTAMP",
          "Conversion Functions: CAST, CONVERT, HEXTORAW, ASCIISTR, TO_CHAR (DATETIME), TO_CHAR (NUMBER), TO_DATE, TO_NUMBER,RAWTOHEX, TO_CHAR (character).",
          "Conditional Functions: CASE and DECODE",
          "Case Conversion functions: UPPER, LOWER, AND INITCAP",
          "Multi- ROW SQL Functions: COUNT, MAX, MIN, SUM, AVG",
          "AnalyticFunctions: LAG, LEAD, RANK,WM_CONCAT,DENSE_RANK"
        ]
      },
      {
        key: "Sub Queries",
        value: [
          "What is a Subquery?",
          "Scalar Subquery",
          "Inline Subquery",
          "Scalar Subquery Caching",
          "Recursive WITH Clause",
          "Invoke the WITH Clause"
        ]
      },
      {
        key: "Set Operators, Multi-row Insert",
        value: [
          "INSERT using Subquery ",
          "INSERT ALL",
          "Set Operators: UNION ALL, INTERSECT, MINUS, and UNION",
          "Conditional FIRST INSERT "
        ]
      },
      {
        key: "Hierarchical Data Retrieval, Nth row",
        value: [
          "Connect By ROWNUM, Connect By Prior, Connect By Level ∙ START WITH",
          "RANK & DENSE_RANK Functions, Nth row using ROWNUM"
        ]
      },
      {
        key: "Materialized View, Synonyms, Sequence, and Views",
        value: [
          "Complex and Simple Views",
          "DML Operations on a View ",
          "Inline Views ",
          "WITH reading ONLY ",
          "WITH CHECK OPTION",
          "Materialized View – Refresh, Drop - Usage ∙ Synonym Creation, Create, Sequence Creation, Granting Access to Synonyms, CURRVAL, NEXTVAL of Sequence"
        ]
      },
      {
        key: "ORACLE PL/SQL - PL/SQL Basics",
        value: [
          "Knowing what is PL/SQL ",
          "Basics of PL/SQL",
          "Block Structure ",
          "Data Types of PL/SQL",
          "Scope & Variable",
          "COMMENT",
          "SET SERVER OUTPUT ON"
        ]
      },
      {
        key: "PL/SQL Conditions",
        value: [
          "IF THEN ",
          "IF THEN ELSIF ",
          "IF THEN ELSE ",
          "IF THEN ELSIF ",
          "NESTED IF ",
          "CASE ",
          "Difference between DECODE and CASE"
        ]
      },
      {
        key: "PL/SQL Loops",
        value: [
          "Fundamentals of LOOP Statement",
          "EXIT Statement ",
          "EXIT WHEN Statement ",
          "FOR LOOP Statement ",
          "WHILE LOOP Statement",
          "CONTINUE Statement",
          "CONTINUE WHEN Statement",
          "GOTO Statement",
          "Control between Loops",
          "Control Flow in the Block",
          "Labeling the Blocks and Loops"
        ]
      },
      {
        key: "Functions, Procedures",
        value: [
          "What is a Procedure",
          "Distinguish between Anonymous Block and Procedure",
          "Creating Procedure",
          "Input and Output parameters for a Procedure ",
          "Executing and Compiling Procedures",
          "Creation of Private Procedures",
          "Calling Procedures from Functions, Other Procedures, Packages",
          "Compiling Procedures ",
          "Executing Procedures ",
          "What is a Function?",
          "Difference between Function and Procedure",
          "Creation of Function",
          "Executing Function ",
          "Compiling Function ",
          "Input and Output Parameters to a Function ",
          "Overloading Procedure, Calling Functions from other Functions, Packages,",
          "Procedures, and Function."
        ]
      },
      {
        key: "PL/SQL Cursor",
        value: [
          "What is a Cursor",
          "Explicit and Implicit Cursors",
          "Cursor Variables ",
          "Ref Cursors",
          "Cursor Attributes",
          "OPEN, FETCH, CLOSE, EXIT Cursor"
        ]
      },
      {
        key: "PL/SQL Exceptions",
        value: [
          "Knowing what is Exception",
          "System Defined Exceptions",
          "User-Defined Exceptions",
          "RAISE_APPLICATION_ERROR Procedure"
        ]
      },
      {
        key: "PL/SQL Triggers",
        value: [
          "Outline of Triggers",
          "Creation of Triggers",
          "System Triggers and DML",
          "Row Level Triggers",
          "Statement Level Triggers",
          "Managing Triggers",
          "Order in which the Triggers fire the multiple triggers on the same table",
          "Mutating Oracle Trigger"
        ]
      },
      {
        key: "PL/SQL Packages",
        value: [
          "What is Package?",
          "Needs to use Packages",
          "Package Body ",
          "Package Specification ",
          "Package Writing Guidelines",
          "Package Example ",
          "Package Instantiation",
          "Package Initialization ",
          "GTT - Global Temporary Table ",
          "Use the UTL_FILE Package ",
          "DBMS_OUTPUT Package Work ",
          "Use the UTL_FILE Package ",
          "PRAGMA AUTONOMOUS TRANSACTION "
        ]
      },
      {
        key: "PL/SQL Collections",
        value: [
          "Nested Tables ",
          "Index By Tables ",
          "Associate Arrays ",
          "VARRAY (Variable Size Array) ",
          "LIMIT in BULK COLLECTION ",
          "BULK COLLECTION ",
          "Exception handling in BULK COLLECTION"
        ]
      },
      {
        key: "Dynamic SQL",
        value: [
          "FETCH, OPEN FOR, and CLOSE Statements ",
          "EXECUTE IMMEDIATE Statement ",
          "DBMS_SQL Package ",
          "Repeated Placeholder Names in Dynamic SQL Statements",
          "DBMS_SQL.TO_CURSOR _NUMBER Function ",
          "DBMS_SQL.TO_REFCURSOR Function ",
          "SQL Injection ",
          "SQL Injection Techniques ",
          "Guarding Against SQL Injection"
        ]
      }
    ]
  },
  {
    id: "reactjs",
    img: reactjs,
    title: "ReactJS Training | ALCE",
    route: "reactjs-training-in-madurai",
    description: "Looking for the top training institute for ReactJS Training? Look no further than ALCE. Our comprehensive ReactJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for ReactJS Training? Look no further than ALCE. Our comprehensive ReactJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "ReactJS Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `With the help of experienced React JS trainer, you can effectively learn core ideas like the React JS Framework, Components, and Architecture with the React JS Course. Comprehensive instruction in key concepts including Events, Props, Stats, JSX, Redux, Hooks, and Performance Optimization is provided via the training program. <br/><br/>Two real-world capstone projects are included in the course to give students practical exposure with the React JS Framework. You'll obtain access to our placement cell and obtain a useful qualification upon course completion, which will open doors to lucrative employment opportunities. Enroll in the React JS Course to start improving your future.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "34",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn ReactJS at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the ReactJS Course at ALCE ?",
        value: `
          The ReactJS at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the ReactJS Course at ALCE ?",
        value: `
          You can enroll for the ReactJS Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the ReactJS Course at ALCE and will I be given enough practical Training for the ReactJS Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The ReactJS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the ReactJS Trainers well-equipped at ALCE ?",
        value: `
          The ReactJS Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the ReactJS Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "Introduction To React & JSX",
        value: [
          "Origins of React",
          "js Syntax",
          "Overview of JSX and why you should use it",
          "Getting hold of everything you need to start coding"
        ]
      },
      {
        key: "React Components",
        value: [
          "React component Properties",
          "Setting Properties",
          "Component Lifecycle",
          "Updating Components",
          "Writing your first React.js component",
          "Mounting Components"
        ]
      },
      {
        key: "JSX",
        value: [
          "Expressions & Attributes",
          "JSX Basics",
          "Namespaced Components",
          "Rendering HTML",
          "Rendering React Components"
        ]
      },
      {
        key: "Flux",
        value: [
          "Views & Controller-Views",
          "Flux is not MVC!",
          "Data Flow",
          "Action, Dispatcher, Store & View",
          "Flux Application Architecture",
          "Structure"
        ]
      },
      {
        key: "Event Handling In React",
        value: [
          "Key Events",
          "Event Pooling",
          "js Event Handlers",
          "Synthetic Event"
        ]
      },
      {
        key: "Creating A Dynamic UI In React",
        value: [
          "Keeping components stateless",
          "Event Delegation",
          "React Stateful Components",
          "Auto binding"
        ]
      },
      {
        key: "Integration With Other Libraries",
        value: [
          "Gulp & Browserify",
          "React with jQuery",
          "React & AJAX"
        ]
      },
      {
        key: "React Server Integration & Deployment",
        value: [
          "https",
          "npm"
        ]
      },
    ]
  },
  {
    id: "angular",
    img: angular,
    title: "AngularJS Training | ALCE",
    route: "angularJS-training-in-madurai",
    description: "Looking for the top training institute for AngularJS Training? Look no further than ALCE. Our comprehensive AngularJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for AngularJS Training? Look no further than ALCE. Our comprehensive AngularJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "AngularJS Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `Prepare yourself for a life-changing experience as we walk you through the world of AngularJS and impart the knowledge and abilities necessary to become an effective AngularJS trainer. AngularJS encompasses a number of concepts that you will learn about, such as its modules, components, routing, forms, authorizations, animations, performance, optimization, deployment, and data and event bindings. ALCE has led the way in offering cutting-edge Angular Training because of its well-thought-out curriculum, skilled instructors, and updated laboratories for practical instruction. In addition, ALCE offers a specialized placement cell that prepares students for internships and employment.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "156",
    duration: "45 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn AngularJS at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the AngularJS Course at ALCE ?",
        value: `
          The AngularJS at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the AngularJS Course at ALCE ?",
        value: `
          You can enroll for the AngularJS Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the AngularJS Course at ALCE and will I be given enough practical Training for the AngularJS Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The AngularJS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the AngularJS Trainers well-equipped at ALCE ?",
        value: `
          The AngularJS Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the AngularJS Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "UNDERSTANDING JAVASCRIPT",
        value: [
          "Introduction to Javascript",
          "Control Structures",
          "Loops in Javascript",
          "Operators in Javascript",
          "Handling functions",
          "Objects and Functions in Javascript",
          "Javascript DOM",
          "Javascript Events and Event Handling"
        ]
      },
      {
        key: "GETTING INTO THE ANGULAR WORLD",
        value: [
          "Angular Advantages",
          "Opportunities for Angular",
          "Angular Versions",
          "AngularJS to Angular 6",
          "Semantic Versioning"
        ]
      },
      {
        key: "INTRODUCTION TO TYPESCRIPT",
        value: [
          "Understanding TypeScript",
          "Basic Types",
          "Working with Variables",
          "Working with Arrays",
          "Working with Classes",
          "Working with Objects",
          "Working with Interfaces",
          "Working with Constructors",
          "Type Definitions",
          "Compiling TypeScript",
          "Linting"
        ]
      },
      {
        key: "ES2015 PRIMER",
        value: [
          "Understanding Let and Const",
          "Working with Template Strings",
          "Mastering Lambda Functions",
          "Working with Generics",
          "Working with Modules"
        ]
      },
      {
        key: "ENVIRONMENT SETUP",
        value: [
          "Node / NPM",
          "Polyfills",
          "Babels",
          "TypeScript",
          "Module Bundler (Webpack)",
          "Code Linting",
          "Test Setup",
          "Application File Structure",
          "AngularCLI",
          "Code Editors"
        ]
      },
      {
        key: "MODULES",
        value: [
          "Why and when to use Modules",
          "Understanding NgModule",
          "Working with Declarations in Modules",
          "Using Providers",
          "Managing Imports",
          "How to use Bootstrapping",
          "The Core Module",
          "Shared Modules"
        ]
      },
      {
        key: "COMPONENTS",
        value: [
          "Introduction to Components",
          "Working with Component Architecture Patterns",
          "Decorator Metadata",
          "State & Behaviour in Angular",
          "Inputs and Outputs"
        ]
      },
      {
        key: "TEMPLATES",
        value: [
          "Inline vs External",
          "Mastering Template Expressions",
          "Data Bindings",
          "Data Bindings Syntax",
          "Working with Data & Event Bindings",
          "Working with Built-in Structural Directives",
          "Working with Built-in Attribute Directives"
        ]
      },
      {
        key: "CUSTOM DIRECTIVES",
        value: [
          "Directives Overview",
          "Types of Directive",
          "Create your own Attribute Directive",
          "Writing Attribute Directive Code",
          "Respond to User-Initiated Events",
          "Pass values into the directive with an @Input data binding",
          "Binding to an @Input property",
          "Bind to an @Input alias",
          "Write a harness to try it",
          "Create your own Structural Directive",
          "NgIf case study",
          "Inside *ngFor",
          "Inside NgSwitch directives",
          "The <ng-template>",
          "Working with <ng-container>",
          "TemplateRef and ViewContainerRef"
        ]
      },
      {
        key: "PIPES",
        value: [
          "Directives Overview",
          "Types of Directive",
          "Create your own Attribute Directive",
          "Writing Attribute Directive Code",
          "Respond to User-Initiated Events",
          "Pass values into the directive with an @Input data binding",
          "Binding to an @Input property",
          "Bind to an @Input alias",
          "Write a harness to try it",
          "Create your own Structural Directive",
          "NgIf case study",
          "Inside *ngFor",
          "Inside NgSwitch directives",
          "The <ng-template>",
          "Working with <ng-container>",
          "TemplateRef and ViewContainerRefUsing pipes",
          "Built-in pipes",
          "Parameterizing a pipe",
          "Chaining pipes",
          "Custom pipes",
          "The Pipe Transform interface",
          "Power Boost Calculator",
          "Pipes and change detection",
          "Pure and impure pipes",
          "Next steps"
        ]
      },
      {
        key: "SERVICES & DEPENDENCY INJECTION",
        value: [
          "Getting familiar with Services",
          "Building a Service",
          "Getting familiar with Dependency Injection",
          "Working with Injectors",
          "Working with Providers",
          "Registering Providers with real-time examples"
        ]
      },
      {
        key: "LIFECYCLE HOOKS & CHANGE DETECTION",
        value: [
          "Understanding Component LifeCycle",
          "Using ngOnInit",
          "All lifecycle Hooks",
          "Understanding Change Detection",
          "Working with Zone.js"
        ]
      },
      {
        key: "ROUTING & ADVANCED ROUTING",
        value: [
          "The Component Router",
          "Defining Routes",
          "Working with Navigation",
          "Understanding Route Params",
          "Child Routes",
          "Route Guards",
          "Route Resolves"
        ]
      },
      {
        key: "MASTERING TEMPLATE-DRIVEN FORMS & MODEL-DRIVEN FORMS",
        value: [
          "Introduction to forms",
          "Overview of Template-driven forms",
          "Understanding Validations",
          "Introduction to ‘Reactive’ forms",
          "Form Group & Form Control Class",
          "Validators Class"
        ]
      },
      {
        key: "ASYNCHRONOUS OPERATIONS & ADVANCED HTTP",
        value: [
          "Introduction to Async",
          "Promises",
          "Working with Observables",
          "EventEmitter",
          "Async Pipes",
          "Handling HTTP Request / Response",
          "Headers & Request Settings",
          "Providing HTTP"
        ]
      },
      {
        key: "COMPONENT STYLING",
        value: [
          "Introduction to Angular Styling",
          "Using Component Styling",
          "Style Scope",
          "Shadow DOM",
          "Loading Component Styles"
        ]
      },
      {
        key: "WORKING WITH ANIMATIONS",
        value: [
          "Introduction to Animations",
          "Animations Setup",
          "Understanding States & Transitions",
          "Entering and Leaving from States",
          "Animatable Units",
          "Automatic property calculation",
          "Understanding Animation Timing",
          "Multistep Animation using Keyframes"
        ]
      },
      {
        key: "TESTING ANGULAR APPLICATIONS",
        value: [
          "Introduction to Testing",
          "Unit Testing",
          "E2E Testing & Protractor",
          "Setup Jasmine Framework",
          "Component Test Basics",
          "Component Test Scenarios",
          "Karma"
        ]
      },
      {
        key: "SECURITY & INTERNATIONALIZATION",
        value: [
          "Importance of Security",
          "Security in Angular",
          "Sanitize the Dangerous Values",
          "Trusting Values",
          "bypassSecurityTrustHtml",
          "bypassSecurityTrustScript",
          "bypassSecurityTrustStyle",
          "bypassSecurityTrustUrl",
          "bypassSecurityTrustResourceUrl",
          "Cross-site Request Forgery",
          "Pre-compiled and runtime",
          "Using ng2-Translate"
        ]
      },
      {
        key: "PERFORMANCE, OPTIMIZATION & DEPLOYMENT",
        value: [
          "Change Detection Strategy",
          "Running outside the Zone",
          "Production Mode",
          "Understanding Web Workers",
          "Precompiling (AoT)",
          "Lazy Loading",
          "Deployment Best Practices"
        ]
      },

    ]
  },
  {
    id: "NodeJS",
    img: nodeJS,
    title: "NodeJS Training | ALCE",
    route: "nodejs-training-in-madurai",
    description: "Looking for the top training institute for NodeJS Training? Look no further than ALCE. Our comprehensive NodeJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for NodeJS Training? Look no further than ALCE. Our comprehensive NodeJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "NodeJS Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `With the help of a real-time Node JS trainer, you can construct a scalable back-end service with Node JS Scripts by mastering the fundamentals of Node JS at ALCE. By the time this program ends, you will understand Module Dependencies, Packages, and Event-driven Concurrency Strategies. You will also know how to create code with proficiency so that you can interface the servers' operating systems like a pro.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "34",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn NodeJS at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the NodeJS Course at ALCE ?",
        value: `
          The NodeJS at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the NodeJS Course at ALCE ?",
        value: `
          You can enroll for the NodeJS Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the NodeJS Course at ALCE and will I be given enough practical Training for the NodeJS Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The NodeJS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the NodeJS Trainers well-equipped at ALCE ?",
        value: `
          The NodeJS Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the NodeJS Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }




    ],
    curriculum: [
      {
        key: "NodeJS Course Syllabus",
        value: [
          "Introduction",
          "Environment Setup",
          "Node Package Manager",
          "Callbacks",
          "Events and Event loop",
          "Streams and Buffers",
          "Express Framework",
          "Introduction to MongoDB",
          "Connecting NodeJS to DataBase",
          "Mongoose Module",
          "Creating Rest APIS"
        ]
      },

    ]
  },
  {
    id: "FullStack",
    img: fullstack,
    title: "Full Stack Developer Training | ALCE",
    route: "full-stack-developer-training-in-madurai",
    description: "Looking for the top training institute for Full Stack Developer Training? Look no further than ALCE. Our comprehensive Full Stack Developer Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Full Stack Developer Training? Look no further than ALCE. Our comprehensive Full Stack Developer Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "Full Stack Developer Training",
    mprice: "35,000",
    oprice: "2#,999",
    overview: `The Frontend, Backend, and Database—the three essential parts of Full Stack Development—are covered in this course. Modules on HTML, CSS, JavaScript, and Angular or React are covered under frontend. Backend includes PHP, Python, Dot Net, and Node.js. Students can study MySQL, MS-SQL, or MongoDB databases. In addition to the tracks listed above, MEAN Stack and MERN Stack are also options. Whichever Full Stack Development path you select, our knowledgeable trainers will ensure that you master the Full Stack training of your choice!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students' knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "184",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Full Stack Developer at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Full Stack Developer Course at ALCE ?",
        value: `
          The Full Stack Developer at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Full Stack Developer Course at ALCE ?",
        value: `
          You can enroll for the Full Stack Developer Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Full Stack Developer Course at ALCE and will I be given enough practical Training for the Full Stack Developer Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Full Stack Developer Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Full Stack Developer Trainers well-equipped at ALCE ?",
        value: `
          The Full Stack Developer Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Full Stack Developer Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Java Fullstack with Angular<br></br>Core Java Programming",
        value: [
          "Introduction to Java",
          "Data Types",
          "Operators",
          "Variables",
          "Methods",
          "Object",
          "Constructors",
          "Strings",
          "Java Beans",
          "Arrays",
          "Inheritance",
          "Abstraction",
          "Abstraction using abstract classes",
          "Abstraction using interfaces",
          "Encapsulation",
          "Interfaces",
          "Polymorphism",
          "Exception Handling",
          "Collection Framework",
          "Generics",
          "Casting",
          "File Handling",
          "Serialization",
          "Threads",
          "Inner Classes"
        ]
      },
      {
        key: "Java Enterprise Edition (JEE)",
        value: [
          "Overview of J2EE and WWW",
          "The HTTP Protocol and Web Application Introduction",
          "Environment Setup",
          "HTML",
          "Servlets",
          "Java Server Pages(JSP)",
          "MVC Architecture",
          "JDBC"
        ]
      },
      {
        key: "Spring and Spring Boot Framework",
        value: [
          "Spring: Introduction",
          "Spring: Core Container",
          "Components of Spring Core",
          "Spring: Data Access and Integration",
          "Spring: Web Layer",
          "Spring: Aspect Oriented Programming (AOP)",
          "Spring: Security",
          "Spring: Boot"
        ]
      },
      {
        key: "TypeScript Framework",
        value: [
          "Introduction to TypeScript",
          "Advantages of TypeScript",
          "Overview of Node and Node Environment Setup",
          "Installing and Configuring TypeScript Engine",
          "Understanding Transpilation"
        ]
      },
      {
        key: "TypeScript Variables and Data types",
        value: [
          "TypeScript Variables and Data types",
          "Declaring and Defining Variables",
          "About “any”",
          "“var” Vs “let” keywords",
          "Static and Dynamic Type",
          "Data types : String, Number, Array, Object, Tuple, Enum, Void and NULL"
        ]
      },
      {
        key: "OOPS in TypeScript",
        value: [
          "Defining Class and Creating Objects",
          "Property, Methods and Constructors",
          "Handling Inheritance",
          "Types of Inheritance in TypeScript",
          "Understanding Access Modifiers",
          "Static Methods in TypeScript",
          "Understanding Interfaces in TypeScript"
        ]
      },
      {
        key: "Programming with Angular",
        value: [
          "Introducing Angular",
          "Angular – Environment Setup",
          "Angular Modules",
          "Angular Components",
          "Angular – Data and Event Bindings",
          "Angular Directives and Custom Directives",
          "Angular Pipes",
          "Angular Services and Dependency Injection",
          "Angular Routing and Advanced Routing",
          "Angular Template Driven Forms",
          "Angular Reactive Forms",
          "Angular Asynchronous Operations and Advanced HTTP",
          "Angular Authorizations",
          "Angular Animations",
          "Testing Angular Applications",
          "Angular Security and Internalization",
          "Angular Performance, Optimization and Deployment",
          "Real Time Project with Angular"
        ]
      },
      {
        key: "Python Fullstack Developer<br></br>Programming in Python",
        value: [
          "Introduction to Python",
          "List, Ranges & Tuples in Python ",
          "Python Dictionaries and Sets",
          "Input and Output in Python",
          "Python functions",
          "Python Object Oriented",
          "Python Exceptions Handling",
          "Python Regular Expressions",
          "Useful additions"
        ]
      },
      {
        key: "Django Framework for Python",
        value: [
          "Introduction",
          "Models layer",
          "View layer",
          "Template layer",
          "Forms",
          "Internationalization and localization",
          "Common web application tools",
          "Real-Time Projects"
        ]
      },
      {
        key: "TypeScript Framework",
        value: [
          "For detailed modules, refer Java Full Stack with Angular"
        ]
      },
      {
        key: "MEAN Stack Developer<br></br>MongoDB",
        value: [
          "Introduction to MongoDB",
          "Installation and Configuration of MongoDB",
          "Fundamentals of MongoDB",
          "Working with Data - CRUD Operations",
          "Querying Collections",
          "Aggregation Operations",
          "Understanding Data Models and Schema",
          "Handling Transactions in MongoDB",
          "Handling Indexes in MongoDB",
          "Managing Security",
          "Replication",
          "Sharding in MongoDB"
        ]
      },
      {
        key: "Node.js<br></br>Understanding the Web",
        value: [
          "Architecture of a Web Application",
          "Server Side Scripting",
          "Client Side Scripting",
          "Importance of Performance"
        ]
      },
      {
        key: "Introduction to Node.js",
        value: [
          "What is Node.js",
          "Advantages of Node.js",
          "Installing Node.js and Configuring Node.js",
          "Architecture of Node.js",
          "Appreciating the Performance of Node.js",
          "Understanding Event Driven code execution and Asynchronous Events in Node.js",
          "Understanding Event Emitters"
        ]
      },
      {
        key: "Implementing Node.js",
        value: [
          "The Node lifecycle",
          "Creating and running a Node Server",
          "Handling Requests and Responses",
          "Routing and Redirecting Requests",
          "Handling Streams, buffers and Events",
          "Handling Multi-processing in Node.js",
          "Handling Run time Errors"
        ]
      },
      {
        key: "Modules in Node.js",
        value: [
          "Understanding Modules in Node.js",
          "Exploring various built-in Modules",
          "Third party Modules for Node.js",
          "Introduction to Node Package Manager - NPM",
          "Understanding Global and Local NPM Packages",
          "Installing and Updating a Module",
          "Deleting a Module",
          "Introduction to various NPM Modules"
        ]
      },
      {
        key: "ExpressJS Framework<br></br>Introduction to ExpressJS Framework",
        value: [
          "Why the need for Express Framework",
          "Overview of ExpressJS framework",
          "Installing ExpressJS",
          "Understanding Request and Response Objects"
        ]
      },
      {
        key: "Exploring ExpressJS",
        value: [
          "Exploring ExpressJS",
          "Configuring ExpressJS",
          "Understanding Middleware",
          "Understand How Middleware Works in ExpressJS",
          "Add a Middleware",
          "Creating Custom Middleware",
          "Handling Requests and Responses",
          "Handling Routing",
          "Using ExpressJS Router",
          "Path Filtering",
          "Creating and Serving HTML Pages",
          "Using Jade Templates",
          "Navigating Datasets",
          "Using REST in ExpressJS",
          "Handling POST",
          "Handling JSON data",
          "Handling Errors"
        ]
      },
      {
        key: "TypeScript Framework",
        value: [
          "For detailed modules, refer Java Full Stack with Angular"
        ]
      },
      {
        key: "Programming with Angular",
        value: [
          "For detailed modules, refer Java Full Stack with Angular"
        ]
      },
      {
        key: "Fullstack Dot Net",
        value: [
          "INTRODUCTION TO .NET FRAMEWORK 4.5",
          "VISUAL C#.NET",
          "ASP .NEt",
          "DO .NET CONCEPTS",
          "ASP.NET MVC (Model, View and Controller)",
          "MICROSOFT SQL SERVER"
        ]
      },
      {
        key: "TypeScript Framework",
        value: [
          "For detailed modules, refer Java Full Stack with Angular"
        ]
      },
      {
        key: "Programming with Angular",
        value: [
          "For detailed modules, refer Java Full Stack with Angular"
        ]
      },
      {
        key: "PHP Fullstack Developer<br></br>Web Designing",
        value: [
          "HTML",
          "Introducing HTML 5",
          "Introducing CSS and CSS 3",
          "Simplifying CSS with BootStrap",
          "Javascript",
          "Jquery Framework"
        ]
      },
      {
        key: "Creating, Managing and Mastering MySQL",
        value: [
          "MySQL Database"
        ]
      },
      {
        key: "Developing Web Applications using PHP",
        value: [
          "Introduction to PHP",
          "Basics of PHP",
          "Control Structures of PHP",
          "Functions in PHP",
          "User Defined Functions in PHP",
          "File Handling in PHP",
          "Handling Forms in PHP",
          "Accessing MySQL with PHP",
          "Sessions and Cookies",
          "Using Emails for Form Submission in PHP"
        ]
      },
      {
        key: "Laravel Framework with PHP",
        value: [
          "Laravel for PHP",
          "Installation and Architecture",
          "Laravel Component - Routes",
          "Laravel Component - Controllers"
        ]
      },

    ]
  },
  {
    id: "MERNStack",
    img: mern,
    title: "MERN Stack Training | ALCE",
    route: "mern-stack-developer-training-in-madurai",
    description: "Looking for the top training institute for MERN Stack Training? Look no further than ALCE. Our comprehensive MERN Stack Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for MERN Stack Training? Look no further than ALCE. Our comprehensive MERN Stack Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "MERN Stack Training",
    mprice: "25,000",
    oprice: "1#,999",
    overview: `The MERN Stack Course is a professional certification program created to assist web development professionals and students in mastering the four essential technologies of this framework: Node.js, ReactJS, Express, and MongoDB. For candidates to effectively manage the framework and create captivating, user-friendly, and stunning functional websites that meet all of the needs of the organizations, they must become proficient in all four technologies. Students may be guaranteed a unique learning experience and a comprehensive understanding of the JavaScript MERN Stack Web Development technology since the training program is delivered by MERN Stack experts that have excellent working knowledge of the technology.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "191",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn MERN Stack at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the MERN Stack Course at ALCE ?",
        value: `
          The MERN Stack at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the MERN Stack Course at ALCE ?",
        value: `
          You can enroll for the MERN Stack Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the MERN Stack Course at ALCE and will I be given enough practical Training for the MERN Stack Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The MERN Stack Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the MERN Stack Trainers well-equipped at ALCE ?",
        value: `
          The MERN Stack Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the MERN Stack Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "MongoDB - Introduction",
        value: [
          "What is NoSQL",
          "Types of NoSQL DBs",
          "Necessity for NoSQL DBs",
          "Relational Databases Vs NoSQL Databases",
          "Advantages of NoSQL Databases",
          "Introducing MongoDB"
        ]
      },
      {
        key: "Installation and Configuration of MongoDB",
        value: [
          "Downloading and Installing MongoDB",
          "Command Line Mode - Shell",
          "GUI mode with MongoDB Compass",
          "Starting and Stopping MongoDB",
          "Getting Help"
        ]
      },
      {
        key: "Fundamentals of MongoDB",
        value: [
          "Understanding MongoDB model",
          "SQL Terms Vs MongoDB Terms",
          "Understanding Database",
          "Understanding Collections",
          "Collections Vs Tables",
          "Understanding Schemas and Documents",
          "Creating and Dropping a Database"
        ]
      },
      {
        key: "Working with Data - CRUD Operations",
        value: [
          "Creating Collections",
          "Inserting Single Document and Multiple Documents",
          "Inserting with _id Field",
          "Inserting Document with Arrays",
          "Understanding Embedded and Nested Documents",
          "Updating Single and Multiple Documents",
          "Using Update Operators – Field Update Operators and Array Update Operators",
          "Deleting Single and Multiple Documents",
          "Understanding and Implementing Bulk Write Operations",
          "Working with Retryable Writes"
        ]
      },
      {
        key: "Querying Collections",
        value: [
          "Using Query Operator - find() to query Documents",
          "Querying Fields and Nested Fields",
          "Using Logical Operations in Query",
          "Querying Nested and Embedded Documents",
          "How to Query an Array?",
          "Projecting Fields in a Query",
          "Using _id Field in Query",
          "Querying Null and Missing Fields",
          "Querying Text in MongoDB",
          "Indexing Text for Search",
          "Understanding Text Search Operators",
          "What is Geospatial Search",
          "Understanding and using Geospatial data",
          "Geospatial Indexes and Queries"
        ]
      },
      {
        key: "Aggregation Operations",
        value: [
          "Understanding Aggregation Operations",
          "Using Aggregation Pipelines",
          "Pipeline Expressions and Behaviors",
          "Understanding Map-Reduce Functions in MongoDB",
          "Understanding Map and Reduce",
          "Using Map-Reduce",
          "Using Map-Reduce Javascript Functions",
          "Map-Reduce results",
          "Understanding Incremental Map-Reduce",
          "Understanding and using Map-Reduce Concurrency",
          "Exploring Aggregation References"
        ]
      },
      {
        key: "Understanding Data Models and Schema",
        value: [
          "Introduction to Data Modelling",
          "Understanding Schema in MongoDB",
          "Flexible Schema and References",
          "Understanding Schema Validation",
          "Create Validation Rules",
          "Using Query Expression to Validate Data",
          "Bypassing the Document Validation",
          "Deep dive into Data Modeling Concepts"
        ]
      },
      {
        key: "Handling Transactions in MongoDB",
        value: [
          "Understanding Transactions and Transactions API",
          "Understanding Atomicity",
          "Executing Multi-document Transactions or Distributed Transactions",
          "Transactions Operations and Restricted Operations",
          "Production Considerations"
        ]
      },
      {
        key: "Handling Indexes in MongoDB",
        value: [
          "What is Indexing and Advantages of Indexes",
          "Single Field and Compound Indexes",
          "Multi key Indexes, Text Indexes, Wild-card Indexes",
          "Hashed Indexes",
          "Managing Indexes"
        ]
      },
      {
        key: "Managing Security",
        value: [
          "Implementing Security in MongoDB",
          "Understanding and Enabling Access Control",
          "Handling Authentications",
          "Understanding and Configuring Encryptions",
          "Configuring and Managing Auditing",
        ]
      },
      {
        key: "Replication",
        value: [
          "Importance of Replication for High Data Availability",
          "Implementing Replication in MongoDB with Replica Sets",
          "Understanding Asynchronous Replication",
          "Change Stream",
        ]
      },
      {
        key: "Sharding in MongoDB",
        value: [
          "Understanding the purpose of uses of Sharding",
          "Understanding Sharded Cluster Components",
          "Harshed Sharding and Ranged Sharding",
          "Handling Collation in Sharding",
          "Understanding Zones",
          "Introduction to Sharded Cluster Administration"
        ]
      },
      {
        key: "Node.js - Understanding the Web",
        value: [
          "Architecture of a Web Application",
          "Server Side Scripting",
          "Client Side Scripting",
          "Importance of Performance"
        ]
      },
      {
        key: "Introduction to Node.js",
        value: [
          "What is Node.js",
          "Advantages of Node.js",
          "Installing Node.js and Configuring Node.js",
          "Architecture of Node.js",
          "Appreciating the Performance of Node.js",
          "Understanding Event Driven code execution and Asynchronous Events in Node.js",
          "Understanding Event Emitters"
        ]
      },
      {
        key: "Implementing Node.js",
        value: [
          "The Node lifecycle",
          "Creating and running a Node Server",
          "Handling Requests and Responses",
          "Routing and Redirecting Requests",
          "Handling Streams, buffers and Events",
          "Handling Multi-processing in Node.js",
          "Handling Run time Errors"
        ]
      },
      {
        key: "Modules in Node.js",
        value: [
          "Understanding Modules in Node.js",
          "Exploring various built-in Modules",
          "Third party Modules for Node.js",
          "Introduction to Node Package Manager - NPM",
          "Understanding Global and Local NPM Packages",
          "Installing and Updating a Module",
          "Deleting a Module",
          "Introduction to various NPM Modules"
        ]
      },
      {
        key: "ExpressJS Framework - Introduction",
        value: [
          "Why the need for Express Framework",
          "Overview of ExpressJS framework",
          "Installing ExpressJS",
          "Understanding Request and Response Objects"
        ]
      },
      {
        key: "Exploring ExpressJS",
        value: [
          "Configuring ExpressJS",
          "Understanding Middleware",
          "Understand How Middleware Works in ExpressJS",
          "Add a Middleware",
          "Creating Custom Middleware"
        ]
      },
      {
        key: "Handling Requests and Responses",
        value: [
          "Handling Routing",
          "Using ExpressJS Router",
          "Path Filtering",
          "Creating and Serving HTML Pages",
          "Using Jade Templates",
          "Navigating Datasets",
          "Using REST in ExpressJS",
          "Handling POST",
          "Handling JSON data",
          "Handling Errors"
        ]
      },
      {
        key: "Programming with ReactJS - Introduction",
        value: [
          "Introduction to Single Page Applications - SPA",
          "Introduction to ReactJS",
          "Uses of ReactJS",
          "Understanding the Component based Architecture of ReactJS"
        ]
      },
      {
        key: "ReactJS – Setting up the Environment",
        value: [
          "Setting up NodeJS",
          "Understanding NPM",
          "Understanding Webpack",
          "Selecting the right IDE",
          "Working with various ReactJS browser Plugins"
        ]
      },
      {
        key: "ReactJS – Understanding JSX",
        value: [
          "Introduction to JSX",
          "Understanding JSX Format",
          "Creating React Components",
          "Understanding various types of React Components",
          "Compiling JSX code to Javascript",
          "Understanding ReactJS Rendering",
          "Understanding ReactDOM.render",
          "Understanding Props",
          "Understanding ReactJS Datatypes",
          "Understanding ReactJS State"
        ]
      },
      {
        key: "ReactJS – Creating UI, Forms and Handling Events",
        value: [
          "Understanding UI in ReactJS",
          "Understanding various Form Components",
          "Getting Inputs from the User",
          "Setting up Default Values",
          "Managing Form Validations",
          "Understanding ReactJS UI Components",
          "Writing Styles in ReactJS",
          "Styling Components using CSS",
          "Understanding In-line Styles",
          "Working with External Styles",
          "Understanding various UI Component Libraries",
          "Understanding Events in ReactJS",
          "Working with Various Events – Mouse Events, Keyboard Events and OnChange Events"
        ]
      },
      {
        key: "ReactJS – Understanding the Component Life Cycle",
        value: [
          "Understanding the Component Life Cycle",
          "Understanding the Initial Render",
          "Understanding the Props Change and State Change",
          "Understanding various Life Cycle Methods",
          "Component willMount, didMount and willUnmount",
          "Component willUpdate and DidUpdate",
          "Understanding the effective usage of various Life Cycle Methods"
        ]
      },
      {
        key: "ReactJS – Understanding Redux and Flux",
        value: [
          "Understanding What is Redux and Flux in ReactJS",
          "Uses and Advantages of Redux and flux",
          "Understanding the Flux Architecture",
          "Working with various Flux Components",
          "Stores and Dispatchers",
          "Actions, Views and Controllers",
          "Understanding the Flux and ReactJS link",
          "More on Redux",
          "Handling State Management with Redux",
          "Understanding Redux Store",
          "Understanding View Controllers",
          "Working with Reducers and Dispatchers",
          "Working with Selectors"
        ]
      },
      {
        key: "ReactJS – Routing",
        value: [
          "Understanding the ReactJS Router",
          "Configuring the Router",
          "Loading the Router Library",
          "Managing Parameters in Routing",
          "Working with Nested Routing",
          "Integrating Redux with ReactJS Router"
        ]
      },
      {
        key: "ReactJS – Handling External Data",
        value: [
          "Working with Fetch",
          "Working with Axios",
        ]
      },
    ]
  },
  {
    id: "MEANStack",
    img: mean,
    title: "MEAN Stack Training | ALCE",
    route: "mean-stack-developer-training-in-madurai",
    description: "Looking for the top training institute for MEAN Stack Training? Look no further than ALCE. Our comprehensive MEAN Stack Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for MEAN Stack Training? Look no further than ALCE. Our comprehensive MEAN Stack Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "MEAN Stack Training",
    mprice: "25,000",
    oprice: "1#,999",
    overview: `Are you trying to expand your job options in the Web Development area and seeking for a professional platform to learn MEAN Stack Course? With ALCE, your quest is over! With ALCE's MEAN Stack Training, you may learn the MEAN Stack Technologies in-depth and develop a solid web application while being guided by certified specialists in the real world.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "51",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn MEAN Stack at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the MEAN Stack Course at ALCE ?",
        value: `
          The MEAN Stack at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the MEAN Stack Course at ALCE ?",
        value: `
          You can enroll for the MEAN Stack Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the MEAN Stack Course at ALCE and will I be given enough practical Training for the MEAN Stack Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The MEAN Stack Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the MEAN Stack Trainers well-equipped at ALCE ?",
        value: `
          The MEAN Stack Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the MEAN Stack Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "MongoDB",
        value: [
          "Concepts",
          "Scaling",
          "SQL and Complex Transactions",
          "Documents Overview",
          "Installing MongoDB (windows)",
          "Installing MongoDB (Linux)",
          "JSON",
          "Dynamic Schema",
          "Cursors Introduction",
          "Query Language: Basic Concepts",
          "Query Language: Projection",
          "Query Language: Advantages of a Dynamic Schema",
          "Shell: Queries",
          "Sorting",
          "Query Language: Cursors",
          "User Authentication",
          "Error Handling"
        ]
      },
      {
        key: "Express .Js",
        value: [
          "MVC Pattern",
          "Introduction to Express",
          "Routing",
          "HTTP Interaction",
          "Handling Form Data",
          "Handling Query Parameters",
          "Cookies and Sessions",
          "User Authentication",
          "Error Handling",
          "Creating and Consuming RESTful Services",
          "Using Templates"
        ]
      },
      {
        key: "Angular .Js",
        value: [
          "Angular Architecture",
          "Dynamic Binding",
          "Modules, Controllers, and Scope",
          "Views",
          "Custom Directives",
          "Event Directives",
          "Expressions",
          "Built-in and Custom Filters",
          "Understanding the Digest Loop",
          "Form Validations",
          "AngularJS Service Types",
          "Factories",
          "Creating Custom Services",
          "Routing, Redirects, and Promises"
        ]
      },
      {
        key: "Node .Js",
        value: [
          "Getting started with Node.js",
          "Node Package Manager",
          "Modules",
          "Asynchronous Programming",
          "Callbacks",
          "Events and Event Loop",
          "Streams and Buffers",
          "Connecting Node.js to Database",
          "Web Sockets"
        ]
      }

    ]
  },
  {
    id: "NextJS",
    img: nextjs,
    title: "NextJS Training | ALCE",
    route: "nextjs-training-in-madurai",
    description: "Looking for the top training institute for NextJS Training? Look no further than ALCE. Our comprehensive NextJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for NextJS Training? Look no further than ALCE. Our comprehensive NextJS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "NextJS Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `Learn web programming from the ground up with our state-of-the-art course. Learn NextJS, a potent React framework, through practical projects supervised by knowledgeable teachers. Create engaging and inventive websites that push the limits of design and user experience. With the knowledge and skills from our extensive program, you can develop applications that are dynamic and high-performing. Become certified and become a part of our placement cell to begin your exciting career in the dynamic field of web development. Enroll in ALCE's NextJS Training program today to start down the path to an innovative future!`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "191",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn NextJS at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the NextJS Course at ALCE ?",
        value: `
          The NextJS at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the NextJS Course at ALCE ?",
        value: `
          You can enroll for the NextJS Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the NextJS Course at ALCE and will I be given enough practical Training for the NextJS Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The NextJS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the NextJS Trainers well-equipped at ALCE ?",
        value: `
          The NextJS Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the NextJS Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Introduction to Next.js",
        value: [
          "What is Next.js?",
          "Benefits of using Next.js",
          "Setting up a Next.js development environment",
          "Creating a Next.js project",
          "Navigating a Next.js project"
        ]
      },
      {
        key: "Building a Next.js App",
        value: [
          "Creating pages in Next.js",
          "Server-side rendering with Next.js",
          "Static site generation with Next.js",
          "Dynamic routing in Next.js",
          "API routes in Next.js"
        ]
      },
      {
        key: "Styling in Next.js",
        value: [
          "CSS modules in Next.js",
          "CSS-in-JS with styled-components",
          "Global styles in Next.js",
          "Using third-party CSS frameworks in Next.js"
        ]
      },
      {
        key: "Managing Data in Next.js",
        value: [
          "Fetching data in Next.js",
          "Server-side data fetching with Next.js",
          "Static data fetching with Next.js",
          "Client-side data fetching with Next.js",
          "Using a CMS with Next.js"
        ]
      },
      {
        key: "Working with Forms in Next.js",
        value: [
          "Creating forms in Next.js",
          "Handling form submissions in Next.js",
          "Form validation in Next.js",
          "Using third-party form libraries in Next.js"
        ]
      },
      {
        key: "Authentication in Next.js",
        value: [
          "Creating a login page in Next.js",
          "IAuthenticating users in Next.js",
          "Persisting authentication state in Next.js",
          "Using third-party authentication providers with Next.js"
        ]
      },
      {
        key: "Testing in Next.js",
        value: [
          "Unit testing in Next.js",
          "Integration testing in Next.js",
          "End-to-end testing in Next.js",
          "Using testing frameworks with Next.js"
        ]
      },
      {
        key: "Deploying a Next.js App",
        value: [
          "Deployment options for Next.js",
          "Deploying to Vercel",
          "Deploying to other platforms",
          "Optimizing a Next.js app for production"
        ]
      },
      {
        key: "E-commerce App",
        value: [
          "Creating an e-commerce app with Next.js",
          "Implementing a shopping cart in Next.js",
          "Building product pages in Next.js",
          "Integrating with payment gateways in Next.js"
        ]
      },
      {
        key: "Building a Next.js Blog",
        value: [
          "Creating a blog with Next.js",
          "mplementing a markdown-based CMS in Next.js",
          "Building dynamic blog pages in Next.js",
          "Adding comments functionality to a Next.js blog"
        ]
      },
      {
        key: "Building a Next.js Portfolio",
        value: [
          "Creating a portfolio website with Next.js",
          "Building project pages in Next.js",
          "Integrating with third-party APIs in Next.js",
          "Using animations and effects in Next.js"
        ]
      },
      {
        key: "Advanced Topics in Next.js",
        value: [
          "Building serverless functions in Next.js",
          "Optimizing performance in Next.js",
          "Using advanced features of Next.js",
          "Best practices for developing with Next.js"
        ]
      },
    ]
  },
  {
    id: "VueJS",
    img: vue,
    title: "Vue JS Training | ALCE",
    route: "vuejs-training-in-madurai",
    description: "Looking for the top training institute for Vue JS Training? Look no further than ALCE. Our comprehensive Vue JS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Vue JS Training? Look no further than ALCE. Our comprehensive Vue JS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Web Development",
    title: "Vue JS Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `ALCE comprehensive Vue JS course is designed for both aspiring developers and working professionals. Whether you’re launching your career in web development or looking to upskill with a powerful framework, this course will equip you with the expertise you need. Our instructors are industry veterans who will guide you through core Vue.js concepts, including templates, view models, event handling, and two-way binding. You’ll also delve into components, slots, dynamic components, custom directives, mixins, filters, and more. This course goes beyond theory. You’ll gain practical experience by working on real-world capstone projects, solidifying your understanding of Vue.js and preparing you for the challenges you’ll face in the field. Upon completion of Vue JS Training, you’ll receive a recognized certification that validates your skills and sets you apart from other applicants. Our dedicated placement cell will provide support in your job search, helping you land your dream role as a Vue.js developer.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "191",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Vue JS at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Vue JS Course at ALCE ?",
        value: `
          The Vue JS at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Vue JS Course at ALCE ?",
        value: `
          You can enroll for the Vue JS Course at ALCE by contacting our support number +91 6369 964 058.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Vue JS Course at ALCE and will I be given enough practical Training for the Vue JS Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Vue JS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Vue JS Trainers well-equipped at ALCE ?",
        value: `
          The Vue JS Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Vue JS Programming language.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Syllabus",
        value: [
          "Getting Started to Vue JS",
          "Using Vue JS to Interact with the DOM",
          "Using Conditionals and Rendering Lists",
          "First Course Project — The Monster Slayer",
          "Understanding the Vue JS Instance",
          "Moving to a “Real” Development Workflow with Webpack and Vue CLI",
          "An Introduction to Components",
          "Communicating between Components",
          "Advanced Component Usage",
          "Second Course Project — Wonderful Quotes",
          "Handling User Input with Forms",
          "Using and Creating Directives",
          "Improving your App with Filters and Mixins",
          "Adding Animations and Transitions",
          "Connecting to Servers via Http — Using vue-resource",
          "Routing in a Vue JS Application",
          "Better State Management with Vuex",
          "Final Project — The Stock Trader",
          "Deploying a Vue JS Application"
        ]
      }
    ]
  },
  {
    id: "Android",
    img: android,
    title: "Android Training | ALCE",
    route: "android-training-in-madurai",
    description: "Looking for the top training institute for Android Training? Look no further than ALCE. Our comprehensive Android Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Android Training? Look no further than ALCE. Our comprehensive Android Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Mobile App Development",
    title: "Android Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The Android Training at ALCE is a hands-on, comprehensive program that covers fundamental subjects including Android Architecture, Components, Life Cycle, and Resources while providing expert mentoring and real-world practice. You will learn about important subjects like Layout Manager, Menus, Intents, Various Styles, Notifications, SQLite Database, Broadcasts, and how to upload your apps to the Google Play Store like a pro in ALCE's Android Training curriculum.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
  <ul>
      <li>
          <b>Practical & Theoretical Approach</b>
          We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
      </li><br></br>
      <li>
          <b>Job-Specific Skill Sets</b>
          Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
      </li><br></br>
      <li>
          <b>Professional Expertise</b>
          The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
      </li><br></br>
      <li>
          <b>Real-Time Development Experience</b>
          Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
      </li><br></br>
      <li>
          <b>Industry-Current Practices</b>
          Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
      </li><br></br>
      <li>
          <b>One-to-One Mentorship</b>
          Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
      </li><br></br>
      <li>
          <b>Professional Resume Building</b>
          Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
      </li><br></br>
  <ul>
  At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "121",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Android at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Android Course at ALCE ?",
        value: `
        The Android at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
        <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
      `
      },
      {
        key: "How Can I enroll for the Android Course at ALCE ?",
        value: `
        You can enroll for the Android Course at ALCE by contacting our support number +91 6369 964 058.
        <br></br>You can also join by walking directly to any of our branches in Madurai. 
      `
      },

      {
        key: "What will be the batch size of the Android Course at ALCE and will I be given enough practical Training for the Android Certification Course ?",
        value: `
        We provide maximum individual one-to-one attention to all the students. The Android Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
        <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
      `
      },
      {
        key: "Are the Android Trainers well-equipped at ALCE ?",
        value: `
        The Android Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Android Programming language.  
      `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
        The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
      `
      }
    ],
    curriculum: [
      {
        key: "INTRODUCTION TO ANDROID",
        value: [
          "Introduction to Mobile App Development",
          "Mobile App Development Platforms",
          "Native App Development",
          "Cross-Platform App Development",
          "Android Vs iOS",
          "What is Android?",
          "Android Versioning",
          "Android Development Platforms",
          "Android Architecture"
        ]
      },
      {
        key: "INSTALLATION AND ANDROID FUNDAMENTALS",
        value: [
          "Installation of Android Studio",
          "Installation of Eclipse and ADT Plugin",
          "Setting up an Android Project",
          "Android Project Structure"
        ]
      },
      {
        key: "CORE JAVA FOR ANDROID PROGRAMMING",
        value: [
          "Java Programming Fundamentals",
          "Control Structures",
          "Loops",
          "OOPS CONCEPTS – Data Abstraction, Encapsulation, Inheritance, Polymorphism",
          "Exception Handling",
          "Packages & Interfaces",
          "Collection",
          "ArrayList",
          "Vector Class",
          "HashMap"
        ]
      },
      {
        key: "ANDROID COMPONENTS & BUILDING BLOCKS",
        value: [
          "Activities/Fragment",
          "Services",
          "Intent",
          "Intent Filters",
          "Storage",
          "Broadcast receivers"
        ]
      },
      {
        key: "MASTERING UI DESIGN",
        value: [
          "Widget Control",
          "Working with Single Screen Design",
          "Working with Multiple Screen Design",
          "Handling Pages with Scrollable Design",
          "Reusing Existing forms",
          "Material Design",
          "Button and Checkbox",
          "Progress Bar and Seek Bar",
          "Text View and Edit Text",
          "Views(Grid View, ListView, Image View)"
        ]
      },
      {
        key: "WORKING WITH LAYOUTS TECHNIQUES",
        value: [
          "Linear Layout",
          "Relative Layout",
          "Table Layout",
          "Frame Layout",
          "What is the layout of Android?",
          "Design Layout Using XML for UI layout"

        ]
      },
      {
        key: "ACTIVITY LIFE CYCLE",
        value: [
          "Understanding the Life cycle methods",
          "A working example of a life cycle"

        ]
      },
      {
        key: "UNDERSTANDING AN ANDROID APPLICATION STRUCTURE",
        value: [
          "Understanding the XML layout files structure",
          "Values XML files",
          "TextView and EditText",
          "Dialogs",
          "Notifications",
          "Understanding the LDPI, MDPI, and HDPI",
          "Understanding Mipmap folders",
          "Mipmap V/s drawable folders"
        ]
      },
      {
        key: "APPLICATION DEVELOPMENT & EMULATOR",
        value: [
          "Creating an Android application",
          "Launching Emulator",
          "Working with Various settings in Emulator",
          "Logcat Operations",
          "Debugging an Application",
          "Understanding DDMS",
          "DDMS and log results"
        ]
      },
      {
        key: "UNDERSTANDING INTENTS",
        value: [
          "Implicit intents",
          "Launching native application using Intent",
          "Explicit Intent",
          "Switch between activities using Explicit Intent",
          "Data transferring between activities using Intents",
          "Role of intent Filters",
          "Intent Filters in your manifest"
        ]
      },
      {
        key: "SERVICES",
        value: [
          "Lifecycle",
          "Bound Services",
          "Notifications",
          "Running in Foreground"
        ]
      },
      {
        key: "UNDERSTANDING SQL & SQLITE",
        value: [
          "DML Queries",
          "DDL Queries",
          "SQL Syntax",
          "SQL functions",
          "Date & time functions",
          "Aggregate functions",
          "JSON functions"
        ]
      },
      {
        key: "DATA STORAGE & PERMISSIONS",
        value: [
          "Content providers and preferences",
          "Storing in Flat files",
          "SQLite – Database Usage",
          "Data storage using the preferences",
          "Database Operations",
          "Realm Database Connectivity",
          "SQLCipher",
          "Permission Systems"
        ]
      },
      {
        key: "WEB SERVICES",
        value: [
          "SOAP",
          "REST",
          "JSON",
          "Volley Library",
          "Retrofit Library"
        ]
      },
      {
        key: "BROADCAST RECEIVERS",
        value: [
          "Broadcast Receivers",
          "Lifecycle",
          "Permissions"
        ]
      },
      {
        key: "WORKING WITH MEDIA",
        value: [
          "Building an Audio App",
          "Building a Video App"
        ]
      },
      {
        key: "CONNECTIVITY",
        value: [
          "Handling Wifi Connectivity",
          "Handling Bluetooth Connectivity",
          "Working with Sensors",
          "Send SMS",
          "Send Email",
          "Notification",
          "Camera"
        ]
      },
      {
        key: "ADVANCED ANDROID CONCEPTS",
        value: [
          "Android Material Design",
          "Sliding Navigation Menu Drawer",
          "View Page Indicator",
          "Card View",
          "Recycler View",
          "Toolbar",
          "Floating Action Button"
        ]
      },
      {
        key: "THIRD-PARTY INTEGRATIONS",
        value: [
          "Google Cloud Messaging (GCM)",
          "Firebase Cloud Messaging (FCM)",
          "Android PHP/MySQL Connection.",
          "Social Media Integration (Facebook, Google Plus, Twitter & LinkedIn)",
          "Third-Party Library Integration",
          "Android PHP/MySQL Connection."

        ]
      },
      {
        key: "PUBLISHING YOUR APP",
        value: [
          "Testing your Android App",
          "Prerequisites for Launching your Android App",
          "Publishing your App"
        ]
      },

    ]
  },
  {
    id: "Flutter",
    img: flutter,
    title: "Flutter Training | ALCE",
    route: "flutter-training-in-madurai",
    description: "Looking for the top training institute for Flutter Training? Look no further than ALCE. Our comprehensive Flutter Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Flutter Training? Look no further than ALCE. Our comprehensive Flutter Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Mobile App Development",
    title: "Flutter Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `Students are introduced to this contemporary react-style framework as part of ALCE's Google Flutter Training, which actively involves them in learning how to construct extremely expressive native applications. In order to prepare them for industry standards, students will also learn in-depth subjects including DART development tools, command-line tools, interop, and plugin APIs of the Flutter framework.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
  <ul>
      <li>
          <b>Practical & Theoretical Approach</b>
          We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
      </li><br></br>
      <li>
          <b>Job-Specific Skill Sets</b>
          Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
      </li><br></br>
      <li>
          <b>Professional Expertise</b>
          The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
      </li><br></br>
      <li>
          <b>Real-Time Development Experience</b>
          Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
      </li><br></br>
      <li>
          <b>Industry-Current Practices</b>
          Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
      </li><br></br>
      <li>
          <b>One-to-One Mentorship</b>
          Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
      </li><br></br>
      <li>
          <b>Professional Resume Building</b>
          Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
      </li><br></br>
  <ul>
  At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "190",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Flutter at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Flutter Course at ALCE ?",
        value: `
        The Flutter at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
        <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
      `
      },
      {
        key: "How Can I enroll for the Flutter Course at ALCE ?",
        value: `
        You can enroll for the Flutter Course at ALCE by contacting our support number +91 6369 964 058.
        <br></br>You can also join by walking directly to any of our branches in Madurai. 
      `
      },

      {
        key: "What will be the batch size of the Flutter Course at ALCE and will I be given enough practical Training for the Flutter Certification Course ?",
        value: `
        We provide maximum individual one-to-one attention to all the students. The Flutter Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
        <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
      `
      },
      {
        key: "Are the Flutter Trainers well-equipped at ALCE ?",
        value: `
        The Flutter Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Flutter Programming language.  
      `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
        The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
      `
      }
    ],
    curriculum: [
      {
        key: "Introduction to Cross Platform App Development",
        value: [
          "Overview of App Development",
          "Review of various App Platforms – Android, iOS, Windows, Web - The Cost Challenge in developing apps for Multiple Platforms • Introduction to Cross Platform App Development",
          "Advantages of Cross Platform App Development",
          "Cost",
          "One Codebase",
          "Quick release",
          "Introduction to popular Cross Platform app Development Tools Flutter",
          "Flutter",
          "React Native, Ionic, Xamarin",
          "Introducing Flutter",
          "Advantages and Features of Flutter",
          "Understanding Flutter's Platform Adaptation"
        ]
      },
      {
        key: "Setting the Development Environment",
        value: [
          "Downloading and Installing Android Studio",
          "Downloading and Installing Flutter SDK",
          "Setting up Emulators",
          "Quick Walkthrough with Android Studio IDE",
          "Using Other editors for Flutter",
          "Setting up Configurations",
          "Sample Program and Execution"
        ]
      },
      {
        key: "Dart – The Programming Language",
        value: [
          "Learning about Dart",
          "The Dart and Flutter Connection",
          "Advantages and Features of Dart Language",
          "Dart Compared with Other OOPS Languages",
          "OOPS in Dart",
          "Understanding the Dart Programming style and elements",
          "Introduction to Dart Packages",
          "Creating a simple Dart Program",
          "Executing a Dart Program"
        ]
      },
      {
        key: "Dart - Essentials",
        value: [
          "Variables, Data Types and Operators",
          "Handling Strings and Numbers",
          "Performing Arithmetic, Relational and Logical Operations",
          "Working with Collections",
          "Introduction to Dart Core Libraries",
          "Using Dart Libraries – dart:core, dart:math, dart:convert, dart:html, dart:io",
          "Handling Iterations and Decision Making",
          "Understanding Extension Methods",
          "Understanding Type Safe and Null Safety",
          "Learning the debugging and error handling features",
          "Introduction to Dart Packages"
        ]
      },
      {
        key: "Flutter – Getting Started",
        value: [
          "Understanding the Flutter Architecture",
          "Learning the steps to build a Flutter App",
          "Understanding Templates and Scaffolding",
          "Building Blocks of Flutter – Widgets, Assets, Images, Box Constraints",
          "Introduction to Flutter Layouts",
          "Learning the Material Design",
          "Creating and running a simple App using Flutter"
        ]
      },
      {
        key: "Flutter UI and Layouts",
        value: [
          "Understanding Widgets",
          "Text Widgets",
          "Row and Column Widget",
          "Stack and Container Widget",
          "Material App Widget",
          "Understanding the key role of Widgets – everything is widgets in Flutter",
          "Learning Layouts in Flutter",
          "Introduction to the Widgets Library and Material Library",
          "Exploring the Standard and Material Widgets",
          "Understanding the Layout Widget",
          "Learning to Add Layout Widget",
          "Understanding Visible Widget",
          "Understanding Parent, Child and Children Properties",
          "Understanding the Row and Column Patterns",
          "Learning to Align Widgets",
          "Learning to Nest Widgets in a Layout",
          "More on Containers",
          "Exploring Grid Views",
          "Understanding List Views",
          "Working with Tab Layouts",
          "Working with Cards and Stack",
          "Working with Constraints",
          "Building Responsive UI in Flutter",
          "Introduction to Responsive Layout",
          "Introduction to Adaptive Layout",
          "Comparing Responsive and Adaptive Layout",
          "Understanding the LayoutBuilder class",
          "Understanding the MediaQuery method",
          "Learning deep about Constraints"

        ]
      },
      {
        key: "Flutter – Developing App Interactivity",
        value: [
          "Adding and handling Input Widgets like Text, Buttons, Checkbox, Radio buttons, Sliders, Form",
          "Creating and managing Forms",
          "Implementing Form Validations",
          "Understanding Widgets States",
          "Understanding Stateful Widgets",
          "Understanding Stateless Widgets",
          "Exploring Class StatefulWidget and State",
          "Handling Active and Inactive Widgets",
          "Managing State",
          "Handling Gestures",
          "Parent Widget and Child Widget in Managing Widget State",
          "Handling AlertDialog",
          "Working with Assets and Images",
          "Understanding Assets and Types of Assets",
          "Loading Images",
          "Managing Assets",
          "Managing Platform Specific Assets",
          "Handling the Launch Screen",
          "Working with Charts and Tables"
        ]
      },
      {
        key: "Flutter – Handling Navigation and Routing",
        value: [
          "Understanding Configuration files",
          "Understanding Navigation in Flutter",
          "Using the Imperative Method for Navigation",
          "Using the Declarative Method for Navigation",
          "Exploring Navigation and Routing",
          "Understanding the MaterialPageRoute",
          "Understanding the Navigator.Push()",
          "Understanding the Navigator.Pop()",
          "Understanding and working with Named Routes",
          "Understanding and working with Deep Links",
          "Implementing Deep Linking on Android, iOS, Web",
          "Learning URL based Navigation for Web Apps",
          "Configuring URL Paths for Web Apps"

        ]
      },
      {
        key: "Flutter – Managing Data and Backend",
        value: [
          "Understanding how data handled in Flutter",
          "Learning App State",
          "Understanding the Declarative approach to manage App State ▪ Advantages of the Declarative approach to manage App State ▪ Other methods for managing App State",
          "Understanding the Simple method for Managing App State ▪ Introduction to the ‘provider’ package",
          "Building a simple state based App",
          "Understanding and Managing HTTP requests",
          "Introduction to the HTTP package",
          "Exploring web server requests in Flutter",
          "Understanding JSON",
          "Understanding and working with JSON Serialization",
          "Learning to use dart:convert",
          "Learning Inline Serializing",
          "Introduction to Firebase",
          "Firebase as a Backend Services",
          "Exploring various features of Firebase",
          "Understanding the Firebase Database"
        ]
      },
      {
        key: "Flutter – Animations",
        value: [
          "Introduction to Animations in Flutter",
          "Understanding the Use Case for Animations",
          "Understanding Tween type of Animations",
          "Understanding Animations based on Physics",
          "Understanding Implicit Animations",
          "Learning about Explicit Animations",
          "Understanding and using the Animation Controller",
          "Learning and implementing various Animation Classes",
          "Ticker Class",
          "Animation Class",
          "CurvedAnimation Class",
          "Implementing HERO Animation",
          "Understanding and implementing Staggered Animations"
        ]
      },
      {
        key: "Flutter – Advanced: Packages and Plugins",
        value: [
          "Understanding Packages",
          "Understanding Packages and Plugins",
          "Learning more about Packages and Plugins",
          "Comparing Packages and Plugins",
          "Advantages of Packages",
          "Learning to use Packages",
          "Searching and Adding a Package to the Project",
          "Understanding Package Dependencies",
          "Resolving Package Conflicts",
          "Creating New Packages and Plugins",
          "Understanding Package Types",
          "Understanding Dart Packages and Plugin Packages",
          "Creating and Implementing Packages",
          "Handling Package Documentations",
          "Publishing Packages"
        ]
      },
      {
        key: "Flutter – Advanced: Debugging, Testing, Hot Reload, Hot Restart",
        value: [
          "Learning to Debug Flutter Apps",
          "Learning about DevTools for debugging",
          "Starting with browser based Debugging",
          "Understanding Source Level Debugger",
          "Understanding and using Widget Inspector",
          "Working with Android Studio and IntelliJ for debugging",
          "Working with Flutter Inspector",
          "Using Breakpoints",
          "Troubleshooting common errors",
          "Handling Errors",
          "Working with Hot Reload",
          "Understanding Hot Reload",
          "Advantages of Hot Reload",
          "Performing Hot Reload",
          "Working with Hot Restart",
          "Understanding Hot Restart",
          "Comparing Hot Reload and Hot Restart",
          "Performing Hot Restart",
          "Understanding Testing",
          "Learning the Testing methods for Flutter Apps",
          "Understanding Unit Testing",
          "Learning to Test Widgets",
          "Introduction to Integration Testing",
          "Understanding Integration Testing",
          "Using the ‘integration_test’ package",
          "Understanding the ‘flutter_driver’ package",
          "Executing Integration Testing"

        ]
      },
      {
        key: "Flutter – Advanced: Deploying Flutter Apps",
        value: [
          "Understanding Deployment",
          "The pub spec file",
          "Performing Build and Release",
          "For Android",
          "For iOS",
          "For Web",
          "Deploying Flutter App",
          "Understanding Continuous Deployment"
        ]
      }
    ]
  },
  {
    id: "ReactNative",
    img: reactnative,
    title: "React Native Training | ALCE",
    route: "react-native-training-in-madurai",
    description: "Looking for the top training institute for React Native Training? Look no further than ALCE. Our comprehensive React Native Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for React Native Training? Look no further than ALCE. Our comprehensive React Native Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals. ",
    post: "Mobile App Development",
    title: "React Native Training",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The React Native Training at ALCE is a hands-on, comprehensive program that covers fundamental subjects including React Native Architecture, Components, Life Cycle, and Resources while providing expert mentoring and real-world practice. You will learn about important subjects like Layout, Navigation, State Management, Styles, Notifications, Local Storage, Communication, and how to publish your apps to the App Store and Google Play Store like a pro in ALCE's React Native Training curriculum.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
  <ul>
      <li>
          <b>Practical & Theoretical Approach</b>
          We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
      </li><br></br>
      <li>
          <b>Job-Specific Skill Sets</b>
          Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
      </li><br></br>
      <li>
          <b>Professional Expertise</b>
          The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
      </li><br></br>
      <li>
          <b>Real-Time Development Experience</b>
          Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
      </li><br></br>
      <li>
          <b>Industry-Current Practices</b>
          Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
      </li><br></br>
      <li>
          <b>One-to-One Mentorship</b>
          Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
      </li><br></br>
      <li>
          <b>Professional Resume Building</b>
          Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
      </li><br></br>
  <ul>
  At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "72",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn React Native at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the React Native Course at ALCE ?",
        value: `
        The React Native at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
        <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
      `
      },
      {
        key: "How Can I enroll for the React Native Course at ALCE ?",
        value: `
        You can enroll for the React Native Course at ALCE by contacting our support number +91 6369 964 058.
        <br></br>You can also join by walking directly to any of our branches in Madurai. 
      `
      },

      {
        key: "What will be the batch size of the React Native Course at ALCE and will I be given enough practical Training for the React Native Certification Course ?",
        value: `
        We provide maximum individual one-to-one attention to all the students. The React Native Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
        <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
      `
      },
      {
        key: "Are the React Native Trainers well-equipped at ALCE ?",
        value: `
        The React Native Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the React Native Programming language.  
      `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
        The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
      `
      }
    ],
    curriculum: [
      {
        key: "Introduction to React Native",
        value: [
          "Basics of React Native",
          "React Native Architecture",
          "React Native Studio Setup",
          "Adding dependencies",
          "Activity Life Cycle"
        ]
      },
      {
        key: "React Native Layouts",
        value: [
          "Linear Layout",
          "Relative Layout",
          "Frame Layout",
          "Coordinate Layout",
          "Table Layout",
          "Constraint Layout"
        ]
      },
      {
        key: "React Native widgets",
        value: [
          "Web View",
          "Checkbox",
          "Radio Buttons",
          "Progress Dialog",
          "Spinner",
          "AutoComplete Text View",
          "Card View",
          "Alert Dialog",
          "Custom Alert Dialog",
          "Horizontal Scrollview",
          "Vertical Scrollview",
          "Option Menu",
          "Popup Menu",
          "Time Picker",
          "Date Picker Dialog"
        ]
      },
      {
        key: "Broadcast receivers",
        value: [
          "Broadcast Receivers (System & Custom)",
          "Local Broadcast Manager"
        ]
      },
      {
        key: "Broadcast receivers",
        value: [
          "Activities - Persisting application state during configuration changes and creating activity hierarchies",
          "Fragments",
          "Explicit and Implicit intents - Navigating between activities using intents",
          "Using Notification Compact to Show Notifications – Using Pending Intent to package and send a delayed action",
          "Services for executing background work"
        ]
      },
      {
        key: "Constructing Option Menu",
        value: [
          "Grid View",
          "List View - Building layouts using XML and Java code and Grouping common UI design elements with styles",
          "Recycler View - Handling item touch interactions in a Recycler View",
          "Drawer Layout - Providing alternative resources for device configuration changes",
          "Tablayout and ViewPager"
        ]
      },
      {
        key: "Customizing Widgets",
        value: [
          "Canvas & Paint - Customizing the application theme",
          "Extending framework UI components to create custom views and validating application layouts properly respond to accessibility events, Constructing Option menus for action bar navigation"
        ]
      },
      {
        key: "Storage Media",
        value: [
          "Internal & External File Storage",
          "Shared Preference"
        ]
      },
      {
        key: "Google Map & Firebase",
        value: [
          "Google map - To display the google map using map key and get current location",
          "Firebase Push Notification"
        ]
      },
      {
        key: "Animations",
        value: [
          "Tween Animation",
          "View Property Animation",
          "Frame Animation"
        ]
      },
      {
        key: "Web Services",
        value: [
          "Asynchronous Task",
          "Volley",
          "Retrofit"
        ]
      },
      {
        key: "Jetpack Components",
        value: [
          "Dagger",
          "Navigation Component",
          "Work Manager"
        ]
      },
      {
        key: "React Native Framework",
        value: [
          "MVVM"
        ]
      },
      {
        key: "Database Framework & Third Party Libraries",
        value: [
          "Using SQLiteOpenHelper",
          "SQLite Data Types",
          "Room Database",
          "Mapping between Table & Widgets using Cursor Adapters",
          "Using A Sync Cursor Loaders",
          "Palette for extracting colors from images",
          "Picasso for Image Loading & Caching",
          "Butter Knife for Injecting Views"
        ]
      },
      {
        key: "Social Media Integration",
        value: [
          "Localization",
          "Facebook Integration"
        ]
      },
      {
        key: "End-to-End App Development",
        value: [
          "Debugging Applications",
          "End to End App Development including writing and executing automated unit tests on the local JVM",
          "Writing and executing automated instrumentation tests on an React Native device using the system log output (analogy cat tools) to trace code execution",
          "Locating an error from the stack trace of an uncaught exception and using Strict Mode to locate and report threading violations and leaks",
          "Using Proguard to obfuscate applications",
          "Generating Signing Keys"
        ]
      },
      {
        key: "Publishing",
        value: [
          "Publish the application on Google Play Store (Live Demo)",
          "Doubts & Queries Using Proguard to obfuscate applications"
        ]
      },
    ]
  },
  {
    id: "SoftwareTesting",
    img: SoftwareTesting,
    title: "Software Testing | ALCE",
    route: "software-testing-training-in-madurai",
    description: "Looking for the top training institute for Software Testing? Look no further than ALCE. Our comprehensive Software Testing covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Software Testing? Look no further than ALCE. Our comprehensive Software Testing covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Software Testing",
    title: "Software Testing",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `Learn about software testing from many angles, including software lifecycle models, test case preparation, software testing techniques, testing types, and much more. Towards the end of the course, you will be working on a few real-time Capstone Testing Projects. With the assistance of our devoted and knowledgeable coaching staff at ALCE, you will acquire practical knowledge of both manual and automated software testing. In order to deliver a comprehensive learning experience, our knowledgeable educators incorporate practical projects and current examples. Furthermore, ALCE offers 100% placement support to guarantee you land the ideal position. Thus, your hunt for the Best Software Testing Institute is over if you enroll in ALCE's Software Testing Course. Don't hesitate.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "74",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Software Testing at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Software Testing Course at ALCE ?",
        value: `
          The Software Testing at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Software Testing at ALCE ?",
        value: `
          You can enroll for the Software Testing at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Software Testing Course at ALCE and will I be given enough practical Training for the Software Testing Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Software Testing batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Software Testing Trainers well-equipped at ALCE ?",
        value: `
          The Software Testing Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Software Testing.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Manual Testing Course Syllabus - Software Lifecycle Models",
        value: [
          "Waterfall model",
          "V model",
          "Incremental model",
          "RAD model",
          "Agile model",
          "Iterative model",
          "Spiral model"
        ]
      },
      {
        key: "Introduction To Testing",
        value: [
          "Why Testing",
          "STLC - Software Testing Life Cycle",
          "Verification and Validation",
          "Desktop, Web and Mobile Applications Testing"
        ]
      },
      {
        key: "Test Strategy & Test Planning With A Live Project",
        value: [
          "Working on Test Strategy",
          "Preparing the Test Plan document",
          "Clarification Document",
          "Getting Familiar with Test Design"
        ]
      },
      {
        key: "Preparing A Test Case Document",
        value: [
          "Boundary Value Analysis (BVA)",
          "Equivalence Partitioning (EP)",
          "Decision Table Testing",
          "State Transition Diagrams",
          "Use Case Testing",
          "Statement Coverage",
          "Branch Coverage",
          "Path Coverage",
          "LCSAJ Testing"
        ]
      },
      {
        key: "Executing Test Cases",
        value: [
          "Test Reporting",
          "Defects Tracking Mechanism And Life Cycle",
          "Defect Categorization",
          "Guidelines On Deciding The Security Of A Defect",
          "Guidelines On Deciding The Priority Of Bug",
          "Cost Of Quality",
          "Defect Management With Tools Like Bugzilla"
        ]
      },
      {
        key: "Software Testing Techniques",
        value: [
          "Static Testing",
          "Dynamic Testing",
          "White Box Testing/ Structure Testing",
          "Black Box Testing"
        ]
      },
      {
        key: "Types Of Testing - Functional Testing:",
        value: [
          "Unit Testing",
          "Integration Testing",
          "Smoke Testing",
          "System Testing",
          "Regression Testing",
          "User Acceptance Testing",
          "Globalization Testing",
          "Localization Testing"
        ]
      },
      {
        key: "Types Of Testing - Non-Functional Testing:",
        value: [
          "Performance Testing",
          "Compatibility Testing",
          "Data Migration Testing",
          "Data Conversion Testing",
          "Security/Penetration Testing",
          "Usability Testing",
          "Install/Uninstall Testing"
        ]
      },
      {
        key: "Real-Time Testing Project:",
        value: [
          "Starting With Automation Testing:",
          "Why Test Automation",
          "Automation Feasibility Analysis",
          "Planning And Design",
          "Test Environment Setup",
          "Automation Script Generation",
          "Test Execution",
          "Defect Analysis & Fixing",
          "Cost Involved In Automation",
          "Automation Applied To Different Types Of Testing"
        ]
      },
      {
        key: "Automation Testing - Syllabus For Automation Testing With Selenium:",
        value: [
          "Automation Introduction",
          "Core Java",
          "Selenium IDE",
          "Selenium RC",
          "Selenium Webdriver",
          "Testing Frameworks",
          "Keyword-driven Framework",
          "Data-driven framework",
          "Hybrid Framework",
          "Globalization Testing",
          "Localization Testing",
          "Selenium Grid",
          "Continuous Integration with Hudson"
        ]
      },
    ]
  },
  {
    id: "Selenium",
    img: selenium,
    title: "Selenium | ALCE",
    route: "selenium-training-in-madurai",
    description: "Looking for the top training institute for Selenium Training? Look no further than ALCE. Our comprehensive Selenium Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Selenium Training? Look no further than ALCE. Our comprehensive Selenium Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Software Testing",
    title: "Selenium",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `The goal of the course curriculum is to give students a thorough understanding of all the different Selenium principles, including Selenium WebDriver, Selenium Grid, Selenium IDE, and so on. Additionally, testing frameworks like GitHub, Cucumber, TestNG, JUnit, Developing Hybrid Frameworks, POM, and Reporting Tools will be covered. Practical projects will provide you with first-hand experience to strengthen your learning. The trainers at ALCE have a plethora of practical experience with Selenium in various projects, so you may learn important lessons about the best practices used in the business. Enroll now to begin a life-changing educational adventure that will give you the know-how and abilities required to succeed in the fast-paced Selenium Testing industry.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "77",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Selenium Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Selenium Course at ALCE ?",
        value: `
          The Selenium Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Selenium Training at ALCE ?",
        value: `
          You can enroll for the Selenium Training at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Selenium Course at ALCE and will I be given enough practical Training for the Selenium Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Selenium Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Selenium Trainers well-equipped at ALCE ?",
        value: `
          The Selenium Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Selenium Training.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Automation Introduction",
        value: [
          "Why and When Automation?",
          "Is automation the final solution?"
        ]
      },
      {
        key: "Selenium Introduction",
        value: [
          "Selenium Introduction - What is Selenium and Why Selenium?",
          "Introduction of Selenium Components",
          "Selenium & Supported Languages - Java, C# & Python"
        ]
      },
      {
        key: "OOPS Concepts and Core Java",
        value: [
          "Setup Java environment - Download JDK and Setup eclipse",
          "Java programming concepts",
          "Class, Object, Variables, Methods & Packages",
          "OOPs concepts - Abstraction, Encapsulation, Polymorphism & Inheritance",
          "Conditions and Loops",
          "Access and Non access modifiers",
          "Interfaces, Constructors",
          "Collections - Set, List, Array, ArrayList, HashSet, Hashtable, HashMap",
          "Keyword List",
          "String Handling",
          "Exception Handling",
          "Retrieving data from the database"
        ]
      },
      {
        key: "Selenium IDE and RC",
        value: [
          "Installing Selenium IDE",
          "“Selenese” - Selenium Commands",
          "Actions, Asserts, Assesors",
          "Developing Test Cases & Test Suites With Selenium-IDE",
          "Introduction to Selenium RC"
        ]
      },
      {
        key: "Selenium Web Drivers",
        value: [
          "Selenium WebDriver Introduction",
          "Architectural difference between WebDriver Vs RC",
          "Download and Configure WebDriver with Eclipse",
          "Cross Browser Testing - Firefox, IE, Chrome, HtmlUnitDriver",
          "Object locators/Identification",
          "Handling various WebElement using WebDriver",
          "Handling Mouse movements and keyboard Events",
          "Handling Pushed Pop-ups, Windows, and Alerts",
          "Handling WebTables",
          "Automating Real time scenarios",
          "Handling Files - Upload & Download using AutoIT"
        ]
      },
      {
        key: "Headless Browser Testing",
        value: [
          "What is Headless Browser Testing?",
          "Its Advantages and Disadvantages",
          "Testing Using HTMLUnitDriver , PhantomJS , ChromeDriver"
        ]
      },
      {
        key: "Flash Object Testing using Sikuli",
        value: [
          "What is Flash Object Testing",
          "How to handle flash Objects in using Sikuli"
        ]
      },
      {
        key: "Testing Framework",
        value: [
          "Introduction of testing framework & Unit Testing",
          "Difference between TestNG and JUnit",
          "TestNG introduction and Configuration with eclipse",
          "TestNG Annotations and Data Providers",
          "Creating Test Suite with TestNG",
          "Parallel Execution in TestNG",
          "Tools helping Object identification"
        ]
      },
      {
        key: "Testing Frameworks",
        value: [
          "Keyword driven Framework",
          "Data driven framework",
          "Hybrid Framework"
        ]
      },
      {
        key: "Develop Hybrid Framework",
        value: [
          "Creating Hybrid Framework for Web Application using WebDriver/TestNG",
          "Use external Data for Testing (Excel sheet, XML files, Property file)",
          "Reading and understanding reports",
          "Screenshot of Failed Test case"
        ]
      },
      {
        key: "Design Pattern - Page Object Model (POM)",
        value: [
          "What is POM?",
          "What is Page factory?",
          "How to implement POM ?",
          "Test Case Execution using POM"
        ]
      },
      {
        key: "Cucumber",
        value: [
          "Cucumber introduction",
          "Why Cucumber",
          "BDD & its application",
          "Gherkin",
          "Components of Gherkin",
          "Step Definition",
          "Setting up a Cucumber Project"
        ]
      },
      {
        key: "Selenium Grid",
        value: [
          "Introduction to Selenium Grid",
          "Configuring Grid setup",
          "Configuring Grid for Multi-browsers",
          "Remote Webdriver"
        ]
      },
      {
        key: "Build Integration",
        value: [
          "Integration with Maven",
          "Creating a Maven Project"
        ]
      },
      {
        key: "Continuos Integration",
        value: [
          "Integration with Jenkins"
        ]
      },
      {
        key: "Continuos Integration",
        value: [
          "Git Hub - Source Code Management",
          "Whatis Version Control?",
          "What is GitHub?",
          "How to Download and Install Git?",
          "Creating a New Repository",
          "Push changes to a Repository",
          "Send a Pull request",
          "Merge changes from a pull request"
        ]
      },
      {
        key: "Reporting Tools",
        value: []
      },
      {
        key: "Interview Guidance and Mock Interview",
        value: []
      },
      {
        key: "Real -Time Project",
        value: []
      },
    ]
  },
  {
    id: "Appium",
    img: appium,
    title: "Appium | ALCE",
    route: "appium-training-in-madurai",
    description: "Looking for the top training institute for Appium Training? Look no further than ALCE. Our comprehensive Appium Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Appium Training? Look no further than ALCE. Our comprehensive Appium Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Software Testing",
    title: "Appium",
    mprice: "15,000",
    oprice: "1#,000",
    overview: `Are you looking for a reputable website to educate you about mobile test automation? Under the direction of certified Mobile App Test Experts, ALCE's Appium Training offers thorough instruction on how to automate native, hybrid, and mobile web applications using the Appium tool in a professional manner.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "15",
    duration: "30 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Appium Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Appium Training Course at ALCE ?",
        value: `
          The Appium Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Appium Training at ALCE ?",
        value: `
          You can enroll for the Appium Training at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Appium Training Course at ALCE and will I be given enough practical Training for the Appium Training Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Appium Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Appium Training Trainers well-equipped at ALCE ?",
        value: `
          The Appium Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Appium Training.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Mobile Testing with Appium training Topics",
        value: [
          "Appium server, clients, and drivers",
          "Appium setup",
          "Starting and stopping sessions",
          "Finding and using elements",
          "Intermediate Appium scripting",
          "Touch actions",
          "Mobile web testing"
        ]
      },
      {
        key: "Advance Appium Training Topics",
        value: [
          "Automating hybrid apps",
          "Inspecting web views",
          "Web view automation in practice",
          "Android and iOS device automation",
          "Automating system apps",
          "Automating app install, removal, and launch",
          "Simulating phone calls and SMS",
          "Reducing wait time for app state"
        ]
      },
    ]
  },
  // {
  //   id: "CloudComputing",
  //   img: appium,
  //   title: "Cloud Computing | ALCE",
  //   route: "cloud-computing-training-in-madurai",
  //   description: "Looking for the top training institute for Cloud Computing Training? Look no further than ALCE. Our comprehensive Cloud Computing Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
  //   keyborad: "Looking for the top training institute for Cloud Computing Training? Look no further than ALCE. Our comprehensive Cloud Computing Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
  //   post: "Cloud & DevOps",
  //   title: "Cloud Computing",
  //   mprice: " _,999",
  //   oprice: " _,999",
  //   overview: `Searching for the top training in cloud computing? Learn from ALCE, the top-ranked cloud computing training institute that offers hands-on, project-oriented training on all the main cloud computing technologies, including Google Cloud, AWS, Microsoft Azure, VMware, and Salesforce.`,
  //   instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
  //   <ul>
  //       <li>
  //           <b>Practical & Theoretical Approach</b>
  //           We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
  //       </li><br></br>
  //       <li>
  //           <b>Job-Specific Skill Sets</b>
  //           Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
  //       </li><br></br>
  //       <li>
  //           <b>Professional Expertise</b>
  //           The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
  //       </li><br></br>
  //       <li>
  //           <b>Real-Time Development Experience</b>
  //           Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
  //       </li><br></br>
  //       <li>
  //           <b>Industry-Current Practices</b>
  //           Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
  //       </li><br></br>
  //       <li>
  //           <b>One-to-One Mentorship</b>
  //           Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
  //       </li><br></br>
  //       <li>
  //           <b>Professional Resume Building</b>
  //           Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
  //       </li><br></br>
  //   <ul>
  //   At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
  //   lectures: "5",
  //   duration: "30 Hrs",
  //   level: "Intermediate",
  //   communication: "English/Tamil",
  //   overviewTitle: "Why Learn Cloud Computing Training at ALCE ?",
  //   why: [
  //     {
  //       value: "Live Capstone Projects"
  //     },
  //     {
  //       value: "Real time Industry Experts as Trainers"
  //     },
  //     {
  //       value: "Placement Support till you get your Dream Job offer!"
  //     },
  //     {
  //       value: "Free Interview Clearing Workshops"
  //     },
  //     {
  //       value: "Real-Time Experts as Trainers"
  //     },
  //     {
  //       value: "Affordable Fees"
  //     }
  //   ],
  //   faq: [
  //     {
  //       key: "Why Should I learn the Cloud Computing Training Course at ALCE ?",
  //       value: `
  //         The Cloud Computing Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
  //         <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
  //       `
  //     },
  //     {
  //       key: "How Can I enroll for the Cloud Computing Training at ALCE ?",
  //       value: `
  //         You can enroll for the Cloud Computing Training at ALCE by contacting our support number 9500411626.
  //         <br></br>You can also join by walking directly to any of our branches in Madurai. 
  //       `
  //     },

  //     {
  //       key: "What will be the batch size of the Cloud Computing Training Course at ALCE and will I be given enough practical Training for the Cloud Computing Training Certification Course ?",
  //       value: `
  //         We provide maximum individual one-to-one attention to all the students. The Cloud Computing Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
  //         <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
  //       `
  //     },
  //     {
  //       key: "Are the Cloud Computing Training Trainers well-equipped at ALCE ?",
  //       value: `
  //         The Cloud Computing Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Cloud Computing Training.  
  //       `
  //     },
  //     {
  //       key: "What are the Payments Options Accepted ALCE?",
  //       value: `
  //         The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
  //       `
  //     }
  //   ],
  //   curriculum: [
  //     {
  //       key: "Amazon Web Services",
  //       value: [
  //       ]
  //     },
  //     {
  //       key: "Microsoft Azure",
  //       value: [
  //       ]
  //     },
  //     {
  //       key: "VMWare",
  //       value: [
  //       ]
  //     },
  //     {
  //       key: "Salesforce",
  //       value: [
  //       ]
  //     },
  //     {
  //       key: "Google Cloud Computing",
  //       value: [
  //       ]
  //     },

  //   ]
  // },
  {
    id: "AWS",
    img: aws,
    title: "AWS | ALCE",
    route: "aws-training-in-madurai",
    description: "Looking for the top training institute for AWS Training? Look no further than ALCE. Our comprehensive AWS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for AWS Training? Look no further than ALCE. Our comprehensive AWS Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Cloud & DevOps",
    title: "AWS",
    mprice: "18,000",
    oprice: "1#,000",
    overview: `You will learn how to use the several AWS components, such as computing (EC2, Lambda), storage systems (S3, Glacier), data management (RDS, DynamoDB), security services (IAM, VPN), etc., from our qualified and experienced professors at ALCE. Over fifteen of the most popular AWS services are covered in our syllabus. You will gain the professional and technical skill sets required to succeed as an AWS professional with our outstanding and first-rate training. If you possess the necessary abilities and background, you can take your career to the next level and become an AWS Solutions Architect. The AWS Course places a strong focus on giving students practical instruction. As a result, you will have a comprehensive educational experience and acquire both theoretical and practical expertise in planning.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "295",
    duration: "60 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn AWS Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the AWS Training Course at ALCE ?",
        value: `
          The AWS Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the AWS Training at ALCE ?",
        value: `
          You can enroll for the AWS Training at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the AWS Training Course at ALCE and will I be given enough practical Training for the AWS Training Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The AWS Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the AWS Training Trainers well-equipped at ALCE ?",
        value: `
          The AWS Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the AWS Training.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Introduction To Cloud Computing",
        value: [
          "What is cloud computing?",
          "History & a comparison with Client server computing",
          "Advantages of Cloud Computing",
          "Roles in Cloud – Sys Ops, Dev Ops and Cloud Architect",
          "Leading Cloud Players – Amazon’s AWS, Microsoft Azure, Openstack and VMWare",
          "Future of Cloud"
        ]
      },
      {
        key: "Cloud Computing Service Models",
        value: [
          "Mastering Software as a Service (SaaS)",
          "Mastering Platform as a Service (PaaS)",
          "Mastering Infrastructure as a Service (IaaS)"
        ]
      },
      {
        key: "Cloud Deployment Models",
        value: [
          "Understanding Public Clouds",
          "Understanding Community Clouds",
          "Understanding Private Clouds",
          "Understanding Hybrid Clouds"
        ]
      },
      {
        key: "Getting Into AWS World",
        value: [
          "Overview of AWS",
          "Accessing AWS",
          "Creating a AWS Account",
          "AWS Management Console",
          "AWS Products and major Services",
          "Architecture of AWS"
        ]
      },
      {
        key: "Getting Familiar With Basic Linux",
        value: [
          "Playing with Linux Commands like ls, cd, mv, man, mkdir, rmdir, rm, locate, clear",
          "Getting hands on Linux administration commands uptime, w command, users, who, whoami, crontab, less, more, cp, cat, pwd, sort, vi, ssh, ftp, sftp, service, free, top, tar, grep, find, lsof, last, ps,"
        ]
      },
      {
        key: "How To Design Like A Solutions Architect",
        value: [
          "Introduction to AWS Solution Architect",
          "Planning and Designing Cloud Services",
          "Designing Highly Available, Scalable, Cost-efficient Systems",
          "Important Cost planning and Optimization techniques a Solutions Architect should consider",
          "Knowing and adopting Best Practices for AWS Security and Costing",
          "Monitoring and Logging in AWS"
        ]
      },
      {
        key: "Getting Started With Amazon Elastic Compute Cloud",
        value: [
          "Features of Amazon EC2",
          "Getting Started with Amazon EC2",
          "EC2 Pricing",
          "Regions and Availability zones",
          "Root device volume",
          "Setting up",
          "Sign up",
          "Create an IAM user",
          "Create Key Pair",
          "Instances and AMIs"
        ]
      },
      {
        key: "Mastering Ec2 Instances",
        value: [
          "Instant Types in EC2",
          "General purpose",
          "Compute optimized",
          "Memory optimized",
          "Storage Optimized",
          "Accelerated Computing",
          "Mastering Amazon AMIs",
          "Launching and Setting up EC2 Instance",
          "Connect to your Instance",
          "Instance Lifecycle",
          "Launch",
          "Stop",
          "Start",
          "Hibernate",
          "Reboot",
          "Retirement",
          "Termination",
          "Managing Users",
          "Managing Softwares",
          "Managing Network and Security",
          "Security Groups",
          "Controlling access",
          "Assigning IPs",
          "Elastic IP addresses",
          "Placement Groups",
          "Hosting a website on AWS EC2 instance",
          "EC2 Best practices to consider from a Solutions Architect standpoint",
          "Use cases"
        ]
      },
      {
        key: "Autoscaling In AWS",
        value: [
          "Overview of Auto Scaling",
          "Benefits of Auto Scaling",
          "Key Components of Auto Scaling",
          "Autoscaling Lifecycle",
          "Create and Launch Configuration in Autoscaling",
          "Create an Auto Scaling Group",
          "Verify an Auto Scaling Group",
          "Tagging Autoscaling groups and Instances",
          "Scaling your Group",
          "Monitoring Auto Scaling Groups",
          "Health Checks",
          "Determine Instance Health",
          "Health Check Grace Period",
          "Replacing Unhealthy Instances",
          "Troubleshooting and Managing Issues",
          "Pricing for Auto Scaling",
          "Use cases"
        ]
      },
      {
        key: "Amazon Virtual Private Cloud (VPC)",
        value: [
          "Overview of Amazon Virtual Private Cloud (VPC)",
          "Understanding Amazon VPC and Subnets",
          "Accessing the Internet",
          "Pricing for Amazon VPC",
          "Amazon VPC limits",
          "PCI DSS Compliance",
          "Networking essentials",
          "Understanding Gateways",
          "Using Route Tables",
          "IPv4 and IPv6",
          "Using Security Groups and Network ACLs",
          "Launch an Instance into your VPC",
          "Assign Elastic IP Instance into VPC",
          "Amazon VPC Best Practices and Cost Considerations",
          "Use cases"
        ]
      },
      {
        key: "Elastic Load Balancing (ELB)",
        value: [
          "Overview of Load balancing",
          "Load balancing benefits",
          "How ELB works",
          "Load balancer Types",
          "Understanding how to Configure Listener",
          "Working with Listener Rules",
          "How to authenticate users",
          "Configure Security for Load balancer",
          "Register and Deregister Target Groups",
          "Configure health checks for Load balancer",
          "Troubleshooting Bad requests, unauthorized access, Forbidden and Timeout issues",
          "ELB limits"
        ]
      },
      {
        key: "Amazon Simple Notification Service (SNS)",
        value: [
          "How Amazon SNS Works",
          "Create a Topic",
          "Create a Subscription for an Endpoint to the Topic",
          "How to Publish a Message to the Topic",
          "How to Delete the Subscription and Topic",
          "Working with Push Email",
          "Working with Text Messaging",
          "Mobile Phone number as Subscriber",
          "Sending a Message to Multiple Phone numbers"

        ]
      },
      {
        key: "Amazon Simple Email Service (SES)",
        value: [
          "How Amazon SES Works",
          "How to Setup Email",
          "How to Send Email",
          "How to Authenticate Email",
          "Managing Sending Limits in SES",
          "Testing & Receiving Emails",
          "Monitoring Sender Reputation",
          "Controlling Access"
        ]
      },
      {
        key: "Amazon Simple Queue Service (SQS)",
        value: [
          "How Amazon SQS Works",
          "Benefits of SQS",
          "Creating Queues",
          "Adding Permission to the Queue",
          "Subscribing a Queue to a Topic",
          "Queue Types",
          "Standard Queue",
          "FIFO Queue",
          "Limits related queues"

        ]
      },
      {
        key: "Amazon Route 53",
        value: [
          "Overview of Amazon Route 53",
          "Domain registration with Route 53",
          "Updating Settings for a Domain:",
          "Privacy protection",
          "Automatic renewal",
          "Locking to prevent unauthorized access",
          "Adding and Changing DNS",
          "Configuring Route 53 as Domain Name System (DNS) service",
          "Existing Domains",
          "New Domains",
          "Working with Hosted Zones",
          "Public hosted zone",
          "Private hosted zone",
          "Migrating a hosted zone to a different AWS Account",
          "Working with Records",
          "Routing Policy",
          "Creating records by importing Zone file",
          "Editing records",
          "Deleting records",
          "Listing Records",
          "Health checking",
          "DNS Constraints and Behaviors",
          "Configuring DNS Failover",
          "Task List for Configuring Failover",
          "Simple configurations",
          "Complex configurations",
          "Troubleshooting",
          "Domain unavailable in the internet",
          "Domain Suspended",
          "Other common Issues"

        ]
      },
      {
        key: "Identity Access Management (IAM)",
        value: [
          "Getting started with IAM",
          "Creating Users",
          "Configuring IAM",
          "Permissions in IAM",
          "Policies in IAM: Creating and Attaching a Policy",
          "Managing Policies",
          "Creating IAM policies",
          "Editing Policies",
          "Testing Policies",
          "Versioning Policies",
          "Deleting Policies",
          "Groups in IAM: Creating and Managing Groups",
          "Roles in IAM: Creating, using and Managing Roles",
          "Tagging Entities",
          "Temporary Security Credentials",
          "Access control distribution using IAM",
          "Troubleshooting in IAM"

        ]
      },
      {
        key: "Amazon Simple Storage Service (S3)",
        value: [
          "Introduction to Storage Services",
          "S3 Overview",
          "Signup for S3",
          "Understanding Buckets in AWS",
          "Creating Amazon S3 bucket",
          "Configuring the bucket",
          "Adding Objects into your S3 bucket",
          "View, Move and Deleting an Object",
          "Deleting the S3 bucket",
          "Working with Security",
          "Working with Encryption",
          "How to host a Static website in S3",
          "Configuring a bucket for website hosting",
          "Cross region replication",
          "CRR Configurations",
          "Working with Event notifications",
          "Monitoring Tools: Automated and Manual",
          "Monitoring Metrics with CloudWatch",
          "Troubleshooting",
          "S3 Pricing"
        ]
      },
      {
        key: "Amazon Glacier Storage",
        value: [
          "Overview of archiving concepts",
          "Overview and learn how to create a Vault",
          "Uploading the Archive in Vault",
          "Downloading the Archive from Vault",
          "Deleting the Archive from Vault",
          "Querying the archive",
          "SQL functions in Glacier",
          "Other key operations in Vault",
          "Configuring Vault notifications",
          "Data retrieval policies",
          "Authentication and user permissions"
        ]
      },
      {
        key: "Aws Resource Monitoring Using Cloudwatch",
        value: [
          "Overview of Monitoring",
          "Identifying the key resources to be monitored",
          "Understanding how to use Dashboards",
          "Monitoring resources in AWS",
          "Understanding how to use Metrics",
          "View available metrics",
          "Search available metrics",
          "Get statistics for metrics",
          "Working with Custom metrics",
          "Understanding how to use Alarms",
          "Create Alarm based on single metric",
          "Edit CloudWatch Alarm",
          "Create Alarm to Stop, Terminate and perform various operations on EC2 Instance",
          "Configuring alerts and notifications for usage using Amazon CloudWatch",
          "Configuring alerts for billing using CloudWatch"

        ]
      },
      {
        key: "Amazon Cloudfront",
        value: [
          "How CloudFront Works",
          "How to Setup CloudFront to deliver content",
          "Understanding Distributions",
          "Creating, updating and deleting distributions",
          "Restricting the Content",
          "Geo-restricting Content",
          "Improving Content speed through Caching",
          "Caching Options",
          "Monitoring CloudFront",
          "Troubleshooting Issues in CloudFront"
        ]
      },
      {
        key: "Amazon Relational Database Service (RDS)",
        value: [
          "Refresher of Database Concepts",
          "Amazon RDS Components",
          "DB Instances",
          "DB Instance Complete lifecycle:",
          "Creating DB instances",
          "Connecting to DB Instance",
          "Modifying DB Instance",
          "Maintaining DB instance",
          "Other important DB instance Operations",
          "Regions and Availability Zones",
          "DB Parameter Groups",
          "DB Option Groups",
          "Available RDS Interfaces",
          "Configuring Security in RDS – Authentication & Access control",
          "Understanding how to Backup and Restore",
          "Creating a DB Snapshot",
          "Copying a Snapshot",
          "Restoring from a DB snapshot",
          "Limits in Amazon RDS",
          "Pricing of Amazon RDS",
          "Monitoring an Amazon RDS DB Instance",
          "Troubleshooting common RDS issues",
          "Database log files",
          "Best Practices in RDS"
        ]
      },
      {
        key: "Amazon DynamoDB",
        value: [
          "Getting started with DynamoDB",
          "DynamoDB Core Components",
          "Working with Tables, Items, Attributes and Primary Key",
          "Naming Rules and Data Types",
          "Using DynamoDB Console",
          "Creating Tables and Loading Data",
          "Running DynamoDB on Your Computer",
          "Working with Queries",
          "Working with Scans",
          "Working with indexes",
          "Working with Steams",
          "Back-up and restore",
          "Best Practices in DynamoDB"

        ]
      },
      {
        key: "Elastic Beanstalk",
        value: [
          "Overview of Elastic Beanstalk",
          "Supported Platforms",
          "AWS Elastic Beanstalk Environment Management Console",
          "Creating an AWS Elastic Beanstalk Environment",
          "Managing the environment",
          "Deploying Applications to AWS Elastic Beanstalk Environments",
          "Deployment Options",
          "Deploying new application versions",
          "Configuration Changes"

        ]
      },
      {
        key: "Cloud Formation",
        value: [
          "How to Simplify Infrastructure management with Cloud Formation",
          "Quickly Replicating your Infrastructure",
          "Working with Stacks",
          "Working with Templates",
          "Controlling and Tracking changes"
        ]
      },
      {
        key: "Migrating To Cloud",
        value: [
          "Understanding Existing Environment",
          "Understanding the Architecture difference between Existing Environment and AWS Service",
          "How to migrate to AWS"

        ]
      },
      {
        key: "Disaster Recovery",
        value: [
          "Overview of Disaster Recovery",
          "Services for Disaster Recovery",
          "Setting Up Access to AWS Application Discovery Service",
          "Setting Up Agentless Discovery"

        ]
      },
      {
        key: "Security In Aws",
        value: [
          "Security Practices for Optimum Cloud Deployment",
          "AWS Responsibilities and Security Attributes",
          "AWS CloudFormation and Design patterns"

        ]
      },
      {
        key: "Real Time AWS Project",
        value: [

        ]
      },
    ]
  },
  {
    id: "GoogleCloud",
    img: appium,
    title: "Google Cloud | ALCE",
    route: "google-cloud-training-in-madurai",
    description: "Looking for the top training institute for Google Cloud Training? Look no further than ALCE. Our comprehensive Google Cloud Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Google Cloud Training? Look no further than ALCE. Our comprehensive Google Cloud Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Cloud & DevOps",
    title: "Google Cloud",
    mprice: "18,000",
    oprice: "1#,000",
    overview: `Are you trying to find the best way to become an expert on the Google Cloud Platform? Next, select ALCE! Through Google Cloud Training at ALCE, you may learn the ins and outs of the Google Cloud Architecture under the guidance of Cloud Experts. Your understanding of how to implement Google Cloud Platform infrastructure components, such as storage systems, networks, and application services, will be enhanced by our knowledgeable Google Cloud Trainers at ALCE. They will also instill in you the professional and technical skill sets necessary to become a certified Google Cloud Architect.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "84",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Google Cloud Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Google Cloud Training Course at ALCE ?",
        value: `
          The Google Cloud Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Google Cloud Training at ALCE ?",
        value: `
          You can enroll for the Google Cloud Training at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Google Cloud Training Course at ALCE and will I be given enough practical Training for the Google Cloud Training Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Google Cloud Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Google Cloud Training Trainers well-equipped at ALCE ?",
        value: `
          The Google Cloud Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Google Cloud Training.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Getting Started",
        value: [
          "Creating a Compute Engine Project",
          "Enabling Billing",
          "Adding Team Members",
          "Compute Engine Resources",
          "Manage Compute Engine Resources"
        ]
      },
      {
        key: "Instances",
        value: [
          "Creating an Instance Using the Developers Console",
          "Accessing an Instance Using the Developers Console",
          "Deleting an Instance Using the Developers Console",
          "Creating an Instance Using gcloud",
          "Instance Attributes",
          "Accessing an Instance Using gcloud",
          "Deleting an Instance Using gcloud",
          "Creating an Instance Programmatically",
          "Creating an Instance Using a Service Account",
          "Selecting an Access Mode",
          "Cleaning Up"
        ]
      },
      {
        key: "Storage: Persistent Disk",
        value: [
          "Compute Engine Storage Options at a Glance",
          "Persistent Disk",
          "Persistent Disk Performance",
          "Create a Persistent Disk Using Developers Console",
          "Create a Persistent Disk Using Gcloud",
          "Attaching/Detaching a PD to/from a Running VM",
          "Create a Persistent Disk Programmatically",
          "Persistent Disk Snapshots"
        ]
      },
      {
        key: "Storage: Cloud Storage",
        value: [
          "Understanding BLOB Storage",
          "Getting Started",
          "Introducing G-suit",
          "Using Cloud Storage from Your Code",
          "Configuring Access Control",
          "Understanding ACLs",
          "Using Default Object ACLs",
          "Understanding Object Immutability",
          "Understanding Strong Consistency"
        ]
      },
      {
        key: "Storage: Cloud SQL and Cloud Datastore",
        value: [
          "Cloud SQL",
          "Getting Started",
          "Creating Databases and Tables",
          "Running SQL Queries",
          "Cloud Datastore",
          "Getting Started",
          "Creating and Viewing Entities via the Developers Console",
          "Creating and Retrieving Entities Programmatically from a VM",
          "Bring Your Own Database"
        ]
      },
      {
        key: "Networking",
        value: [
          "A Short Networking Primer",
          "Network Addresses and Routing",
          "Transport Control Protocol (TCP)",
          "The Domain Name System (DNS)",
          "Hypertext Transfer Protocol (HTTP)",
          "Load Balancing",
          "Firewalls",
          "Default Networking",
          "Configuring Firewall Rules",
          "Configuring Load Balancing",
          "Reserving External IP Addresses",
          "Configuring Networks",
          "Understanding Networking Costs",
          "Understanding Routing",
          "Selecting an Access Mode"
        ]
      },
      {
        key: "Advanced Topics",
        value: [
          "Startup Scripts",
          "cloud compute",
          "Literal-Value Approach",
          "Local-File Approach",
          "Cloud-Storage Approach",
          "Publicly Available Approach",
          "API Approach",
          "Custom Images",
          "Creating a Custom Image",
          "Using a Custom Image",
          "Metadata",
          "Metadata Server",
          "Metadata Entries",
          "Project Metadata",
          "Instance Metadata",
          "Data Formats",
          "Default Versus Custom",
          "Project-Level Custom Metadata",
          "Instance-Level Custom Metadata",
          "wait_for_change URL parameter"
        ]
      }
    ]
  },
  {
    id: "MicrosoftAzure",
    img: appium,
    title: "Microsoft Azure | ALCE",
    route: "microsoft-azure-training-in-madurai",
    description: "Looking for the top training institute for Microsoft Azure Training? Look no further than ALCE. Our comprehensive Microsoft Azure Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    keyborad: "Looking for the top training institute for Microsoft Azure Training? Look no further than ALCE. Our comprehensive Microsoft Azure Training covers everything from the basics to advanced topics, all under the guidance of experienced professionals.",
    post: "Cloud & DevOps",
    title: "Microsoft Azure",
    mprice: "18,000",
    oprice: "1#,000",
    overview: `Professional trainers with over ten years of experience in cloud computing and adept usage of Azure Virtual Networks have created and are delivering the Windows Azure training. Azure Networking, Azure Virtual Machines, Azure App Service, Azure Containers, Azure Cloud Services, and Azure Active Directory are all covered in the course. Additionally, you will learn how to administer an Azure-based management and automation system as well as an Active Directory Infrastructure in a hybrid environment. Real-time capstone projects are incorporated into the curriculum. Students graduate with a prestigious qualification. Students receive placement support in order to secure well-paying positions in the Microsoft Azure field.`,
    instructor: `In ALCE Training is dedicated to providing comprehensive training with a balanced practical and theoretical approach to Spring programming concepts. Here’s how we ensure top-quality education for our students:<br></br>
    <ul>
        <li>
            <b>Practical & Theoretical Approach</b>
            We elucidate training with a correct mix of practical and theoretical knowledge, ensuring students grasp the core concepts of Spring programming.
        </li><br></br>
        <li>
            <b>Job-Specific Skill Sets</b>
            Our Spring tutors at ALCE Institution equip students with job-specific skill sets essential for the software development industry.
        </li><br></br>
        <li>
            <b>Professional Expertise</b>
            The instructors at ALCE Academy are professionals with extensive experience in the software development domain, bringing real-world insights to the classroom.
        </li><br></br>
        <li>
            <b>Real-Time Development Experience</b>
            Our trainers are real-time Spring developers with over a decade of experience, enabling them to teach students how to code and program efficiently in these languages.
        </li><br></br>
        <li>
            <b>Industry-Current Practices</b>
            Instructors enhance students'knowledge by training them intensively on the latest practices and techniques used in the software development field.
        </li><br></br>
        <li>
            <b>One-to-One Mentorship</b>
            Our tutors provide personalized mentorship, ensuring each participant receives hands-on learning experiences tailored to their individual needs.
        </li><br></br>
        <li>
            <b>Professional Resume Building</b>
            Mentors guide students in professionally crafting their resumes, making them stand out to potential employers.
        </li><br></br>
    <ul>
    At ALCE Academy, our mission is to ensure that students not only learn but also excel in their careers with a strong foundation in Spring programming.`,
    lectures: "96",
    duration: "50 Hrs",
    level: "Intermediate",
    communication: "English/Tamil",
    overviewTitle: "Why Learn Microsoft Azure Training at ALCE ?",
    why: [
      {
        value: "Live Capstone Projects"
      },
      {
        value: "Real time Industry Experts as Trainers"
      },
      {
        value: "Placement Support till you get your Dream Job offer!"
      },
      {
        value: "Free Interview Clearing Workshops"
      },
      {
        value: "Real-Time Experts as Trainers"
      },
      {
        value: "Affordable Fees"
      }
    ],
    faq: [
      {
        key: "Why Should I learn the Microsoft Azure Training Course at ALCE ?",
        value: `
          The Microsoft Azure Training at ALCE is curated and conducted by Coding & Programming Experts who has 10+ years of experience in the Oracle Programming language
          <br/><br/>ALCE is the only coaching center that follows the blended method of learning (Practical + Theoretical) concepts of the Oracle programming language
        `
      },
      {
        key: "How Can I enroll for the Microsoft Azure Training at ALCE ?",
        value: `
          You can enroll for the Microsoft Azure Training at ALCE by contacting our support number 9500411626.
          <br></br>You can also join by walking directly to any of our branches in Madurai. 
        `
      },

      {
        key: "What will be the batch size of the Microsoft Azure Training Course at ALCE and will I be given enough practical Training for the Microsoft Azure Training Certification Course ?",
        value: `
          We provide maximum individual one-to-one attention to all the students. The Microsoft Azure Training batch size is optimized for 5 - 6 members per batch. The batch size has been customized for individual attention and to clear the doubts of the students in complex topics clearly with tutors. 
          <br></br>ALCE provides the necessary practical training to students with many Industry case studies and real-time projects 
        `
      },
      {
        key: "Are the Microsoft Azure Training Trainers well-equipped at ALCE ?",
        value: `
          The Microsoft Azure Training Mentors at ALCE Training Institute are Certified Experts and they have 10+ years of experience in the Microsoft Azure Training.  
        `
      },
      {
        key: "What are the Payments Options Accepted ALCE?",
        value: `
          The different Payment Options Accepted at ALCE are Bank Transfer, Cash, Card, G Pay, and Net Transfer.
        `
      }
    ],
    curriculum: [
      {
        key: "Introduction to Azure",
        value: [
          "Overview of Cloud Technology",
          "Azure - an overview",
          "Manage Azure with the Azure portal",
          "Manage Azure with Windows PowerShell",
          "Manage Azure with Azure CLI",
          "Overview of Azure deployment models",
          "Managing and monitoring Azure resources",
          "Managing Microsoft Azure",
          "Using the Azure portals",
          "Using the Azure Resource Manager",
          "features in the Azure portal",
          "Using Azure PowerShell",
          "Using Azure CLI"
        ]
      },
      {
        key: "Implementing and managing Azure Networking",
        value: [
          "Overview of Azure Networking",
          "Implement and manage Virtual Networks",
          "Configure Azure Virtual Network",
          "Configure Virtual Network connectivity",
          "Overview of Azure Classic Networking",
          "Using a deployment template and Azure",
          "PowerShell for implementing Azure Virtual Networks",
          "Create an Azure Virtual Network using a deployment template",
          "Create a Virtual Network using Azure PowerShell",
          "Create a Virtual Network using Azure CLI",
          "Configuring VNet Peering",
          "Using the Azure portal to configure VNet Peering",
          "Configuring VNet Peering–based Service Chaining",
          "Validating Virtual Network connectivity"
        ]
      },
      {
        key: "Implementing Virtual Machines",
        value: [
          "Azure VMs - an overview",
          "Deployment of Azure VMs",
          "Deploying Azure VMs",
          "Overview of classic Azure VMs",
          "Deploying Azure VMs",
          "Creating Azure VMs by using the Azure portal, Azure PowerShell, and Azure CLI",
          "Validating Azure VM deployment",
          "Deploying Azure VMs by using Azure Resource Manager templates",
          "Using Visual Studio and an Azure Resource Manager template to deploy Azure VMs",
          "Using Azure PowerShell and an Azure Resource Manager template to deploy Azure VMs",
          "Using Azure CLI and an Azure Resource Manager template to deploy Azure VMs"
        ]
      },
      {
        key: "Managing Azure VMs",
        value: [
          "Configuring Azure VMs",
          "Managing disks of Azure VMs",
          "Managing and monitoring Azure VMs",
          "Managing Classic Azure VMs",
          "Managing Azure VMs",
          "Implementing Desired State Configuration (DSC)",
          "Implementing Storage Spaces–based volumes"
        ]
      },
      {
        key: "Implementing Azure App Service",
        value: [
          "Introduction to App Service",
          "Planning app deployment in App Service",
          "Implementing and maintaining Web Apps",
          "Configuring Web Apps",
          "Monitoring Web Apps and WebJobs",
          "Implementing Mobile Apps",
          "Implementing Traffic Manager",
          "Creating Web Apps",
          "Deploying Web Apps",
          "Managing Web Apps",
          "Implement Traffic Manager"
        ]
      },
      {
        key: "Plan and implement Storage, Backup, and Recovery Services",
        value: [
          "Planning Storage",
          "Implementing and managing Azure Storage",
          "Implementing Azure CDNs",
          "Implementing Azure Backup",
          "Planning and implementing Azure Site Recovery",
          "Planning and implementing Azure Storage",
          "Creating and configuring Azure Storage",
          "Using Azure File storage",
          "Protecting data with Azure Backup"
        ]
      },
      {
        key: "Implementing containers in Azure",
        value: [
          "Implementing Windows and Linux containers in Azure",
          "Implementing Azure Container Service",
          "Implementing containers on Azure VMs",
          "Implementing Windows and Linux containers in Azure",
          "Deploying containers to Azure VMs",
          "Deploying multicontainer applications with Docker",
          "Compose to Azure VMs",
          "Implementing Azure Container Registry"
        ]
      },
      {
        key: "Implementing Azure Cloud Services",
        value: [
          "Plan and deploy Azure Cloud Services",
          "Manage and maintain Azure Cloud Services",
          "Implementation of Azure Cloud Services",
          "Deployment of a cloud service",
          "Configuration of deployment slots and Remote Desktop Protocol (RDP)",
          "Monitoring cloud services"
        ]
      },
      {
        key: "Implementing Azure Active Directory",
        value: [
          "Create and manage Azure AD tenants",
          "Configuration of application and resource access with Azure AD",
          "Overview of Azure AD Premium"
        ]
      },
      {
        key: "Manage Active Directory infrastructure in a hybrid environment",
        value: []
      },
      {
        key: "Implementing Azure-based management and automation",
        value: [
          "Implementing OMS",
          "Implementing Azure Automation",
          "Implementing Automation runbooks",
          "Implementing Azure Automation–based management",
          "Implementing Automation",
          "Configuring Automation accounts",
          "Creating runbooks"
        ]
      },
    ]
  }
]

