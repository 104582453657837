import { Disclosure } from "@headlessui/react";
import React, { useEffect } from "react";
import { faq } from "../../constant/images";
import faqimg from "../../assets/images/all-img/faq-1.png"
import AOS from 'aos';
import 'aos/dist/aos.css';
const Accordion = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <div className="section-padding  bg-white bg-[url('../images/all-img/section-bg-15.png')] bg-bottom  bg-cover bg-no-repeat">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-[30px]">
          <div>
            <div className="mini-title" data-aos="fade-right" data-aos-delay="200">Frequently Asked Question</div>
            <div className="column-title " data-aos="fade-right" data-aos-delay="400">
              General <span className="shape-bg">Questions</span>
            </div>
            <ul className="list accrodains space-y-[30px] lg:max-w-[470px]">
              {/* <Disclosure >
                {({ open }) => (
                  <li className={open ? "open" : null} >
                    <Disclosure.Button type="button" className="accrodain-button">
                      <span>What courses do you offer ?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>
                    <Disclosure.Panel className="content">
                      We offer a variety of courses across different disciplines, including web development, data science, digital marketing, and graphic design. Our courses are designed to cater to both beginners and advanced learners.
                    </Disclosure.Panel>
                  </li>
                )}

              </Disclosure> */}
              <Disclosure>
                {({ open }) => (
                  <li className={open ? "open" : null} data-aos="fade-right" data-aos-delay="600">
                    <Disclosure.Button type="button" className="accrodain-button">
                      <span>How can I enroll in a course ?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      Enrolling in a course is easy! Simply visit our course catalog, select the course you are interested in, and click on the "Show Interest" button. You will be guided through the registration and payment process.
                    </Disclosure.Panel>
                  </li>
                )}

              </Disclosure >
              <Disclosure  >
                {({ open }) => (
                  <li className={open ? "open" : null} data-aos="fade-right" data-aos-delay="800">
                    <Disclosure.Button type="button" className="accrodain-button">
                      <span>Do you offer any certifications ?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      Yes, upon successful completion of our courses, you will receive a certificate of completion. This certificate can be a valuable addition to your resume and can help you in your career advancement.
                    </Disclosure.Panel>
                  </li>
                )}

              </Disclosure>
              <Disclosure >
                {({ open }) => (
                  <li className={open ? "open" : null} data-aos="fade-right" data-aos-delay="1000">
                    <Disclosure.Button type="button" className="accrodain-button">
                      <span>What is the duration of your courses ?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      The duration of our courses varies depending on the subject and level of difficulty. Most of our courses range from 4 to 12 weeks. Detailed information about the duration of each course is available on the course page.
                    </Disclosure.Panel>
                  </li>
                )}

              </Disclosure>
              <Disclosure >
                {({ open }) => (
                  <li className={open ? "open" : null} data-aos="fade-right" data-aos-delay="1200">
                    <Disclosure.Button type="button" className="accrodain-button">
                      <span>What support do you offer to students during the course ?</span>
                      <span className="icon-pm"></span>
                    </Disclosure.Button>

                    <Disclosure.Panel className="content">
                      We provide comprehensive support to our students, including access to course materials, live Q&A sessions, and dedicated forums for discussion. Our instructors are always available to answer your questions and provide guidance throughout the course.
                    </Disclosure.Panel>
                  </li>
                )}

              </Disclosure>


            </ul>
          </div>
          <div data-aos="fade-right" data-aos-delay="400">
            <img src={faqimg} alt="" style={{marginTop:"25%",width:"90%"}} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
