/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import lightImg from "../assets/images/svg/light.svg";
import aboutOne from "../assets/images/banner/image.png";
import targetImg from "../assets/images/svg/target.svg";
import AOS from 'aos';
import 'aos/dist/aos.css';
const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <div className="about-area section-padding">
      <div className="container">
        <div className="grid lg:grid-cols-2 grid-cols-1 gap-10">
          <div data-aos="fade-right" data-aos-delay="200">
            <img src={aboutOne} alt="aboutOne" className=" mx-auto" />
          </div>
          <div>
            {/* <div className="mini-title">About ALCE</div> */}
            <h4 className="column-title " data-aos="fade-up" data-aos-delay="200">
              The Place Where You Can
              <span className="shape-bg">Achieve</span>
            </h4>
            <div data-aos="fade-up" data-aos-delay="300">
            Our courses are designed for ongoing career development and are delivered by leading technical domain experts from around the world. Explore our most popular courses here!
            </div>
            <ul className=" list-item space-y-6 pt-8">
              <li className="flex" data-aos-delay="400" data-aos="fade-up" >
                <div className="flex-none mr-6">
                  <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src={lightImg}
                      alt="lightImg"
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div>
                </div>
                <div className="flex-1" >
                  <h4 className=" text-xl mb-1">Our Mission</h4>
                  <div>
                    Empower students with cutting-edge skills through innovative, expert-led training for career advancement.
                  </div>
                </div>
              </li>
              <li className="flex" data-aos="fade-up" data-aos-delay="500">
                <div className="flex-none mr-6">
                  <div className="h-[72px] w-[72px] rounded-full">
                    <img
                      src={targetImg}
                      alt=""
                      className=" w-full h-full object-cover rounded-full block"
                    />
                  </div>
                </div>
                <div className="flex-1">
                  <h4 className=" text-xl mb-1">Our Vision</h4>
                  <div>
                    Be the global leader in career-focused education, shaping the future through excellence and innovation.
                  </div>
                </div>
              </li>
            </ul>
            {/* <div className="pt-8">
              <a href="#" className=" btn btn-primary">
                Read More Us
              </a>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
