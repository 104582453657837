import React from "react";
import CommonCourseDetails from "../CourseDetails/CourseDetails";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";

const SeleniumTesting = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Selenium Testing Course Details"} />
      {/* <CoreJavaCourseDetails /> */}
      <CommonCourseDetails courseId="Selenium" />
      <Footer />
    </>
  );
};

export default SeleniumTesting;
