import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import CCPPCourseDetails from "../CourseDetails/CCPPCourseDetails";
import CommonCourseDetails from "../CourseDetails/CourseDetails";

const CAndCPP = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Course Details"} />
      <CommonCourseDetails courseId="ccpp" />
      <Footer />
    </>
  );
};

export default CAndCPP;
