import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import PythonCourseDetails from "../CourseDetails/PythonCourseDetails";
import CommonCourseDetails from "../CourseDetails/CourseDetails";

const Python = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Course Details"} />
      <CommonCourseDetails courseId="python" />
      <Footer />
    </>
  );
};

export default Python;
