import React, { useEffect, useState } from "react";
import mailWhite from "../assets/images/svg/mail-white.svg";
import bigMail from "../assets/images/svg/big-mail.svg";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';
const NewsLetter = () => {
  const [email, setEmail] = useState('');
  const [error, setError] = useState('');

  const validateEmail = (email) => {
    return /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email);
  };

  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 

  const handleSubscribeNewLetter = async () => {
    if (!validateEmail(email)) {
      setError('Invalid email address');
      return;
    }
  
    setError('');
  
    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);
      
      const formData = new FormData();
      formData.append('Email', email);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'NewLetter'); // Replace 'YourSheetName' with your actual sheet name
  
      toast.info('Submitting...');
      const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
      if (response.status === 200) {
        toast.success('Subscription successful');
        setEmail('');
      }
    } catch (error) {
      toast.error('Failed to subscribe');
    }
  };
  
  return (
    <>
      <div className="bg-[url('../images/all-img/section-bg-3.png')] bg-cover bg-no-repeat bg-center py-20">
        <div className="container">
          <div className="lg:flex lg:items-center lg:space-x-[70px]">
            <div className=" flex-none">
              <div className="flex items-center space-x-8 text-white max-w-[327px] mx-auto lg:mb-0 md:mb-10 mb-5">
                <div className=" flex-none " data-aos="fade-left" data-aos-delay="200">
                  <img src={bigMail} alt="" />
                </div>
                <div className="flex-1 lg:text-[33px] lg:leading-[51px] md:text-3xl text-2xl font-bold" data-aos="fade-left" data-aos-delay="300">
                  Subscribe to My Newsletter
                </div>
              </div>
            </div>
            <div className=" flex-1">
              <div className="md:flex   items-center  relative md:space-x-6 md:space-y-0  space-y-4   ">
                <div className="flex-1 relative" data-aos="fade-left" data-aos-delay="500">
                  <span className=" absolute left-0 top-1/2 -translate-y-1/2 ">
                    <img src={mailWhite} alt="" />
                  </span>
                  <input
                    type="email"
                    placeholder="Enter your mail address"
                    className=" border-b border-t-0 border-l-0 border-r-0 border-[#B2E2DF] ring-0 focus:ring-0 bg-transparent text-white
                                placeholder:text-[#B2E2DF] focus:outline-0 focus:border-[#B2E2DF] transition pl-8 text-lg"
                    value={email}
                    onChange={(e) => {
                      setEmail(e.target.value);
                      setError('');
                    }}
                  />
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                </div>
                <div className="flex-none" data-aos="fade-left" data-aos-delay="700">
                  <button className="btn bg-white  text-secondary md:w-auto w-full" onClick={handleSubscribeNewLetter}>
                    Get Started Now
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: '300px', // Adjust the width as needed
          bottom: '30px', // Adjust the distance from the bottom
          right: '30px', // Adjust the distance from the right
          zIndex: 9999 // Ensure it appears above other elements
        }}
      />
    </>

  );
};

export default NewsLetter;
