import React, { useEffect } from "react";
import { call, mail, map } from "../../constant/images";
import ContactForm from "../ContactForm";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import AOS from 'aos';
import 'aos/dist/aos.css';
import { Helmet } from "react-helmet";
const ContactUs = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <>
      <Helmet>
        <title>ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses</title>
	      <meta name="description" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
        <meta name="keyborad" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
      </Helmet>
      <Header />
      <PageBanner title={"Contact Us"} pageName="Contact" />
      <div class="nav-tab-wrapper tabs  section-padding">
        <div class="container">
          <div class=" grid grid-cols-12 gap-[30px]">
            <div class="xl:col-span-5 lg:col-span-6 col-span-12 ">
              <div class="mini-title" data-aos="fade-right" data-aos-delay="200">Contact Us</div>
              <h4 class="column-title" data-aos="fade-right" data-aos-delay="400">
                Get In Touch <span class="shape-bg">Today</span>
              </h4>
              <div data-aos="fade-right" data-aos-delay="600">
                We’d love to hear from you! Whether you have a question about our services, need support, or just want to say hello, our team is here to assist you. 
              </div>
              <ul class=" list-item space-y-6 pt-8">
                <li class="flex" data-aos="fade-right" data-aos-delay="800">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={mail} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class="lg:text-xl text-lg mb-1">Email-Us :</h4>
                    <a href="mailto:info@alce.co.in">info@alce.co.in</a>
                  </div>
                </li>
                <li class="flex" data-aos="fade-right" data-aos-delay="1000">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={call} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class=" lg:text-xl text-lg mb-1">Call Us:</h4>
                    <div>+91 +91 6369 964 058</div>
                  </div>
                </li>
                <li class="flex" data-aos="fade-right" data-aos-delay="1200">
                  <div class="flex-none mr-6">
                    <div class="">
                      <img src={map} alt="" class="" />
                    </div>
                  </div>
                  <div class="flex-1">
                    <h4 class="lg:text-xl text-lg mb-1">Office :</h4>
                    <div>90A/7, near Gowri Krishna Hotel, Arul Nagar, State Bank Supervisors Colony, Madurai, Tamil Nadu 625016</div>
                  </div>
                </li>
              </ul>
            </div>
            <div class="xl:col-span-7 lg:col-span-6 col-span-12">
              <div class="bg-white shadow-box7 p-8 rounded-md">
                <ContactForm />
              </div>
            </div>
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
};

export default ContactUs;
