import React from "react";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import Accordion from "../HomeThreeComponents/Accordion";
import Team from "../HomeThreeComponents/Team";
import About from "../HomeTwoComponents/About";
import Counter from "../HomeTwoComponents/Counter";
import PageBanner from "../PageBanner";
import Testimonials from "../Testimonials";
import { Helmet } from "react-helmet";

const PrivacyPolicy = () => {
  const today = new Date().toLocaleDateString();

  return (
    <>
      <Helmet>
        <title>ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses</title>
        <meta name="description" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
        <meta name="keywords" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
      </Helmet>
      <Header />
      <div style={{padding:"10%"}}>
        <h4>Privacy Policy</h4>
        <p><strong>Effective Date:</strong> {today}</p>
        <h5>1. Introduction</h5>
        <p>
          Welcome to ALCE (Academy of Learn Compile Execute). We value your privacy and are committed to protecting your personal information. 
          This privacy policy outlines how we collect, use, and safeguard your data when you visit our website, <a href="https://alce.co.in">https://alce.co.in</a>.
        </p>
        <h5>2. Data Collection</h5>
        <p>
          We collect personal information that you provide to us when you make an enquiry through our website. This information may include, 
          but is not limited to, your name, email address, phone number, and any other details you choose to share.
        </p>
        <h5>3. Use of Data</h5>
        <p>
          The data collected will be used solely by the ALCE team to respond to your enquiries and provide you with the information or services you have requested. 
          We ensure that your personal information is used only for the purposes for which it was collected.
        </p>
        <h5>4. Data Sharing</h5>
        <p>
          We respect your privacy and will not share, sell, or rent your personal information to any third parties. Your data will only be accessible to 
          authorized ALCE team members who require it to fulfill your enquiry.
        </p>
        <h5>5. Data Protection</h5>
        <p>
          We implement appropriate technical and organizational measures to protect your personal information from unauthorized access, alteration, disclosure, or destruction. 
          We ensure that our data processing practices comply with applicable data protection laws and regulations.
        </p>
        <h5>6. Your Rights</h5>
        <p>
          You have the right to access, correct, or delete your personal information that we hold. If you wish to exercise these rights or have any questions about our privacy practices, 
          please contact us at <a href="mailto:enquiry@alce.co.in">enquiry@alce.co.in</a>.
        </p>
        <h5>7. Changes to This Privacy Policy</h5>
        <p>
          We may update this privacy policy from time to time. Any changes will be posted on this page, and we will notify you of any significant changes by updating the effective date at the top of this policy.
        </p>
        <h5>8. Contact Us</h5>
        <p>
          If you have any questions or concerns about this privacy policy or our data practices, please contact us at:
        </p>
        <p>
          ALCE (Academy of Learn Compile Execute) <br />
          Email: <a href="mailto:enquiry@alce.co.in">enquiry@alce.co.in</a> <br />
          Phone: 6369964058 <br />
          Address: Madura Complex 107, Opp to Maybell, Ellis Nagar, Madurai - 625016
        </p>
      </div>
      <Footer />
    </>
  );
};

export default PrivacyPolicy;
