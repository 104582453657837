import React from "react";
import CommonCourseDetails from "../CourseDetails/CourseDetails";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";

const GoogleCloud = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Google Cloud Course Details"} />
      {/* <CoreJavaCourseDetails /> */}
      <CommonCourseDetails courseId="GoogleCloud" />
      <Footer />
    </>
  );
};

export default GoogleCloud;
