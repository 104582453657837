import React, { useState, useEffect } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ForceShowInterestModal() {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [mobile, setMobile] = useState('');
  const [errors, setErrors] = useState({});
  const [isVisible, setIsVisible] = useState(false);
  const [isFirstTime, setIsFirstTime] = useState(true);

  useEffect(() => {
    if(!localStorage.getItem("ShowInterestForce") && window.location.href.includes("courses")){
      const firstTimer = setTimeout(() => {
        setIsVisible(true);
        return () => clearTimeout(firstTimer);
      }, 15000);
    }    
    if(localStorage.getItem("Name") && window.location.href.includes("courses") && !window.location.href.includes("localhost")){
      createLogs();
    }
  }, []);

  const createLogs = async () =>{
    const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
    const istDateTime = new Date().toLocaleString('en-IN', istOptions);
    const formData = new FormData();
    formData.append('Name', localStorage.getItem("Name"));
    formData.append('Email', localStorage.getItem("Email"));
    formData.append('MobileNumber', localStorage.getItem("MobileNumber"));
    formData.append('URL', window.location.href);
    formData.append('sheetName', 'TrackLog');
    formData.append('DateTime', istDateTime);
    const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
  }
  useEffect(() => {
    document.body.style.overflow = isVisible ? 'hidden' : 'auto';
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isVisible]);

  const validate = () => {
    let tempErrors = {};
    let isValid = true;

    if (!name) {
      tempErrors['name'] = 'Name is required';
      isValid = false;
    }

    if (mobile) {
      const mobilePattern = /^[0-9]{10}$/;
      if (!mobilePattern.test(mobile)) {
        tempErrors['mobile'] = 'Mobile number must be 10 digits';
        isValid = false;
      }
    } else {
      tempErrors['mobile'] = 'Mobile number is required';
      isValid = false;
    }

    if (email) {
      const emailPattern = /^[a-zA-Z0-9+_.-]+@[a-zA-Z0-9.-]+$/;
      if (!emailPattern.test(email)) {
        tempErrors['email'] = 'Invalid email format';
        isValid = false;
      }
    }

    setErrors(tempErrors);
    return isValid;
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (validate()) {
      try {
        const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
        const istDateTime = new Date().toLocaleString('en-IN', istOptions);
        const formData = new FormData();
        formData.append('Name', name);
        formData.append('Email', email);
        formData.append('MobileNumber', mobile);
        formData.append('sheetName', 'ShowInterestForce');
        formData.append('DateTime', istDateTime);
        localStorage.setItem("Name", name);
        localStorage.setItem("Email", email);
        localStorage.setItem("MobileNumber", mobile);
        const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
        if (response.status === 200) {
          toast.success('Your Interest Submitted Successfully');
          localStorage.setItem("ShowInterestForce",true);
        }
      } catch (error) {
        toast.error('Failed to submit form');
      }
    }
  };

  const handleClose = () => {
    setIsVisible(false);
    setIsFirstTime(false);
    setTimeout(() => {
      setIsVisible(true);
    }, 30000);
  };

  return (
    <>
      {isVisible && (
        <div style={styles.modal}>
          <div style={styles.modalContent}> 
            {isFirstTime && (
              <span style={styles.close} onClick={handleClose}>&times;</span>
            )}
            <form onSubmit={handleSubmit}>
              <div style={styles.formGroup}>
                <label style={styles.label}>Name:</label>
                <input
                  type="text"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  style={styles.input}
                />
                {errors.name && <p style={styles.error}>{errors.name}</p>}
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label}>Email (Optional):</label>
                <input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  style={styles.input}
                />
                {errors.email && <p style={styles.error}>{errors.email}</p>}
              </div>
              <div style={styles.formGroup}>
                <label style={styles.label}>Mobile Number:</label>
                <input
                  type="text"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                  style={styles.input}
                />
                {errors.mobile && <p style={styles.error}>{errors.mobile}</p>}
              </div>
              <button type="submit" className="btn btn-primary py-[15px] px-8">
                Submit
              </button>
            </form>
          </div>
        </div>
      )}
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: '300px',
          bottom: '30px',
          right: '30px',
          zIndex: 9999
        }}
      />
    </>
  );
}

const styles = {
  modal: {
    display: 'block',
    position: 'fixed',
    zIndex: 9999,
    left: 0,
    top: 0,
    width: '100%',
    height: '100%',
    overflow: 'auto',
    backgroundColor: 'rgba(0,0,0,0.4)',
    justifyContent: 'center',
    alignItems: 'center'
  },
  modalContent: {
    backgroundColor: '#fff',
    margin: '10% auto',
    padding: '20px',
    border: '1px solid #888',
    width: '400px',
    borderRadius: '10px',
    textAlign: 'center',
    boxShadow: '0 5px 15px rgba(0,0,0,0.3)'
  },
  close: {
    color: '#aaa',
    float: 'right',
    fontSize: '28px',
    fontWeight: 'bold',
    cursor: 'pointer',
    marginTop: '-10px',
    marginRight: '-10px'
  },
  formGroup: {
    marginBottom: '15px',
    textAlign: 'left'
  },
  label: {
    display: 'block',
    marginBottom: '5px',
    fontWeight: 'bold',
    color: '#333'
  },
  input: {
    width: '100%',
    padding: '10px',
    boxSizing: 'border-box',
    borderRadius: '5px',
    border: '1px solid #ddd',
    fontSize: '14px'
  },
  error: {
    color: 'red',
    fontSize: '12px',
    marginTop: '5px'
  }
};

export default ForceShowInterestModal;
