import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import CoreJavaCourseDetails from "../CourseDetails/CoreJavaCourseDetails";
import CommonCourseDetails from "../CourseDetails/CourseDetails";

const MEANStack = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"MEAN Stack Course Details"} />
      {/* <CoreJavaCourseDetails /> */}
      <CommonCourseDetails courseId="MEANStack" />
      <Footer />
    </>
  );
};

export default MEANStack;
