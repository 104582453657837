import { BrowserRouter, HashRouter, Route, Routes } from "react-router-dom";

import HomeOne from "./Components/Home/HomeOne";
import ErrorPage from "./Components/Pages/404Page";
import AWS from "./Components/Pages/AWS";
import AboutOne from "./Components/Pages/AboutOne";
import Android from "./Components/Pages/Android";
import Angular from "./Components/Pages/Angular";
import Appium from "./Components/Pages/Appium";
import CAndCPP from "./Components/Pages/CAndCPP";
import CloudComputing from "./Components/Pages/CloudComputing";
import ContactUs from "./Components/Pages/ContactUs";
import CoreJava from "./Components/Pages/CoreJava";
import CourseSideBar from "./Components/Pages/CourseSideBar";
import Dotnet from "./Components/Pages/Dotnet";
import Flutter from "./Components/Pages/Flutter";
import FullStack from "./Components/Pages/FullStack";
import GoogleCloud from "./Components/Pages/GoogleCloud";
import Hibernate from "./Components/Pages/Hibernate";
import MEANStack from "./Components/Pages/MEANStack";
import MERNStack from "./Components/Pages/MERNStack";
import MicrosoftAzure from "./Components/Pages/MicrosoftAzure";
import NextJS from "./Components/Pages/NextJS";
import NodeJS from "./Components/Pages/NodeJS";
import OracleTraining from "./Components/Pages/OracleTraining";
import PHPCourse from "./Components/Pages/PHP";
import Python from "./Components/Pages/Python";
import ReactJS from "./Components/Pages/ReactJS";
import ReactNative from "./Components/Pages/ReactNative";
import SeleniumTesting from "./Components/Pages/SeleniumTesting";
import SoftwareTesting from "./Components/Pages/SoftwareTesting";
import Spring from "./Components/Pages/Spring";
import Thanks from "./Components/Pages/Thanks";
import VueJS from "./Components/Pages/VueJS";
import WebDesigning from "./Components/Pages/WebDesigning";
import PrivacyPolicy from "./Components/Pages/PrivacyPolicy";

function App() {
  const courses = [
    { path: "c-and-cpp", element: <CAndCPP /> },
    { path: "python", element: <Python /> },
    { path: "dot-net", element: <Dotnet /> },
    { path: "core-java", element: <CoreJava /> },
    { path: "hibernate", element: <Hibernate /> },
    { path: "spring", element: <Spring /> },
    { path: "web-designing", element: <WebDesigning /> },
    { path: "php", element: <PHPCourse /> },
    { path: "reactjs", element: <ReactJS /> },
    { path: "angularjs", element: <Angular /> },
    { path: "nodejs", element: <NodeJS /> },
    { path: "full-stack-developer", element: <FullStack /> },
    { path: "mern-stack-developer", element: <MERNStack /> },
    { path: "mean-stack-developer", element: <MEANStack /> },
    { path: "nextjs", element: <NextJS /> },
    { path: "vuejs", element: <VueJS /> },
    { path: "oracle", element: <OracleTraining /> },
    { path: "android", element: <Android /> },
    { path: "flutter", element: <Flutter /> },
    { path: "react-native", element: <ReactNative /> },
    { path: "software-testing", element: <SoftwareTesting /> },
    { path: "selenium", element: <SeleniumTesting /> },
    { path: "appium", element: <Appium /> },
    { path: "aws", element: <AWS /> },
    { path: "google-cloud", element: <GoogleCloud /> },
    { path: "microsoft-azure", element: <MicrosoftAzure /> },
  ];

  return (
    <HashRouter>
      <div className="font-gilroy font-medium text-gray text-lg leading-[27px]">
        <Routes>
          <Route path="/" element={<HomeOne />} />
          <Route path="/about-us" element={<AboutOne />} />
          <Route path="/courses" element={<CourseSideBar />} />
          {courses.map(course => (
            <>
              <Route path={`courses/${course.path}-training-in-madurai`} element={course.element} />
              <Route path={`courses/${course.path}-training-near-me`} element={course.element} />
              <Route path={`courses/${course.path}-training-in-online`} element={course.element} />
              <Route path={`courses/${course.path}-training-in-offline`} element={course.element} />
              <Route path={`courses/${course.path}-best-training`} element={course.element} />
            </>
          ))}
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy />} />
          <Route path="/react-templates/edumim/thanks" element={<Thanks />} />
          <Route path="*" element={<ErrorPage />} />
        </Routes>
      </div>
    </HashRouter>
  );
}

export default App;
