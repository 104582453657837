/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from "react";
import mailIcon from "../assets/images/svg/call.svg";
import ManOne from "../assets/images/banner/man1.png";
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import AOS from 'aos';
import 'aos/dist/aos.css';

const Banner = () => { 
  const [mobile, setMobile] = useState('');
  const [error, setError] = useState('');

  const validateMobile = (mobile) => {
    return /^[0-9]{10}$/.test(mobile);
  };
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 

  const handleSubscribeBanner = async () => {
    if (!validateMobile(mobile)) {
      setError('Invalid Mobile Number');
      return;
    }

    setError('');
    

    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);

      const formData = new FormData();
      localStorage.setItem("MobileNumber",mobile)
      formData.append('MobileNumber', mobile);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'GetStarted'); // Replace 'YourSheetName' with your actual sheet name
      toast.info('Submitting...');
      const response = await axios.post('https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec', formData);
      if (response.status === 200) {
        toast.success('Subscription successful');
        setMobile('')
      }
    } catch (error) {
      toast.error('Failed to subscribe');
    }
  };
  return (
    <>
      <section
        className={`xl:min-h-screen bg-[url('../images/banner/1.png')] bg-cover bg-no-repeat bg-center overflow-hidden`}
      >
        <div className="container relative">
          <div className="max-w-[570px] xl:pt-[297px] md:pt-20 md:pb-20 pt-28 pb-14 xl:pb-40 space-y-8">
            <h2 data-aos="fade-up" data-aos-delay="200">
              Get Trained from{" "} 
              <span className=" text-secondary inline-block bg-[url('../images/banner/shape.svg')]  bg-no-repeat bg-bottom">
                Industry Experts
              </span>{" "}
              through online or classroom sessions
            </h2>
            <div className=" plain-text text-gray leading-[30px]" data-aos="fade-up" data-aos-delay="300">
              A choice that makes a significant impact on your career.
            </div>
            <div className="bg-white md:flex  rounded-md shadow-e1 items-center py-[10px] relative lg:pr-[10px] lg:pl-[38px] pl-4 pr-4" data-aos="fade-up" data-aos-delay="400">
              <div className="flex-1 flex items-center lg:mb-0 mb-2">
                <div className="flex-none">
                  <span className=" ">
                    <img src={mailIcon} alt="mainIcon" />
                  </span>
                </div>
                <div className="flex-1">
                  <input
                    type="tel"
                    placeholder="Enter your mobile number"
                    className="border-none focus:ring-0"
                    value={mobile}
                    onChange={(e) => {
                      setMobile(e.target.value);
                      setError('');
                    }}
                  />
                  {error && <p className="text-red-500 text-sm">{error}</p>}
                </div>
              </div>
              <div className="flex-none">
                <button className="btn btn-primary w-full lg:w-auto" onClick={handleSubscribeBanner}>
                  Get Started Now
                </button>
              </div>
            </div>
          </div>
          <div className="imge-box absolute xl:right-[-260px]  hidden xl:block   bottom-0  " data-aos="fade-right" data-aos-delay="300">
            {/* <img src={ManOne} alt="manOne" /> */}
          </div>
        </div>
      </section>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: '300px', // Adjust the width as needed
          bottom: '30px', // Adjust the distance from the bottom
          right: '30px', // Adjust the distance from the right
          zIndex: 9999 // Ensure it appears above other elements
        }}
      />
    </>
  );
};

export default Banner;
