import React from "react";
import CommonCourseDetails from "../CourseDetails/CourseDetails";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";

const Android = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Android Course Details"} />
      {/* <CoreJavaCourseDetails /> */}
      <CommonCourseDetails courseId="Android" />
      <Footer />
    </>
  );
};

export default Android;
