import React from "react";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";
import DotnetCourseDetails from "../CourseDetails/DotnetCourseDetails";
import CommonCourseDetails from "../CourseDetails/CourseDetails";

const Dotnet = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"Course Details"} />
      <CommonCourseDetails courseId="dotnet" />
      <Footer />
    </>
  );
};

export default Dotnet;
