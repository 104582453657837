import React from "react";
import Feature from "../Feature";
import Footer from "../Footer";
import Header from "../Header";
import Accordion from "../HomeThreeComponents/Accordion";
import Team from "../HomeThreeComponents/Team";
import About from "../HomeTwoComponents/About";
import Counter from "../HomeTwoComponents/Counter";
import PageBanner from "../PageBanner";
import Testimonials from "../Testimonials";
import { Helmet } from "react-helmet";

const AboutOne = () => {
  return (
    <>
      <Helmet>
        <title>ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses</title>
	      <meta name="description" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
        <meta name="keyborad" content="ALCE | AWS, Python, Java, Software Testing, Selenium, Data Science, Full Stack, DevOps, Ethical Hacking, Digital Marketing, Salesforce, RPA, AngularJS, React Training in Classroom and Online Training Courses" />
      </Helmet>
      <Header />
      <PageBanner title={"About Us"} pageTitle="About Us"  />
      <About />
      <Feature />
      {/* <Counter /> */}
      {/* <Testimonials /> */}
      {/* <Team /> */}
      <Accordion />
      <Footer />
    </>
  );
};

export default AboutOne;
