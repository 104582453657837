/* eslint-disable jsx-a11y/anchor-is-valid */
import { Disclosure, Tab } from "@headlessui/react";
import axios from "axios";
import React, { useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import { courseDetailsInfo } from "../../constant/dummyData";
import {
  ck,
  clockIcon,
  file2,
  fileIcon,
  target,
  web
} from "../../constant/images";

const CommonCourseDetails = ({ courseId }) => {


  const [courseDetailData, setCourseDetail] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [currentItem, setCurrentItem] = useState(null);
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState({});

  const fetchCourseDetails = (courseDetailsInfo) => {
    const filteredCourses = courseDetailsInfo.filter(course => course.id === courseId);
    return Array.isArray(filteredCourses) ? filteredCourses[0] : filteredCourses;
  }

  useEffect(() => {
    const fetchData = async () => {
      const temp = fetchCourseDetails(courseDetailsInfo);
      setCourseDetail(temp);
    };
    fetchData();
  }, [courseDetailsInfo]);


  useEffect(() => {
    if (isModalOpen) {
      document.body.style.overflow = 'hidden';
    } else {
      document.body.style.overflow = 'auto';
    }

    // Clean up when the component unmounts
    return () => {
      document.body.style.overflow = 'auto';
    };
  }, [isModalOpen]);

  const openModal = () => {
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setIsModalOpen(false);
    setName("");
    setMobile("");
    setEmail("");
    setErrors({});
  };

  const validateForm = () => {
    let errors = {};
    if (!name.trim()) {
      errors.name = "Name is required";
    }
    if (!email.trim()) {
      errors.email = "Email is required";
    } else if (!/\S+@\S+\.\S+/.test(email)) {
      errors.email = "Email is invalid";
    }
    if (!mobile.trim()) {
      errors.mobile = "Mobile Number is required";
    } else if (!/^[0-9]{10}$/.test(mobile)) {
      errors.mobile = "Mobile Number is invalid";
    }
    return errors;
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    const errors = validateForm();
    if (Object.keys(errors).length > 0) {
      setErrors(errors);
      return;
    }

    setLoading(true);

    try {
      const istOptions = { timeZone: 'Asia/Kolkata', hour12: false };
      const istDateTime = new Date().toLocaleString('en-IN', istOptions);
      const formData = new FormData();
      formData.append("Name", name);
      formData.append("Email", email);
      formData.append("MobileNumber", mobile);
      formData.append("CourseTitle", courseDetailData?.title);
      formData.append('DateTime', istDateTime);
      formData.append('sheetName', 'Enquiry'); // Replace 'YourSheetName' with your actual sheet name
      localStorage.setItem("Name", name)
      localStorage.setItem("Email", email)
      localStorage.setItem("MobileNumber", mobile)
      const response = await axios.post("https://script.google.com/macros/s/AKfycbwvmrXs-vh5Xl8QBUuzwoSIitE3NA5jA8qNkRKymUbY8oZk69Chu3FY0fDiR3Iv9EH9/exec", formData);
      if (response.status === 200) {
        toast.success("Enquiry submitted successfully");
        closeModal();
      }
    } catch (error) {
      toast.error("Failed to submit enquiry");
    } finally {
      setLoading(false);
    }
  };
  return (
    <>
      <div className="nav-tab-wrapper tabs  section-padding">
        <Helmet>
          <title>{courseDetailData?.title}</title>
          <meta name="description" content={courseDetailData?.description} />
          <meta name="keyborad" content={courseDetailData?.keyborad} />
        </Helmet>
        <div className="container">
          <div className="grid grid-cols-12 gap-[30px]">
            <div className="lg:col-span-8 col-span-12">
              <div className="single-course-details">
                <div className=" mb-6">
                  <span className="bg-secondary py-1 px-3 text-lg font-semibold rounded text-white ">
                    {courseDetailData?.post}
                  </span>
                </div>
                <h2>{courseDetailData?.title}</h2>
                <Tab.Group>
                  <div className="nav-tab-wrapper mt-12">
                    <Tab.List as="ul" id="tabs-nav" className="course-tab mb-8">
                      {["Overview", "Curriculum", "Approach", "FAQ"].map(
                        (item, index) => (
                          <Tab
                            as="li"
                            key={index}
                            className={({ selected }) =>
                              selected ? "active" : null
                            }
                          >
                            <a style={{ cursor: "pointer" }}>{item}</a>
                          </Tab>
                        )
                      )}
                    </Tab.List>
                    <Tab.Panels id="tabs-content">
                      <Tab.Panel id="tab1" className="tab-content">
                        <div>
                          <h3 className=" text-2xl">Course Description</h3>
                          <p className="mt-4">
                            <div dangerouslySetInnerHTML={{ __html: courseDetailData?.overview }} />
                          </p>
                          <div className="bg-[#F8F8F8] space-y-6 p-8 rounded-md my-8">
                            <h4 className=" text-2xl">{courseDetailData?.overviewTitle}</h4>
                            <ul className=" grid sm:grid-cols-2 grid-cols-1 gap-6">
                              {
                                courseDetailData.why && courseDetailData.why?.map((data) => {
                                  return (
                                    <li className=" flex space-x-3">
                                      <div className="flex-none  relative top-1 ">
                                        <img src={ck} alt="" />
                                      </div>
                                      <div className="flex-1">
                                        {data.value}
                                      </div>
                                    </li>
                                  )
                                })
                              }

                            </ul>
                          </div>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel id="tab2" className="tab-content">
                        <div>
                          <h3 className=" text-2xl">Course Description</h3>
                          <div className="md:flex md:space-x-10  space-x-3 flex-wrap mt-4 mb-6">
                            <span><b>Level</b> {courseDetailData?.level}</span>
                            <span><b>Topics</b> {courseDetailData?.lectures}</span>
                            <span><b>Total</b> {courseDetailData?.duration}</span>
                          </div>
                          <ul className="list  course-accrodain space-y-6">
                            {
                              courseDetailData && courseDetailData.curriculum && courseDetailData.curriculum.map((data) => {
                                return (
                                  <Disclosure as="li">
                                    {({ open }) => (

                                      <li className={open ? "open" : ""}>
                                        <Disclosure.Button
                                          as="button"
                                          className="accrodain-button"
                                        >
                                          <span className="icon-pm fle x-none"></span>
                                          {/* <span className=" flex-1">{data.key}</span> */}
                                          <span className=" flex-1"><div dangerouslySetInnerHTML={{ __html: data.key }} /></span>
                                        </Disclosure.Button>
                                        {
                                          data.value.map((val) => {
                                            return (
                                              <Disclosure.Panel>
                                                <div className="content">
                                                  <div className=" mt-0 ">
                                                    <div className="flex-1 flex">
                                                      <span className="flex-none  mr-2">
                                                        <img src={fileIcon} alt="" />
                                                      </span>
                                                      <span className="flex-1">
                                                        {/* {val} */}
                                                        <div dangerouslySetInnerHTML={{ __html: val }} />
                                                      </span>
                                                    </div>
                                                  </div>
                                                </div>
                                              </Disclosure.Panel>
                                            )
                                          })
                                        }

                                      </li>
                                    )}
                                  </Disclosure>
                                )
                              })
                            }

                          </ul>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel id="tab3" className="tab-content">
                        <div className=" bg-[#F8F8F8] rounded-md p-8">
                          <p>
                            <div dangerouslySetInnerHTML={{ __html: courseDetailData?.instructor }} />
                          </p>
                        </div>
                      </Tab.Panel>
                      <Tab.Panel as="div" id="tab4" className="tab-content">

                        <div className=" bg-[#F8F8F8] rounded-md p-8">
                          <ul className="list accrodains space-y-[30px]">
                            {
                              courseDetailData.faq && courseDetailData.faq.map((data) => {
                                return (
                                  <Disclosure >
                                    {({ open }) => (
                                      <li className={open ? "open" : null} >
                                        <Disclosure.Button type="button" className="accrodain-button">
                                          <span>{data.key} </span>
                                          <span className="icon-pm"></span>
                                        </Disclosure.Button>
                                        <Disclosure.Panel className="content">
                                          {/* {data.value} */}
                                          <div dangerouslySetInnerHTML={{ __html: data.value }} />
                                        </Disclosure.Panel>
                                      </li>
                                    )}
                                  </Disclosure>
                                )
                              })
                            }
                          </ul>
                        </div>
                      </Tab.Panel>
                    </Tab.Panels>
                  </div>
                </Tab.Group>
              </div>
            </div>
            <div className="lg:col-span-4 col-span-12">
              <div className="sidebarWrapper space-y-[30px]">
                <div className="wdiget custom-text space-y-5 ">
                  <button className="btn-primary btn"
                    onClick={() => openModal()}>Enquiry Now</button>
                  <ul className="list  ">
                    <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                      <div className="flex-1 space-x-3 flex">
                        <img src={file2} alt="" />
                        <div className=" text-black font-semibold">Topics</div>
                      </div>
                      <div className="flex-none">{courseDetailData?.lectures}</div>
                    </li>

                    <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                      <div className="flex-1 space-x-3 flex">
                        <img src={clockIcon} alt="" />
                        <div className=" text-black font-semibold">Duration</div>
                      </div>
                      <div className="flex-none">{courseDetailData?.duration}</div>
                    </li>
                    <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                      <div className="flex-1 space-x-3 flex">
                        <img src={target} alt="" />
                        <div className=" text-black font-semibold">
                          Course level
                        </div>
                      </div>
                      <div className="flex-none">{courseDetailData?.level}</div>
                    </li>

                    <li className=" flex space-x-3 border-b border-[#ECECEC] mb-4 pb-4 last:pb-0 past:mb-0 last:border-0">
                      <div className="flex-1 space-x-3 flex">
                        <img src={web} alt="" />
                        <div className=" text-black font-semibold">Language</div>
                      </div>
                      <div className="flex-none">{courseDetailData?.communication}</div>
                    </li>
                  </ul>
                </div>

                <div className="wdiget">
                  <h4 className=" widget-title">Related Courses</h4>
                  <ul className="list">
                    {
                      courseDetailsInfo?.filter(course => course.post === courseDetailData?.post && course.id === courseId).map((course) => {
                        return (
                          <Link
                            className=" bg-white shadow-box2 rounded-[8px] transition duration-100 hover:shadow-sm"
                            to={course.route}>
                            <li className=" flex space-x-4 border-[#ECECEC] pb-6 mb-6 last:pb-0 last:mb-0 last:border-0 border-b">
                              <div className="flex-none ">
                                <div className="h-20 w-20  rounded">
                                  <img
                                    src={course.img}
                                    alt={course.route}
                                    // className=" w-full h-full object-cover rounded"
                                    className=" w-full h-full object-cover rounded"
                                  />
                                </div>
                              </div>
                              <div className="flex-1 ">
                                {/* <div className="flex space-x-3 mb-2">
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class="text-tertiary"
                                  ></iconify-icon>
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class="text-tertiary"
                                  ></iconify-icon>
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class="text-tertiary"
                                  ></iconify-icon>
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class="text-tertiary"
                                  ></iconify-icon>
                                  <iconify-icon
                                    icon="heroicons:star-20-solid"
                                    class="text-tertiary"
                                  ></iconify-icon>
                                </div> */}
                                <div className="mb-1 font-semibold text-black">
                                  {course.title}
                                </div>
                                <span className="font-semibold">
                                  ₹ <span style={{ color: "red", textDecoration: "line-through" }}>{course.mprice}</span> <span className="text-secondary">{course.oprice}</span>
                                </span>
                              </div>
                            </li>
                          </Link>
                        )
                      })
                    }

                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer
        position="bottom-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        toastStyle={{
          width: "300px", // Adjust the width as needed
          bottom: "30px", // Adjust the distance from the bottom
          right: "30px", // Adjust the distance from the right
          zIndex: 9999, // Ensure it appears above other elements
        }}
      />
      {isModalOpen && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50" style={{ marginTop: "100px" }}>
          <div className="bg-white p-8 rounded-lg shadow-lg w-full max-w-md">
            <form onSubmit={handleFormSubmit}>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Name
                </label>
                <input
                  type="text"
                  className="form-control"
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                />
                {errors.name && (
                  <p className="text-red-500 text-sm">{errors.name}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Mobile Number
                </label>
                <input
                  type="tel"
                  className="form-control"
                  value={mobile}
                  onChange={(e) => setMobile(e.target.value)}
                />
                {errors.mobile && (
                  <p className="text-red-500 text-sm">{errors.mobile}</p>
                )}
              </div>
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">
                  Email
                </label>
                <input
                  type="email"
                  className="form-control"
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                />
                {errors.email && (
                  <p className="text-red-500 text-sm">{errors.email}</p>
                )}
              </div>
              <div className="mb-4">
                {/* <label className="block text-sm font-medium text-gray-700" >
                    Course Title
                  </label> */}
                <input
                  type="hidden"
                  className="form-control"
                  value={currentItem?.title || ""}
                  readOnly
                />
              </div>
              <button
                type="submit"
                className="btn-primary btn w-full"
                disabled={loading}
              >
                {loading ? "Sending..." : "Submit"}
              </button>
            </form>
            <button className="mt-4 text-red-500" onClick={closeModal}>
              Cancel
            </button>
          </div>
        </div>
      )}
      <CheatGoogle />
    </>
  );
};

const CheatGoogle = () => {
  const url = window.location.href;
  // Extract the part after the last slash
  const courseSegment = url.split('/').pop();
  // Replace hyphens with spaces
  const formattedCourse = courseSegment.replace(/-/g, ' ');
  // Extract "Core Java" from the formatted string
  const courseTitle = formattedCourse.split(' ').slice(0, 2).map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ');

  // Generate an array of JSX elements
  const renderHeadings = Array.from({ length: 30 }).map((_, index) => (
    <React.Fragment key={index}>
      <h1 hidden>{courseTitle} Training in Madurai</h1>
      <h1 hidden>{courseTitle} Training in Online</h1>
      <h1 hidden>{courseTitle} Training in Offline</h1>
      <h1 hidden>{courseTitle} Best Training</h1>
      <h1 hidden>{courseTitle} Near Me</h1>

      <h2 hidden>{courseTitle} Training in Madurai</h2>
      <h2 hidden>{courseTitle} Training in Online</h2>
      <h2 hidden>{courseTitle} Training in Offline</h2>
      <h2 hidden>{courseTitle} Best Training</h2>
      <h2 hidden>{courseTitle} Near Me</h2>

      <h3 hidden>{courseTitle} Training in Madurai</h3>
      <h3 hidden>{courseTitle} Training in Online</h3>
      <h3 hidden>{courseTitle} Training in Offline</h3>
      <h3 hidden>{courseTitle} Best Training</h3>
      <h3 hidden>{courseTitle} Near Me</h3>
    </React.Fragment>
  ));

  return (
    <>
      {renderHeadings}
    </>
  );
};


export default CommonCourseDetails;
