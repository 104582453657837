import React, { useEffect } from "react";
import about3 from "../../assets/images/all-img/aboutus.png";
import AOS from 'aos';
import 'aos/dist/aos.css';
const About = () => {
  useEffect(() => {
    AOS.init({
      duration: 1000, // Animation duration
      once: true, // Whether animation should only happen once
    });
  }, []); 
  return (
    <div className="about-area section-padding">
      <div className="container"> 
        <div className="grid grid-cols-12 gap-[30px]">
          <div className="xl:col-span-7 lg:col-span-6 col-span-12" data-aos="fade-right" data-aos-delay="200">
            <img src={about3} alt="" className=" block w-full" data-aos="fade-left" data-aos-delay="300"/>
          </div>
          <div className="xl:col-span-5 lg:col-span-6 col-span-12">
            <div className="mini-title" data-aos="fade-right" data-aos-delay="500">About ALCE</div>
            <h4 className="column-title " data-aos="fade-right" data-aos-delay="700">
              We Provide The Best Online{" "} & Oflline <br/>
              <span className="shape-bg">Training</span>
            </h4>
            <div className=" mb-8" data-aos="fade-right" data-aos-delay="900">
              Our experienced educators ensure a comprehensive learning experience, catering to diverse needs and fostering academic excellence. 
              <br></br><br></br>Join us to achieve your educational goals, whether you prefer virtual classes or in-person instruction. 
              <br></br><br></br>We offer a wide range of courses, personalized support, and interactive learning tools designed to engage and inspire students of all ages and backgrounds.
            </div>
            {/* <div className="space-y-8">
              <div className="progressbar-group">
                <div className="flex justify-between" data-width="85%">
                  <span className="block text-black font-semibold mb-2">
                    Business Studies
                  </span>
                  <span className=" block mb-2 text-black font-semibold">
                    86%
                  </span>
                </div>
                <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative">
                  <div
                    className="ani  h-[6px] bg-primary block absolute left-0 top-1/2 -translate-y-1/2 "
                    style={{ width: "86%" }}
                  ></div>
                </div>
              </div>
              <div className="progressbar-group">
                <div className="flex justify-between" data-width="67%">
                  <span className="block text-black font-semibold mb-2">
                    Marketing
                  </span>
                  <span className=" block mb-2 text-black font-semibold">
                    67%
                  </span>
                </div>
                <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative">
                  <div
                    className="ani  h-[6px] bg-secondary block absolute left-0 top-1/2 -translate-y-1/2 "
                    style={{ width: "67%" }}
                  ></div>
                </div>
              </div>
              <div className="progressbar-group">
                <div className="flex justify-between" data-width="95%">
                  <span className="block text-black font-semibold mb-2">
                    Design & Development
                  </span>
                  <span className=" block mb-2 text-black font-semibold">
                    95%
                  </span>
                </div>
                <div className="rounded-[2px] overflow-hidden bg-opacity-10 bg-black h-[6px] relative">
                  <div
                    className="ani  h-[6px]  bg-tertiary block absolute left-0 top-1/2 -translate-y-1/2 "
                    style={{ width: "95%" }}
                  ></div>
                </div>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </div>
  );
};

export default About;
