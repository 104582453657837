import React from "react";
import CommonCourseDetails from "../CourseDetails/CourseDetails";
import Footer from "../Footer";
import Header from "../Header";
import PageBanner from "../PageBanner";

const ReactNative = () => {
  return (
    <>
      <Header />
      <PageBanner title={"Course Details"} pageName={"React Native Course Details"} />
      {/* <CoreJavaCourseDetails /> */}
      <CommonCourseDetails courseId="ReactNative" />
      <Footer />
    </>
  );
};

export default ReactNative;

